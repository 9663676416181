<template>
  <w-popover v-if="showOrderMethodPopover" @close="close">
    <div
      :class="{
        'order-method-popover': true,
        'order-method-popover--single-option': hasSingleOrderType,
      }"
    >
      <div class="order-method-popover__header">
        <div class="order-method-popover__menu">{{ menuText }}</div>
        <div class="order-method-popover__facility">{{ facilityName }}</div>
      </div>
      <div class="order-method-popover__single-option-title">
        <div v-if="canChoosePickup">
          <w-pickup-icon></w-pickup-icon>
          <div>{{ pickupText }}</div>
        </div>
        <div v-if="canChooseDelivery">
          <w-delivery-icon></w-delivery-icon>
          <div>{{ deliveryText }}</div>
        </div>
        <div v-if="canChooseQrOrdering">
          <w-order-icon v-if="isSeatOrRoomOrder"></w-order-icon>
          <w-table-icon v-else></w-table-icon>
          <div>{{ qrOrderingText }}</div>
        </div>
      </div>
      <div
        class="order-method-popover__order-options blockable"
        role="listbox"
        :aria-label="chooseOrderMethodText"
      >
        <div
          :class="{
            'order-method-popover__order-option': true,
            'order-method-popover__order-option--selected': hasSelectedDelivery,
          }"
          v-if="canChooseDelivery"
          @click="selectDelivery"
          role="option"
          :aria-selected="hasSelectedDelivery"
        >
          <div class="order-method-popover__order-option-title">
            <w-delivery-icon></w-delivery-icon> {{ deliveryText }}
          </div>
          <div
            v-if="hasDeliveryAreas"
            class="order-method-popover__gmp-autocomplete-container"
          >
            <w-gmp-autocomplete></w-gmp-autocomplete>
            <w-delivery-address-message
              class="order-method-popover__delivery-address-message"
            ></w-delivery-address-message>
          </div>
          <div class="order-method-popover__infos">
            <div class="order-method-popover__info-item">
              <div class="order-method-popover__info-item-title">
                {{ deliveryHoursText }}
              </div>
              <w-accept-hours :hours="deliveryHours"></w-accept-hours>
            </div>
            <div
              v-if="hasDeliveryLeadTime"
              class="order-method-popover__info-item"
            >
              <div class="order-method-popover__info-item-title">
                {{ leadTimeText }}
              </div>
              <div>{{ deliveryLeadTimeText }}</div>
            </div>

            <div v-if="showDeliveryFee" class="order-method-popover__info-item">
              <div class="order-method-popover__info-item-title">
                {{ deliveryFeeText }}
              </div>
              <div>{{ deliveryFeePriceTag }}</div>
            </div>
            <div
              v-if="hasDeliveryMinimumOrderValue"
              class="order-method-popover__info-item"
            >
              <div class="order-method-popover__info-item-title">
                {{ minimumOrderValueText }}
              </div>
              <div>{{ minimumOrderValuePriceTag }}</div>
            </div>
          </div>
        </div>
        <div
          :class="{
            'order-method-popover__order-option': true,
            'order-method-popover__order-option--selected': hasSelectedPickup,
          }"
          v-if="canChoosePickup"
          @click="selectPickup"
          role="option"
          :aria-selected="hasSelectedPickup"
        >
          <div class="order-method-popover__order-option-title">
            <w-pickup-icon></w-pickup-icon> {{ pickupText }}
          </div>
          <div class="order-method-popover__infos">
            <div class="order-method-popover__info-item">
              <div class="order-method-popover__info-item-title">
                {{ pickupHoursText }}
              </div>
              <w-accept-hours :hours="pickupHours"></w-accept-hours>
            </div>
            <div
              v-if="hasPickupLeadTime"
              class="order-method-popover__info-item"
            >
              <div class="order-method-popover__info-item-title">
                {{ leadTimeText }}
              </div>
              <div>{{ pickupLeadTimeText }}</div>
            </div>
            <div
              v-if="hasFacilityAddress"
              class="order-method-popover__info-item"
            >
              <div class="order-method-popover__info-item-title">
                {{ addressText }}
              </div>
              <a
                class="order-method-popover__address"
                :href="googleMapsUrl"
                target="_blank"
              >
                <span v-for="(line, i) in address" :key="i">
                  {{ line }}<br />
                </span>
              </a>
            </div>
          </div>
        </div>
        <div
          class="order-method-popover__order-option"
          v-if="canChooseQrOrdering"
        >
          <div class="order-method-popover__infos">
            <div
              v-if="isInsideCurrentQrOrderingHours"
              class="order-method-popover__info-item"
            >
              {{ qrOrderingInfoText }}
            </div>
            <div v-else class="order-method-popover__info-item">
              <div class="order-method-popover__info-item-title">
                {{ qrOrderingHoursText }}
              </div>
              <w-accept-hours :hours="qrOrderingHours"></w-accept-hours>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-slot:footer>
      <div>
        <w-acceptance-hours-message
          class="order-method-popover__message"
        ></w-acceptance-hours-message>
        <w-delivery-address-message
          class="order-method-popover__message order-method-popover__message--delivery-address"
        ></w-delivery-address-message>
      </div>
      <w-button primary @click="close">
        {{ buttonText }}
      </w-button>
    </template>
  </w-popover>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import i18n from '../../i18n';
import { OrderType } from '../../enums';
import { getFormattedLeadTime } from '../../utils';

import { PickupIcon, DeliveryIcon, TableIcon, OrderIcon } from '../icons';
import { AcceptanceHoursMessage, DeliveryAddressMessage } from '../messages';
import { QrOrderType } from '../../enums';
import GMPAutocomplete from '../GMPAutocomplete';
import AcceptHours from '../AcceptHours';
import Button from '../Button';

import Popover from './Popover';

export default {
  data() {
    return {
      menuText: i18n.gettext('Menu'),
      pickupText: i18n.gettext('Pickup'),
      deliveryText: i18n.gettext('Delivery'),
      pickupHoursText: i18n.gettext('Pickup hours'),
      deliveryHoursText: i18n.gettext('Delivery hours'),
      qrOrderingHoursText: i18n.gettext('Ordering hours'),
      leadTimeText: i18n.gettext('Lead time'),
      addressText: i18n.gettext('Address'),
      deliveryFeeText: i18n.gettext('Delivery fee'),
      minimumOrderValueText: i18n.gettext('Minimum order value'),
      chooseOrderMethodText: i18n.gettext('Choose order method'),
    };
  },
  computed: {
    ...mapState(['facility', 'showOrderMethodPopover', 'qrOrderType']),
    ...mapGetters([
      'getPriceTag',
      'pickupHours',
      'deliveryHours',
      'qrOrderingHours',
      'pickupLeadTime',
      'deliveryLeadTime',
      'deliveryFee',
      'hasDeliveryAreas',
      'hasValidAddressInsideDeliveryArea',
      'deliveryMinimumOrderValue',
      'isInsideCurrentQrOrderingHours',
      'hasSelectedPickup',
      'hasSelectedDelivery',
      'orderTypes',
      'hasSingleOrderType',
      'hasAddressError',
    ]),
    ...mapGetters('availabilities', ['canOrderTodayOrInAdvance']),

    qrOrderingText() {
      let orderType = i18n.gettext('Table');
      if (this.qrOrderType === QrOrderType.SeatOrder) {
        orderType = i18n.gettext('Seat');
      } else if (this.qrOrderType === QrOrderType.RoomOrder) {
        orderType = i18n.gettext('Room');
      }
      return i18n.sprintf(i18n.gettext('%s ordering'), orderType);
    },

    qrOrderingInfoText() {
      let orderType = i18n.gettext('table');
      if (this.qrOrderType === QrOrderType.SeatOrder) {
        orderType = i18n.gettext('seat');
      } else if (this.qrOrderType === QrOrderType.RoomOrder) {
        orderType = i18n.gettext('room');
      }
      return i18n.sprintf(
        i18n.gettext('Browse through our menu and order directly to your %s!'),
        orderType
      );
    },

    isSeatOrRoomOrder() {
      return (
        this.qrOrderType === QrOrderType.SeatOrder ||
        this.qrOrderType === QrOrderType.RoomOrder
      );
    },

    canChoosePickup() {
      return this.orderTypes.includes(OrderType.Pickup);
    },
    canChooseDelivery() {
      return this.orderTypes.includes(OrderType.Delivery);
    },
    canChooseQrOrdering() {
      return this.orderTypes.includes(OrderType.QrOrder);
    },
    hasPickupLeadTime() {
      return this.pickupLeadTime > 0;
    },
    hasDeliveryLeadTime() {
      return this.deliveryLeadTime > 0;
    },
    showDeliveryFee() {
      return this.hasDeliveryAreas || this.deliveryFee > 0;
    },
    hasDeliveryMinimumOrderValue() {
      return this.deliveryMinimumOrderValue > 0;
    },
    hasFacilityAddress() {
      return !!(this.facility.street && this.facility.city);
    },
    pickupLeadTimeText() {
      return getFormattedLeadTime(this.pickupLeadTime);
    },
    deliveryLeadTimeText() {
      return getFormattedLeadTime(this.deliveryLeadTime);
    },
    deliveryFeePriceTag() {
      if (this.hasDeliveryAreas && !this.hasValidAddressInsideDeliveryArea) {
        return i18n.gettext('Depends on your location');
      }
      return this.getPriceTag(this.deliveryFee);
    },
    minimumOrderValuePriceTag() {
      if (this.hasDeliveryAreas && !this.hasValidAddressInsideDeliveryArea) {
        return i18n.gettext('Depends on your location');
      }
      return this.getPriceTag(this.deliveryMinimumOrderValue);
    },
    facilityName() {
      return this.facility.name;
    },
    address() {
      const { name, street, zip_code, city } = this.facility;
      return [name, street, `${zip_code ? `${zip_code} ` : ''}${city}`];
    },
    googleMapsUrl() {
      return (
        this.facility.google_maps_url ||
        `https://google.com/maps/search/${encodeURIComponent(
          this.address.slice(1).join(' ')
        )}`
      );
    },
    buttonText() {
      if (this.canChooseQrOrdering) {
        return this.isInsideCurrentQrOrderingHours
          ? i18n.gettext('Order now!')
          : i18n.gettext('Browse menu!');
      }
      if (this.hasAddressError) {
        return i18n.gettext('Browse menu!');
      }
      return this.canOrderTodayOrInAdvance
        ? i18n.gettext('Order now!')
        : i18n.gettext('Browse menu!');
    },
  },
  methods: {
    ...mapActions(['selectOrderType', 'toggleOrderMethodPopover']),
    close() {
      this.toggleOrderMethodPopover();
    },
    selectPickup() {
      this.selectOrderType(OrderType.Pickup);
    },
    selectDelivery() {
      this.selectOrderType(OrderType.Delivery);
    },
  },
  components: {
    'w-button': Button,
    'w-popover': Popover,
    'w-table-icon': TableIcon,
    'w-order-icon': OrderIcon,
    'w-pickup-icon': PickupIcon,
    'w-delivery-icon': DeliveryIcon,
    'w-accept-hours': AcceptHours,
    'w-gmp-autocomplete': GMPAutocomplete,
    'w-acceptance-hours-message': AcceptanceHoursMessage,
    'w-delivery-address-message': DeliveryAddressMessage,
  },
};
</script>

<style lang="scss">
@import 'bulma/sass/utilities/_all';

@import '../../styles/_variables';

.order-method-popover--single-option {
  align-items: center;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .order-method-popover__single-option-title {
    display: block;
    font-size: 1.25rem;
    margin-bottom: 4rem;
    text-align: center;
    text-transform: uppercase;

    .icon {
      height: 3.5rem;
      display: inline-block;
      margin-bottom: 1rem;
      width: 3.5rem;
    }
  }

  .order-method-popover__order-option {
    border: none;
    box-shadow: none;
    flex-basis: 100%;
    padding: 0;
  }

  .order-method-popover__order-option-title {
    display: none;
  }

  .order-method-popover__info-item:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.order-method-popover__single-option-title {
  display: none;
}

.order-method-popover__header {
  font-size: $font-size-lg;
  margin: -2rem 0 2rem;
  text-align: center;

  .order-method-popover__menu {
    text-transform: uppercase;
  }

  .order-method-popover__facility {
    font-size: $font-size-xl;
    padding: 0 4rem;
  }

  @include from($device: $tablet) {
    display: none;
  }
}

.order-method-popover__order-options {
  @include from($device: $tablet) {
    display: flex;
  }
}

.order-method-popover__order-option {
  border: 2px solid transparent;
  border-radius: 0.5rem;
  box-shadow: 0 0 5px 0 var(--app-theme__lineColor);
  cursor: pointer;
  padding: 1rem;

  &:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  @include from($device: $tablet) {
    flex-basis: 50%;
    padding: 2rem;
    margin-bottom: 0 !important;

    &:first-child {
      margin-right: 0.5rem;
    }

    &:last-child {
      margin-left: 0.5rem;
    }
  }
}

.order-method-popover__order-option--selected {
  border: 2px solid var(--app-theme__primaryColor);

  .order-method-popover__order-option-title {
    color: var(--app-theme__primaryColor);
  }
}

.order-method-popover__order-option-title {
  align-items: center;
  display: flex;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-transform: uppercase;

  .icon {
    height: 2.5rem;
    margin-right: 0.5rem;
    width: 2.5rem;
  }

  @include from($device: $tablet) {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2rem;

    .icon {
      margin-right: 0;
      margin-bottom: 0.5rem;
    }
  }
}

.order-method-popover__gmp-autocomplete-container {
  margin-bottom: 1rem;
}

.order-method-popover__infos {
}

.order-method-popover__info-item {
  margin-bottom: 0.5rem;

  @include from($device: $tablet) {
    margin-bottom: 1rem;
  }
}

.order-method-popover__info-item-title {
  font-weight: 600;
}

.order-method-popover__address {
  color: var(--app-theme__primaryColor);
  text-decoration: underline;

  &.order-method-popover__address:hover {
    color: var(--app-theme__primaryColor);
  }
}

.order-method-popover__pickup_hours-weekdays {
  padding-right: 1rem;
}

.order-method-popover__message {
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 1rem;
  }
}

.order-method-popover__delivery-address-message {
  margin-top: 0.5rem;

  @include from($device: $tablet) {
    display: none;
  }
}

.order-method-popover__message--delivery-address {
  display: none;

  @include from($device: $tablet) {
    display: block;
  }
}
</style>

import Jed from 'jed/jed';

import { getLocale } from '../common/utils';

import messages from './nls/translations';

const lang = getLocale(window.resmioApp.languageCode);

const translations =
  typeof messages[lang] === 'object' && Object.keys(messages[lang]).length > 0
    ? messages[lang]
    : messages['root'];

const i18n = new Jed({
  // Generally output by a .po file conversion
  locale_data: {
    messages: translations,
  },
});

export default i18n;

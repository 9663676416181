<template>
  <div
    :class="{
      'product-list-item': true,
      'product-list-item--clickable': clickable,
    }"
    @click="handleClick"
  >
    <div
      class="product-list-item__image"
      v-bind:style="[!oneImagePresent && { width: '0%' }]"
    >
      <img v-bind:src="image" />
    </div>
    <div class="product-list-item__content">
      <div class="product-list-item__header">
        <div class="product-list-item__title">
          <div class="product-list-item__title-text">
            <span class="product-list-item__title-text-spacing">{{
              title
            }}</span>
            <span v-if="hasLabels" class="product-list-item_title-icon-inline">
              <span
                class="product-list-item__title-icon-spacing"
                v-if="hasAlcoholLabel"
                ><w-alcohol-icon></w-alcohol-icon
              ></span>
              <span
                class="product-list-item__title-icon-spacing"
                v-if="hasVeganLabel"
                ><w-vegan-icon></w-vegan-icon
              ></span>
              <span
                class="product-list-item__title-icon-spacing"
                v-if="hasVegetarianLabel"
                ><w-vegetarian-icon></w-vegetarian-icon
              ></span>
              <span
                class="product-list-item__title-icon-spacing"
                v-if="hasSpicyLabel"
                ><w-spicy-icon></w-spicy-icon
              ></span>
              <span
                class="product-list-item__title-icon-spacing"
                v-if="hasHotLabel"
                ><w-hot-icon></w-hot-icon
              ></span>
              <span
                class="product-list-item__title-icon-spacing"
                v-if="hasExtraHotLabel"
                ><w-extra-hot-icon></w-extra-hot-icon
              ></span>
            </span>
          </div>
        </div>
        <div class="product-list-item__price">
          {{ shouldShowPrice ? priceTag : '' }}
        </div>
      </div>
      <div class="product-list-item__body">
        <div class="product-list-item__description">
          {{ description }}
        </div>
        <div v-if="orderingEnabled" class="product-list-item__icon-container">
          <w-increment-icon
            v-if="orderable"
            class="product-list-item__add-icon"
          ></w-increment-icon>
          <w-disabled-icon
            v-else
            class="product-list-item__disabled-icon"
          ></w-disabled-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import { Route } from '../../enums';

import {
  DisabledIcon,
  IncrementIcon,
  AlcoholIcon,
  VeganIcon,
  VegetarianIcon,
  SpicyIcon,
  HotIcon,
  ExtraHotIcon,
} from '../icons';

export default {
  props: {
    product: Object,
    imagepresent: Boolean,
  },
  computed: {
    ...mapState(['productsById', 'qrModeEnabled']),
    ...mapGetters([
      'getPriceTag',
      'shouldShowPrice',
      'orderingEnabled',
      'productIsOrderable',
      'selectedOrderType',
      'getProductPrice',
    ]),
    title() {
      return this.product.title;
    },
    priceTag() {
      const price = this.getProductPrice(this.product);
      return this.getPriceTag(price);
    },
    shouldShowPrice() {
      let price = this.getProductPrice(this.product);
      return price >= 0;
    },
    description() {
      return this.product.description; // TODO: truncate?
    },
    image() {
      if (!this.product.image) {
        return '';
      }
      return (
        'https://' +
        window.resmioApp.imageResizeService +
        '/?&width=200&image=' +
        this.product.image
      );
    },
    oneImagePresent() {
      return this.imagepresent;
    },
    hasDetails() {
      return (
        !!this.description ||
        this.product.product_allergens_exist ||
        this.product.product_modifiers_exist ||
        (this.orderingEnabled
          ? this.qrModeEnabled
            ? this.product.product_variations_exist
            : this.product.product_takeaway_variations_exist
          : this.product.product_variations_exist)
      );
    },
    clickable() {
      return this.hasDetails || this.orderingEnabled;
    },
    orderable() {
      return this.productIsOrderable(this.product);
    },
    hasLabels() {
      return (
        this.product.product_labels && this.product.product_labels.length > 0
      );
    },
    hasAlcoholLabel() {
      return (
        this.product.product_labels &&
        this.product.product_labels.find((label) => label === 'alcohol')
      );
    },
    hasVeganLabel() {
      return (
        this.product.product_labels &&
        this.product.product_labels.find((label) => label === 'vegan')
      );
    },
    hasVegetarianLabel() {
      return (
        this.product.product_labels &&
        this.product.product_labels.find((label) => label === 'vegetarian')
      );
    },
    hasSpicyLabel() {
      return (
        this.product.product_labels &&
        this.product.product_labels.find((label) => label === 'spicy')
      );
    },
    hasHotLabel() {
      return (
        this.product.product_labels &&
        this.product.product_labels.find((label) => label === 'hot')
      );
    },
    hasExtraHotLabel() {
      return (
        this.product.product_labels &&
        this.product.product_labels.find((label) => label === 'extrahot')
      );
    },
  },
  methods: {
    ...mapActions(['fetchProduct', 'createCartItem']),
    async handleClick() {
      if (this.hasDetails) {
        this.$router.push({
          name: Route.ProductDetails,
          params: {
            id: this.product.id,
          },
        });
      } else if (this.orderingEnabled && this.orderable) {
        if (!this.productsById[this.product.id]) {
          await this.fetchProduct(this.product.id);
        }
        this.createCartItem({
          quantity: 1,
          product: this.productsById[this.product.id].resource_uri,
        });
      }
    },
  },

  components: {
    'w-disabled-icon': DisabledIcon,
    'w-increment-icon': IncrementIcon,
    'w-alcohol-icon': AlcoholIcon,
    'w-vegan-icon': VeganIcon,
    'w-vegetarian-icon': VegetarianIcon,
    'w-spicy-icon': SpicyIcon,
    'w-hot-icon': HotIcon,
    'w-extra-hot-icon': ExtraHotIcon,
  },
};
</script>

<style lang="scss">
@import 'bulma/sass/utilities/_all';
@import '../../styles/_variables.scss';

.product-list-item {
  border-bottom: 2px solid var(--app-theme__lineColor);
  display: flex;
  width: 100%;
  &:hover {
    background-color: var(--app-theme__menuColor);
  }
}

.product-list-item__content {
  padding: 1.5rem 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: $detail-desktop) {
    min-height: auto !important;
  }
}

.product-list-item__image {
  box-sizing: border-box;
  width: 15%;
  vertical-align: middle;
  display: flex;
  > img {
    object-fit: cover;
  }
  @media (max-width: $detail-desktop) {
    display: none;
  }
}

.product-list-item--clickable {
  cursor: pointer;
}

.product-list-item__header {
  display: flex;
  padding-bottom: 1rem;
}

.product-list-item__title {
  flex-grow: 1;
  font-weight: 600;
}

.product-list-item__price {
  flex-shrink: 0;
}

.product-list-item__body {
  display: flex;
}

.product-list-item__description {
  flex: 1;
  padding-right: 1rem;
}

.product-list-item__icon-container {
  display: flex;
}

.product-list-item_title-icon-inline {
  white-space: nowrap;
}

.product-list-item__title-text {
  display: inline-block;
}

.product-list-item__title-text-spacing {
  padding-right: 1rem;
  vertical-align: top;
}

.product-list-item__title-icon-inline {
  display: inline-block;
  white-space: nowrap;
}

.product-list-item__title-icon-spacing {
  display: inline-block;
}

.product-list-item__add-icon {
  color: var(--app-theme__primaryColor);
}

.product-list-item__disabled-icon {
  color: var(--app-theme__lineColor);
}

.category-list-item:last-child .product-list-item:last-child,
.app--iframe .product-list-item:last-child {
  border-bottom-style: none;
}
</style>

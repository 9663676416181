<template>
  <div class="menu-error">
    <div class="menu-error__header">
      <w-menu-icon class="menu-error__icon"></w-menu-icon>
      <div class="menu-error__bubble">{{bubbleText}}</div>
    </div>
    {{message}}
  </div>
</template>

<script>
import i18n from '../../i18n'

import { MenuIcon } from '../icons'

export default {
  props: {
    message: String
  },
  data () {
    return {
      bubbleText: i18n.gettext('Coming soon!')
    }
  },
  components: {
    'w-menu-icon': MenuIcon
  }
}
</script>

<style lang="scss">
@import '../../styles/_variables.scss';

.menu-error {
  align-items: center;
  color: var(--app-theme__primaryColor);
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 2.5rem 0 0;
}

.menu-error__header {
  padding: 1rem;
  position: relative;
}

.menu-error__icon {
  color: var(--app-theme__primaryColor);
  height: 4rem;
  width: 4rem;
}

.menu-error__bubble {
  color: var(--app-theme__backgroundColor);
  align-items: center;
  background-color: var(--app-theme__alertColor);
  border-radius: 50%;
  display: flex;
  font-size: $font-size-sm;
  font-weight: 600;
  height: 6rem;
  left: 0;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 0;
  transform: translate(-50%, -50%);
  width: 6rem;
}
</style>

<template>
  <table>
    <tr v-for="([weekdays, hours], i) in formattedHours" :key="i">
      <td>
        <span class="accept-hours__weekdays">
          {{weekdays}}
        </span>
      </td>
      <td>{{hours}}</td>
    </tr>
  </table>
</template>

<script>
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js'

export default {
  props: {
    hours: Array
  },
  computed: {
    formattedHours () {
      const weekdaysMin = moment().localeData()._weekdaysMin
      return this.hours.map(hours => {
        const { ends, begins, weekdays } = hours
        return [
          weekdays
            .map(weekday => weekdaysMin[(weekday + 1) % 7])
            .join(', '),
          `${begins.slice(0, 5)} - ${ends.slice(0, 5)}`
        ]
      })
    }
  }
}
</script>

<style>
.accept-hours__weekdays {
  padding-right: 1rem;
}
</style>

<template>
  <div
    :class="{
      'product-list': true,
      'product-list--single': hasSingleCategory,
    }"
  >
    <w-product-list-item
      v-for="product in products"
      :key="product.id"
      :product="product"
      :imagepresent="imagepresent"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ProductListItem from './ProductListItem';

export default {
  props: ['products', 'imagepresent', 'categoryElement'],
  computed: {
    ...mapGetters(['hasSingleCategory', 'categories', 'productsByCategoryId']),
    shouldResize() {
      return !!this.productsByCategoryId[this.categoryElement.id].find(
        ({ image }) => image !== ''
      );
    },
  },
  components: {
    'w-product-list-item': ProductListItem,
  },
  methods: {
    resizeImageRows() {
      if (!this.imagepresent) {
        return;
      }

      let imagePresentArray = this.categories.map(
        ({ id }) =>
          !!this.productsByCategoryId[id].find(({ image }) => image !== '')
      );
      let categories = document.getElementsByClassName('category-list-item');
      for (let j = 0; j < categories.length; j++) {
        let category = categories.item(j);

        let maxHeight = 0;
        let items = category.getElementsByClassName(
          'product-list-item__content'
        );
        let images = category.getElementsByClassName(
          'product-list-item__image'
        );
        let headers = category.getElementsByClassName(
          'product-list-item__header'
        );
        let bodies = category.getElementsByClassName('product-list-item__body');
        for (let i = 0; i < headers.length; i++) {
          // Calculate the required space for the content (header of the list element and body + padding) and then apply it to all element in the category
          let currHeight =
            headers.item(i).clientHeight +
            bodies.item(i).clientHeight +
            2 * 1.5 * parseFloat(getComputedStyle(items.item(i)).fontSize);
          if (maxHeight < currHeight) {
            maxHeight = currHeight;
          }
        }
        if (imagePresentArray[j]) {
          for (let i = 0; i < items.length; i++) {
            items.item(i).style.minHeight = `${maxHeight}px`;
            images.item(i).style.maxHeight = `${maxHeight}px`;
          }
        }
      }
    },

    adjustImageRows() {
      let images = document.getElementsByClassName('product-list-item__image');
      for (let i = 0; i < images.length; i++) {}
    },
  },
  mounted() {
    this.resizeImageRows();
    this.adjustImageRows();
    window.addEventListener('resize', this.resizeImageRows);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeImageRows);
  },
};
</script>

<style lang="scss">
@import 'bulma/sass/utilities/_all';

.product-list {
  background-color: var(--app-theme__menuColor);

  @include from($device: $tablet) {
    background-color: inherit;
    display: block !important;
  }
}

.product-list--single {
  background-color: inherit;
}
</style>

export default theme => {
  let variables = ''

  Object.entries(theme).forEach(([key, value]) => {
    const name = key.replace('_', '-')
    const rgb = `${Number.parseInt(value.slice(1, 3), 16)}, ${Number.parseInt(value.slice(3, 5), 16)}, ${Number.parseInt(value.slice(5, 7), 16)}`
    variables += `--app-theme__${name}:${value};--app-theme__${name}RGB:${rgb};`
  })

  return `:root{${variables}}body{color:var(--app-theme__textColor);}`
}

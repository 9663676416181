<template>
  <w-icon>
    <g transform="translate(0.75, -1)">
      <path d='M9.323 13.94S8.103 4 15.653 4c7.547 0 6.143 9.94 6.143 9.94m-16.296 1c0-.552.447-1 1.003-1h18.494c.554 0 1.003.446 1.003 1v12.367c0 .552-.447 1-1.003 1H6.503c-.554 0-1.003-.447-1.003-1V14.94zm10.15 3.56v5' />
    </g>
  </w-icon>
</template>

<script>
import Icon from './Icon'

export default {
  components: {
    'w-icon': Icon
  }
}
</script>

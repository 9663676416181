<template>
  <div class="checkout-success-layout">
    <w-header back-button show-always></w-header>
    <div class="checkout-success-layout__content">
      <slot></slot>
    </div>
    <w-footer></w-footer>
  </div>
</template>

<script>
import Header from '../Header';
import Footer from '../Footer';

export default {
  components: {
    'w-header': Header,
    'w-footer': Footer,
  },
};
</script>

<style lang="scss">
@import 'bulma/sass/utilities/_all';

@import '../../styles/_variables.scss';

.checkout-success-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .checkout-success-layout__content {
    padding: 0 1rem;

    @include from($device: $desktop) {
      padding: 0 calc((#{$sidebar-width} + #{$column-gap-desktop} * 2) / 2) 0;
    }
  }
}
</style>

<template>
  <div :class="{
    'cart-item': true,
    'cart-item--forbidden': forbidden
  }">
    <div class="cart-item__quantity">
      {{quantity}}
    </div>
    <div class="cart-item__title" @click="routeToCartItem">
      <div class="cart-item__product">
        {{title}}
      </div>
      <div class="cart-item__options">
        {{description}}
      </div>
    </div>
    <div class="cart-item__buttons" style="position:relative">
      <div class="cart-item__forbidden-icon-container" style="position:absolute;width:100%;height:100%">
        <w-disabled-icon v-if="forbidden" class="cart-item__forbidden-icon" style="margin:0 auto"></w-disabled-icon>
      </div>
      <button class="cart-item__button" @click="decrementQuantity" :disabled="!canDecrement">
        <w-decrement-icon></w-decrement-icon>
      </button>
      <button class="cart-item__button" @click="incrementQuantity">
        <w-increment-icon></w-increment-icon>
      </button>
    </div>
    <div class="cart-item__total">
      {{priceTag}}
    </div>
    <div class="cart-item__remove">
      <button class="cart-item__button cart-item__button--remove" @click="remove">
        <w-close-icon class="cart-item__close-icon"></w-close-icon>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import { Route } from '../enums'
import { CloseIcon, DisabledIcon, IncrementIcon, DecrementIcon } from './icons'

export default {
  props: {
    item: Object
  },
  computed: {
    ...mapState([
      'cartDrawerIsOpen'
    ]),
    ...mapGetters([
      'getPriceTag',
      'forbiddenProductsInCart'
    ]),
    title () {
      return this.item.title
    },
    description () {
      return this.item.description
    },
    priceTag () {
      return this.getPriceTag(this.item.price_gross)
    },
    quantity () {
      return `${this.item.quantity}x`
    },
    productId () {
      /*
        return this.productsByResourceUri[this.item.product]

        the problem here is, that when loading a cart on startup,
        productsByResourceUri is not yet populated (product details
        haven't been loaded yet).
      */
      /*
        this.products.find(({ resource_uri }) => resource_uri === this.item.product)

        this won't work, as the resource uris in the products array
        loaded on startup are like

          /v1/facility/[facility-id]]/products/[id]

        whereas the the uri on cart items (and products details) are like

          /v1/facility/[facility-id]]/menu_widget_product/[id]
      */
      // the following is somewhat hacky
      return this.item.product.match(/\d+$/)[0]
    },
    canDecrement () {
      return this.item.quantity > 1
    },
    forbidden () {
      return this.forbiddenProductsInCart.includes(this.item.product)
    }
  },
  methods: {
    ...mapActions([
      'updateCartItem',
      'deleteCartItem',
      'toggleCartDrawer'
    ]),
    incrementQuantity () {
      this.updateCartItem({
        ...this.item,
        quantity: this.item.quantity + 1
      })
    },
    decrementQuantity () {
      this.updateCartItem({
        ...this.item,
        quantity: Math.max(this.item.quantity - 1, 1)
      })
    },
    async remove () {
      const itemId = this.item.id
      const productId = this.productId
      await this.deleteCartItem(this.item)
      const { name, params, query } = this.$route
      if (
        name === Route.ProductDetails &&
        params.id ===  productId &&
        query.item_id === itemId
      ) {
        this.$router.replace({
          name: Route.ProductDetails,
          params: { id: productId }
        })
      }
    },
    routeToCartItem () {
      if (this.cartDrawerIsOpen) {
        this.toggleCartDrawer()
      }
      const { name, params, query } = this.$route
      if (
        name !== Route.ProductDetails ||
        params.id !== this.productId ||
        query.item_id !== this.item.id
      ) {
        this.$router.push({
          name: Route.ProductDetails,
          params: { id: this.productId },
          query: { item_id: this.item.id }
        })
      }
    }
  },
  components: {
    'w-close-icon': CloseIcon,
    'w-disabled-icon': DisabledIcon,
    'w-increment-icon': IncrementIcon,
    'w-decrement-icon': DecrementIcon
  }
}
</script>

<style lang="scss">
.cart-item {
  display: flex;
  margin-bottom: 1rem;
  position: relative;
}
.cart-item:last-child {
  margin-bottom: 0;
}
.cart-item__quantity {
  width: 2rem;
}
.cart-item--forbidden {
  &, .cart-item__button--remove {
    color: var(--app-theme__errorColor);
  }
  .cart-item__button:not(.cart-item__button--remove) {
    visibility: hidden;
  }
  .cart-item__forbidden-icon-container {
    display: block;
  }
}
.cart-item__title {
  cursor: pointer;
  flex: 1;
  padding: 0 1rem;
}
.cart-item__total {
  padding: 0 1rem;
  text-align: right;
  width: 8rem;
}
.cart-item__options {
  color: var(--app-theme__lineColor);
  display: block;
}
.cart-item__buttons {
  position: relative;
}
.cart-item__forbidden-icon-container {
  display: none;
  height: 100%;
  position: absolute;
  width: 100%;
}
.cart-item__forbidden-icon {
  margin: 0 auto
}
.cart-item__button {
  background-color: transparent;
  border: 2px solid var(--app-theme__lineColor);
  border-radius: 0.5rem;
  color: var(--app-theme__textColor);
  cursor: pointer;
  display: inline-block;
  margin: 0 0 0.25rem 0;
  padding: 0.25rem;
}
.cart-item__button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.cart-item__button--remove {
  border: none;
}
.cart-item__button .icon {
  height: 1rem !important;
  width: 1rem !important;
}
</style>

import i18n from '../../i18n';
import { getVariations } from '../../utils';

function getDefaultFormValues() {
  return {
    id: null,
    quantity: 1,
    product: null,
    product_variation: null,
    product_modifiers: [],
  };
}

function getDefaultVariation(product, isOrderingEnabled, isQrModeEnabled) {
  const variations = getVariations(
    product.product_variations,
    isOrderingEnabled,
    isQrModeEnabled
  );
  if (variations.length === 0) {
    return null;
  }
  return variations[0];
}

export default {
  resetForm({ state, commit, dispatch }, values) {
    const defaults = getDefaultFormValues();
    commit('setFormValues', {
      ...defaults,
      ...values,
    });
    dispatch('validateFormField', {
      name: 'product_modifiers',
      value: state.formValues.product_modifiers,
    });
  },
  setFormValue({ state, commit, dispatch }, { name, value }) {
    commit('setFormValues', {
      ...state.formValues,
      [name]: value,
    });
    dispatch('validateFormField', { name, value });
  },
  createItem({ dispatch, rootState, rootGetters }, product) {
    const defaultVariation = getDefaultVariation(
      product,
      rootGetters.orderingEnabled,
      rootState.qrModeEnabled
    );
    dispatch('resetForm', {
      product: product.resource_uri,
      product_variation: defaultVariation && defaultVariation.resource_uri,
    });
  },
  updateItem({ dispatch }, item) {
    dispatch('resetForm', item);
  },
  async submit({ state, dispatch }) {
    const item = await (state.formValues.id
      ? dispatch('updateCartItem', state.formValues, { root: true })
      : dispatch('createCartItem', state.formValues, { root: true }));
    return item;
  },
  selectVariation({ dispatch }, variation) {
    dispatch('setFormValue', {
      name: 'product_variation',
      value: variation.resource_uri,
    });
  },
  toggleModifier({ state, dispatch }, modifier) {
    const { resource_uri } = modifier;
    const { product_modifiers } = state.formValues;
    dispatch('setFormValue', {
      name: 'product_modifiers',
      value: product_modifiers.includes(resource_uri)
        ? product_modifiers.filter((modifier) => modifier !== resource_uri)
        : [...product_modifiers, resource_uri],
    });
  },
  incrementQuantity({ state, dispatch }) {
    const { quantity } = state.formValues;
    dispatch('setFormValue', {
      name: 'quantity',
      value: quantity + 1,
    });
  },
  decrementQuantity({ state, dispatch }) {
    const { quantity } = state.formValues;
    dispatch('setFormValue', {
      name: 'quantity',
      value: Math.max(quantity - 1, 1),
    });
  },
  validateFormField({ state, getters, commit }, { name, value }) {
    const validateFormField = (key, value) => {
      switch (key) {
        case 'product_modifiers':
          const counts = {};
          const errors = {};
          value.forEach((uri) => {
            const { product_modifier_group } = getters.modifiers.find(
              ({ resource_uri }) => resource_uri === uri
            );
            const count = counts[product_modifier_group];
            counts[product_modifier_group] =
              typeof count === 'undefined' ? 1 : count + 1;
          });
          getters.modifierGroups.forEach(
            ({ resource_uri, minimum_quantity, maximum_quantity }) => {
              const count = counts[resource_uri] || 0;
              if (count < minimum_quantity || count > maximum_quantity) {
                let error;
                if (minimum_quantity === maximum_quantity) {
                  error = i18n.sprintf(
                    i18n.ngettext(
                      'choose one item',
                      'choose %d items',
                      minimum_quantity
                    ),
                    minimum_quantity
                  );
                } else if (minimum_quantity === 0) {
                  error = i18n.sprintf(
                    i18n.ngettext(
                      'choose one item tops',
                      'choose %d items tops',
                      maximum_quantity
                    ),
                    maximum_quantity
                  );
                } else if (maximum_quantity - minimum_quantity === 1) {
                  error = i18n.sprintf(
                    i18n.gettext('choose %d or %d items'),
                    minimum_quantity,
                    maximum_quantity
                  );
                } else {
                  error = i18n.sprintf(
                    i18n.gettext('choose between %d and %d items'),
                    minimum_quantity,
                    maximum_quantity
                  );
                }
                errors[resource_uri] = error;
              }
            }
          );
          if (Object.entries(errors).length > 0) {
            return errors;
          }
          break;
      }
      return null;
    };
    commit('setFormErrors', {
      ...state.formErrors,
      [name]: validateFormField(name, value),
    });
  },
};

<template>
  <div
    :class="{ backdrop: true, 'backdrop--center-content': centerContent }"
    @click.self="handleClick"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    centerContent: Boolean,
  },
  methods: {
    handleClick() {
      this.$emit('close');
    },
  },
  mounted() {
    this.prevOverflow = window.document.documentElement.style.overflow;
    window.document.documentElement.style.overflow = 'hidden';
  },
  destroyed() {
    window.document.documentElement.style.overflow = this.prevOverflow;
  },
};
</script>

<style>
.backdrop {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 102; /* greater than stickies */
}
.backdrop--center-content {
  align-items: center;
  display: flex;
  justify-content: center;
}
</style>

import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js';

import { getLocale, localeFormattedPrice } from '../../../common/utils';
import {
  getCurrentHours,
  isInsideHours,
  placeHasValidAddress,
  roundComputedPrice,
  sortBy,
  sortByPriority,
} from '../../utils';

import { CartType, OrderType, PaymentType } from '../../enums';
import i18n from '../../i18n';

export default ({ placesService }) => ({
  menu(state) {
    return state.menus[0];
  },
  categories(_, getters) {
    const visibleCategories = getters.menu.menu_categories.filter(
      (category) =>
        category.show_products_in_menu &&
        getters.products.some(
          (product) => product.menu_category === category.resource_uri
        )
    );
    return sortByPriority(visibleCategories);
  },
  categoriesByResourceUri(_, getters) {
    const map = {};
    getters.categories.forEach((category) => {
      map[category.resource_uri] = category;
    });
    return map;
  },
  hasProducts(_, getters) {
    return getters.products.length > 0;
  },
  hasOrderableProducts(_, getters) {
    if (getters.hasSelectedQrOrdering) {
      return getters.hasProducts;
    }
    return !!(
      getters.products.find(
        ({ online_order_enabled }) => online_order_enabled
      ) &&
      getters.categories.find(
        ({ online_order_enabled }) => online_order_enabled
      )
    );
  },
  hasProductLabels(_, getters) {
    if (
      getters.products.find(
        (p) => p.product_labels && p.product_labels.length > 0
      )
    ) {
      return true;
    } else {
      return false;
    }
  },
  productIsOrderable(_, getters) {
    return (product) => {
      if (getters.hasSelectedQrOrdering) {
        return (
          product.price_gross !== null &&
          getters.currentOrderHours.length > 0 &&
          getters.currentOrderHours.some(({ menu_categories }) =>
            menu_categories.includes(product.menu_category)
          )
        );
      }
      return (
        product.price_takeaway !== null &&
        product.online_order_enabled &&
        getters.categoriesByResourceUri[product.menu_category]
          .online_order_enabled
      );
    };
  },
  hasCategories(_, getters) {
    return getters.categories.length > 0;
  },
  hasSingleCategory(_, getters) {
    return getters.categories.length === 1;
  },
  hasMultipleCategories(_, getters) {
    return getters.categories.length > 1;
  },
  products(state, getters) {
    if (getters.menu?.products === undefined) {
      return [];
    }
    if (state.qrModeEnabled) {
      // in qr mode we want to filter out non-orderable products
      const currentHours = getCurrentHours(moment(), getters.qrOrderingHours);
      if (currentHours.length > 0) {
        const visibleProducts = getters.menu.products.filter(
          (product) =>
            product.show_product_in_menu &&
            product.price_gross !== null &&
            currentHours.some(({ menu_categories }) =>
              menu_categories.includes(product.menu_category)
            )
        );
        return sortByPriority(visibleProducts);
      }
    }
    const visibleProducts = getters.menu.products.filter(
      (product) =>
        product.show_product_in_menu &&
        (product.price_gross !== null || product.price_takeaway !== null)
    );
    return sortByPriority(visibleProducts);
  },
  productsByCategoryId(_, getters) {
    const productsByCategoryId = {};
    getters.categories.forEach(({ id, resource_uri: resourceUri }) => {
      productsByCategoryId[id] = getters.products.filter(
        ({ menu_category: menuCategory }) => menuCategory === resourceUri
      );
    });
    return productsByCategoryId;
  },
  selectedProduct(state) {
    return state.productsById[state.selectedProductId];
  },
  selectedCategory(state, getters) {
    return (
      getters.categories.find(({ id }) => id === state.selectedCategoryId) ||
      getters.categories[0]
    );
  },
  getPriceTag(state) {
    const languageCode = getLocale(window.resmioApp.languageCode);
    return (price) =>
      price !== null
        ? localeFormattedPrice(price, {
            languageCode: languageCode,
            currencyCode: state.facility.currency,
          })
        : '';
  },
  // ORDERING / CHECKOUT RELATED
  cashEnabled(_, getters) {
    return getters.hasSelectedQrOrdering
      ? getters.menu.qr_allow_cash
      : getters.menu.allow_cash;
  },
  payPalEnabled(state, getters) {
    if (!state.facility.paypal_client_id) {
      return false;
    }
    return getters.hasSelectedQrOrdering
      ? getters.menu.qr_allow_paypal
      : getters.menu.allow_paypal;
  },
  stripeEnabled(state, getters) {
    if (!state.facility.stripe_publish_key) {
      return false;
    }
    return getters.hasSelectedQrOrdering
      ? getters.menu.qr_allow_credit_card
      : getters.menu.allow_credit_card;
  },
  stripeSepaEnabled(state, getters) {
    if (
      !(state.facility.stripe_publish_key && state.facility.stripe_sepa_enabled)
    ) {
      return false;
    }
    return getters.hasSelectedQrOrdering
      ? getters.menu.qr_allow_sepa_direct_debit
      : getters.menu.allow_sepa_direct_debit;
  },
  bamboraEnabled(state, getters) {
    if (!state.facility.supports_bambora) {
      return false;
    }
    return getters.hasSelectedQrOrdering
      ? getters.menu.qr_allow_credit_card
      : getters.menu.allow_credit_card;
  },
  paymentTypes(_, getters) {
    const types = [];
    if (getters.payPalEnabled) {
      types.push(PaymentType.PayPal);
    }
    if (getters.stripeEnabled) {
      types.push(PaymentType.CreditCard);
    }
    if (getters.stripeSepaEnabled) {
      types.push(PaymentType.DirectDebit);
    }
    if (!getters.stripeEnabled && getters.bamboraEnabled) {
      types.push(PaymentType.Bambora);
    }
    if (
      getters.cashEnabled &&
      (!getters.forcePrepayment || types.length === 0)
    ) {
      types.unshift(PaymentType.Cash);
    }
    return types;
  },
  hasPaymentTypes(_, getters) {
    return getters.paymentTypes.length > 0;
  },
  canPayCash(_, getters) {
    return getters.paymentTypes.includes(PaymentType.Cash);
  },
  canPayWithPayPal(_, getters) {
    return getters.paymentTypes.includes(PaymentType.PayPal);
  },
  canPayWithBambora(_, getters) {
    return getters.paymentTypes.includes(PaymentType.Bambora);
  },
  canPayWithCreditCard(_, getters) {
    return getters.paymentTypes.includes(PaymentType.CreditCard);
  },
  canPayWithDirectDebit(_, getters) {
    return getters.paymentTypes.includes(PaymentType.DirectDebit);
  },
  menuEnabled(_, getters) {
    if (getters.menu) {
      return getters.menu.enabled;
    } else {
      return false;
    }
  },
  orderingEnabled(state, getters) {
    if (getters.qrOrderingEnabled && !getters.isInsideCurrentQrOrderingHours) {
      return false;
    }
    return (
      !!state.facility.currency &&
      getters.hasOrderTypes &&
      getters.hasPaymentTypes &&
      getters.hasOrderableProducts
    );
  },
  checkoutEnabled(_, getters) {
    const minimumRequiredCheckoutConditions =
      getters.orderingEnabled &&
      getters['availabilities/canOrderTodayOrInAdvance'] &&
      getters.cartIsFilled &&
      !getters.hasForbiddenProductsInCart;

    if (!minimumRequiredCheckoutConditions) {
      return false;
    } else if (
      getters.hasSelectedDelivery &&
      (getters.hasAddressError || !getters.cartHasReachedMinimumOrderValue)
    ) {
      return false;
    } else if (
      getters.hasSelectedQrOrdering &&
      getters.isOutsideCurrentQrOrderingHours
    ) {
      return false;
    }

    return true;
  },
  forcePrepayment(_, getters) {
    const orderValue = getters.hasSelectedQrOrdering
      ? getters.menu.qr_force_prepayment_order_value
      : getters.menu.force_prepayment_order_value;
    return orderValue !== null && orderValue <= getters.cartSubtotal;
  },
  payPalPurchaseUnits(state, getters) {
    const { country, currency } = state.facility;
    const {
      cartItems,
      cartTotal,
      cartSubtotal,
      hasSelectedDelivery,
      tipAmount,
      voucherAmount,
    } = getters;
    const { name, address1, address2, zip_code, city } =
      getters['checkoutForm/processedFormValues'];
    const items = cartItems.map(
      ({ title, description, quantity, price_gross }) => ({
        name: title.slice(0, 127),
        description: description.slice(0, 127),
        unit_amount: {
          value: (Number(price_gross) / quantity).toFixed(2),
          currency_code: currency,
        },
        quantity: String(quantity),
      })
    );
    if (tipAmount > 0) {
      items.push({
        name: i18n.gettext('Tip'),
        unit_amount: {
          value: tipAmount.toFixed(2),
          currency_code: currency,
        },
        quantity: '1',
      });
    }

    return [
      {
        amount: {
          value: (cartTotal - voucherAmount).toFixed(2),
          currency_code: currency,
          breakdown: {
            item_total: {
              value: (cartSubtotal + tipAmount).toFixed(2),
              currency_code: currency,
            },
            shipping: {
              value: (cartTotal - cartSubtotal - tipAmount).toFixed(2),
              currency_code: currency,
            },
            discount: {
              value: parseFloat(voucherAmount).toFixed(2),
              currency_code: currency,
            },
          },
        },
        items,
        shipping: hasSelectedDelivery
          ? {
              name: {
                full_name: name.slice(0, 300),
              },
              address: {
                address_line_1: address1.slice(0, 300),
                address_line_2: address2.slice(0, 300),
                postal_code: zip_code.slice(0, 60),
                admin_area_2: city.slice(0, 120),
                country_code: country.slice(0, 2),
              },
            }
          : undefined,
      },
    ];
  },
  // PICKUP RELATED
  pickupEnabled(_, getters) {
    return getters.menu.allow_pickup && getters.pickupHours.length > 0;
  },
  pickupHours(_, getters) {
    // First we sort by pickup hour begin (so that earlier is displayed first)
    // Then we sort based on the first element of the weekday array
    // First element of the weekdays array is needed to make sure that
    // we sort based on the first displayed weekday
    return sortBy(getters.menu.product_pickup_accept_hours, 'begins').sort(
      (a, b) => (a.weekdays[0] < b.weekdays[0] ? -1 : 1)
    );
  },
  pickupLeadTime(_, getters) {
    return Number(getters.menu.pickup_lead_time);
  },
  // DELIVERY RELATED
  deliveryEnabled(_, getters) {
    return getters.menu.allow_delivery && getters.deliveryHours.length > 0;
  },
  deliveryHours(_, getters) {
    return sortBy(getters.menu.product_delivery_accept_hours, 'begins').sort(
      (a, b) => (a.weekdays[0] < b.weekdays[0] ? -1 : 1)
    );
  },
  deliveryLeadTime(_, getters) {
    return Number(getters.menu.delivery_lead_time);
  },
  deliveryFee(_, { menu, deliveryAreas, fittingDeliveryAreas }) {
    if (deliveryAreas.length === 0) {
      return Number(menu.delivery_fee);
    }
    if (fittingDeliveryAreas.length === 0) {
      throw Error('this should not have happened!');
    }
    let smallestFee = Number(fittingDeliveryAreas[0].delivery_fee);
    for (let i = 1; i < fittingDeliveryAreas.length; i++) {
      const fee = Number(fittingDeliveryAreas[i].delivery_fee);
      if (fee < smallestFee) {
        smallestFee = fee;
      }
    }
    return smallestFee;
  },
  deliveryAreas(_, getters) {
    if (getters.menu.menu_delivery_areas) {
      return getters.menu.menu_delivery_areas;
    } else {
      return [];
    }
  },
  fittingDeliveryAreas(state, getters) {
    if (!state.googleMapsPlace) {
      return [];
    }
    return getters.deliveryAreas.filter(({ delivery_area }) =>
      placesService.isInsideArea(state.googleMapsPlace, delivery_area)
    );
  },

  deliveryMinimumOrderValue(_, { menu, deliveryAreas, fittingDeliveryAreas }) {
    if (deliveryAreas.length === 0) {
      // if there are no delivery areas, return global delivery minimum order amount
      return Number(menu.delivery_minimum_order_value);
    }
    if (fittingDeliveryAreas.length === 0) {
      return 0;
    }

    // return min order amount by delivery area
    let minDeliveryFeeArea = fittingDeliveryAreas[0];
    for (let i = 0; i < fittingDeliveryAreas.length; i++) {
      const currDeliveryArea = fittingDeliveryAreas[i];
      if (
        Number(currDeliveryArea.delivery_fee) <
        Number(minDeliveryFeeArea.delivery_fee)
      ) {
        minDeliveryFeeArea = currDeliveryArea;
      }
      if (
        Number(currDeliveryArea.delivery_fee) ===
        Number(minDeliveryFeeArea.delivery_fee)
      ) {
        // example these areas have an intersection and customer's delivery address within the intersection:
        // area 1: delivery_fee = 5; min order amount = 10
        // area 2: delivery_fee = 5; min order amount = 15
        // in this case area 1 must be returned
        if (
          Number(currDeliveryArea.minimum_order_amount) <
          Number(minDeliveryFeeArea.minimum_order_amount)
        ) {
          minDeliveryFeeArea = currDeliveryArea;
        }
      }
    }

    return minDeliveryFeeArea.minimum_order_amount;
  },
  hasDeliveryAreas(_, getters) {
    return getters.deliveryAreas.length > 0;
  },
  hasAddress(state) {
    return !!state.googleMapsPlace;
  },
  hasValidAddress(state) {
    return (
      !!state.googleMapsPlace && placeHasValidAddress(state.googleMapsPlace)
    );
  },
  hasValidAddressInsideDeliveryArea(_, getters) {
    return getters.hasValidAddress && getters.fittingDeliveryAreas.length > 0;
  },
  hasAddressError(_, getters) {
    return (
      getters.hasSelectedDelivery &&
      getters.hasDeliveryAreas &&
      !getters.hasValidAddressInsideDeliveryArea
    );
  },
  // QR ORDERING RELATED
  qrOrderingEnabled(state, getters) {
    return (
      state.qrModeEnabled &&
      getters.menu.allow_qr_ordering &&
      getters.qrOrderingHours.length > 0
    );
  },
  qrOrderingHours(_, getters) {
    return sortBy(getters.menu.product_qr_order_accept_hours, 'begins').sort(
      (a, b) => (a.weekdays[0] < b.weekdays[0] ? -1 : 1)
    );
  },
  isInsideCurrentQrOrderingHours(_, getters) {
    return isInsideHours(moment(), getters.qrOrderingHours);
  },
  isOutsideCurrentQrOrderingHours(_, getters) {
    return !getters.isInsideCurrentQrOrderingHours;
  },
  qrCashPaymentText(_, getters) {
    return getters.menu.qr_cash_payment_text;
  },
  // ORDER TYPE RELATED
  orderTypes(_, getters) {
    if (getters.qrOrderingEnabled) {
      return [OrderType.QrOrder];
    }
    const orderTypes = [];
    if (getters.pickupEnabled) {
      orderTypes.push(OrderType.Pickup);
    }
    if (getters.deliveryEnabled) {
      orderTypes.push(OrderType.Delivery);
    }
    return orderTypes;
  },
  hasOrderTypes(_, getters) {
    return getters.orderTypes.length > 0;
  },
  hasSingleOrderType(_, getters) {
    return getters.orderTypes.length === 1;
  },
  selectedOrderType(_, getters) {
    if (getters.cartType === CartType.Pickup) {
      return OrderType.Pickup;
    }
    if (getters.cartType === CartType.Delivery) {
      return OrderType.Delivery;
    }
    if (getters.cartType === CartType.QrOrder) {
      return OrderType.QrOrder;
    }
  },
  orderHours(_, getters) {
    if (getters.hasSelectedPickup) {
      return getters.pickupHours;
    }
    if (getters.hasSelectedDelivery) {
      return getters.deliveryHours;
    }
    if (getters.hasSelectedQrOrdering) {
      return getters.qrOrderingHours;
    }
  },
  orderLeadTime(_, getters) {
    if (getters.hasSelectedPickup) {
      return getters.pickupLeadTime;
    }
    if (getters.hasSelectedDelivery) {
      return getters.deliveryLeadTime;
    }
    if (getters.hasSelectedQrOrdering) {
      return 0;
    }
  },
  currentOrderHours(state, getters) {
    return getCurrentHours(moment(), getters.orderHours);
  },
  hasSelectedPickup(_, getters) {
    return getters.selectedOrderType === OrderType.Pickup;
  },
  hasSelectedDelivery(_, getters) {
    return getters.selectedOrderType === OrderType.Delivery;
  },
  hasSelectedQrOrdering(_, getters) {
    return getters.selectedOrderType === OrderType.QrOrder;
  },
  // TIP RELATED
  tipsEnabled(_, getters) {
    return (
      (getters.hasSelectedPickup && getters.menu.allow_pickup_tip) ||
      (getters.hasSelectedDelivery && getters.menu.allow_delivery_tip) ||
      (getters.hasSelectedQrOrdering && getters.menu.allow_qr_tip)
    );
  },
  tipAmount(_, getters) {
    switch (getters.tipSelection) {
      case 'roundup':
        return getters.roundUpAmount;
      case '5%':
        return roundComputedPrice(getters.tippablePrice * 0.05);
      case '10%':
        return roundComputedPrice(getters.tippablePrice * 0.1);
      case '15%':
        return roundComputedPrice(getters.tippablePrice * 0.15);
      case '20%':
        return roundComputedPrice(getters.tippablePrice * 0.2);
      case '25%':
        return roundComputedPrice(getters.tippablePrice * 0.25);
      case 'custom':
        return Number(getters.tip);
      default:
        return 0;
    }
  },
  tipSelection(_, getters) {
    return getters['checkoutForm/tipSelection'];
  },
  tip(_, getters) {
    return getters['checkoutForm/tip'];
  },
  tippablePrice(_, getters) {
    return getters.hasSelectedDelivery
      ? getters.cartSubtotal + getters.deliveryFee
      : getters.cartSubtotal;
  },
  roundUpAmount(_, getters) {
    let diff = getters.tippablePrice - Math.floor(getters.tippablePrice);
    return diff !== 0 ? 1 - diff : 0;
  },
  // CART RELATED
  cartType(state) {
    return state.cart.type;
  },
  cartItems(state) {
    return state.cart.product_cart_items;
  },
  cartSubtotal(_, getters) {
    return getters.cartItems.reduce(
      (sum, { price_gross }) => sum + Number(price_gross),
      0
    );
  },
  cartTotal(_, getters) {
    if (!getters.hasSelectedDelivery) {
      return getters.cartSubtotal + getters.tipAmount;
    }
    return getters.cartSubtotal + getters.deliveryFee + getters.tipAmount;
  },
  numItemsInCart(_, getters) {
    return getters.cartItems.reduce((num, { quantity }) => num + quantity, 0);
  },
  cartIsEmpty(_, getters) {
    return getters.numItemsInCart === 0;
  },
  cartIsFilled(_, getters) {
    return !getters.cartIsEmpty;
  },
  cartHasReachedMinimumOrderValue(_, getters) {
    return getters.cartSubtotal >= getters.deliveryMinimumOrderValue;
  },
  defaultCartType(_, getters) {
    if (getters.qrOrderingEnabled) {
      return CartType.QrOrder;
    }
    if (
      getters.deliveryEnabled &&
      (getters['availabilities/canDeliverToday'] ||
        !getters.pickupEnabled ||
        !getters['availabilities/canPickupToday'])
    ) {
      return CartType.Delivery;
    }
    return CartType.Pickup;
  },
  forbiddenProductsInCart(state, getters) {
    return getters.cartItems
      .filter(({ product }) => {
        if (state.forbiddenProducts.includes(product)) {
          return true;
        }
        const productId = Number(product.match(/\d+$/)[0]);
        product = getters.menu.products.find(({ id }) => id === productId);
        const category = getters.categoriesByResourceUri[product.menu_category];
        if (
          !product ||
          !product.show_product_in_menu ||
          !category ||
          !category.show_products_in_menu
        ) {
          return true;
        }
        return !getters.productIsOrderable(product);
      })
      .map(({ product }) => product);
  },
  hasForbiddenProductsInCart(_, getters) {
    return getters.forbiddenProductsInCart.length > 0;
  },
  // VOUCHER RELATED
  voucher(state) {
    return state.voucher;
  },
  voucherStatus(_, getters) {
    if (getters.voucher) {
      return getters.voucher.status;
    } else {
      return '';
    }
  },
  voucherAmount(_, getters) {
    if (getters.voucher) {
      return getters.voucher.amount_left;
    } else {
      return 0;
    }
  },
  isVoucherAvailable(_, getters) {
    return getters.voucherAmount > 0;
  },
  isVoucherCoversFull(_, getters) {
    return getters.cartTotal > 0 && getters.voucherAmount >= getters.cartTotal;
  },
  voucherEnabled(state, getters) {
    if (state.isOrderbirdFacility) {
      return false;
    }
    return (
      (getters.hasSelectedPickup && getters.menu.enable_voucher_for_pickup) ||
      (getters.hasSelectedDelivery &&
        getters.menu.enable_voucher_for_delivery) ||
      (getters.hasSelectedQrOrdering && getters.menu.enable_voucher_for_qr)
    );
  },
  getProductPrice(_, getters) {
    return (product) => {
      if (
        !getters.orderingEnabled ||
        getters.selectedOrderType === CartType.QrOrder
      ) {
        return product.price_gross;
      }
      if (
        getters.selectedOrderType === CartType.Delivery ||
        getters.selectedOrderType === CartType.Pickup
      ) {
        return product.price_takeaway;
      }
      throw new Error('Unknown order type');
    };
  },
  getVariationPrice(_, getters) {
    return (variation) => {
      if (
        !getters.orderingEnabled ||
        getters.selectedOrderType === CartType.QrOrder
      ) {
        return variation.price_gross;
      }
      if (
        getters.selectedOrderType === CartType.Delivery ||
        getters.selectedOrderType === CartType.Pickup
      ) {
        return variation.price_takeaway;
      }
      throw new Error('Unknown order type');
    };
  },
  termsAndConditionsUrl(state) {
    return state.isOrderbirdFacility
      ? i18n.gettext(
          'https://support.orderbird.com/de_DE/gaestemanagement-von-orderbird-starten/gaestemanagement-nutzungsbedingungen-preisliste'
        )
      : i18n.gettext('https://www.resmio.com/en/terms-conditions-b2c/');
  },
  privacyPolicyUrl(state) {
    return state.isOrderbirdFacility
      ? i18n.gettext('https://www.orderbird.com/en/privacy')
      : i18n.gettext('https://www.resmio.com/en/privacy-policy/');
  },
  legalNoticeUrl(state) {
    return state.isOrderbirdFacility
      ? i18n.gettext('https://www.orderbird.com/en/legal-notice')
      : i18n.gettext('https://www.resmio.com/en/imprint/');
  },
});

<template>
  <w-message v-if="showMessage" :alert="isAlert" :error="isError">
    {{messageText}}
  </w-message>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import i18n from '../../i18n'

import Message from './Message'

export default {
  computed: {
    ...mapState('checkoutForm', [
      'serverError'
    ]),
    ...mapGetters('checkoutForm', [
      'hasFormErrors',
      'hasFilledInRequiredFields'
    ]),
    messageText () {
      if (this.serverError) {
        return this.serverError
      }
      if (this.hasFormErrors) {
        return i18n.gettext('Please correct the form errors above.')
      }
      return i18n.gettext('Please fill in the required fields above.')
    },
    showMessage () {
      return !!this.serverError || this.hasFormErrors || !this.hasFilledInRequiredFields
    },
    isError () {
      return !!this.serverError || this.hasFormErrors
    },
    isAlert () {
      return !this.isError
    }
  },
  components: {
    'w-message': Message
  }
}
</script>

<template>
  <w-message v-if="showMessage" v-on="$listeners" error>
    {{ messageText }}
  </w-message>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import i18n from '../../i18n';

import Message from './Message';

export default {
  computed: {
    ...mapGetters([
      'hasAddress',
      'hasValidAddress',
      'hasAddressError',
      'hasValidAddressInsideDeliveryArea',
    ]),
    showMessage() {
      return this.hasAddressError;
    },
    messageText() {
      if (!this.hasAddress) {
        return i18n.gettext(
          'Please check if your location is within our delivery area!'
        );
      }
      if (!this.hasValidAddress) {
        return i18n.gettext(
          'Please provide your exact location including street and house number!'
        );
      }
      if (!this.hasValidAddressInsideDeliveryArea) {
        return i18n.gettext(
          'Unfortunately your location is outside our delivery area!'
        );
      }
      return '';
    },
  },
  components: {
    'w-message': Message,
  },
};
</script>

<template>
  <div class="category-sidebar-labels">
    <div class="category-sidebar-label">
      <div class="category-sidebar-label-icon">
        <w-alcohol-icon></w-alcohol-icon>
      </div>
      <div class="category-sidebar-label-text">{{ alcoholText }}</div>
    </div>
    <div class="category-sidebar-label">
      <div class="category-sidebar-label-icon">
        <w-vegan-icon></w-vegan-icon>
      </div>
      <div class="category-sidebar-label-text">{{ veganText }}</div>
    </div>
    <div class="category-sidebar-label">
      <div class="category-sidebar-label-icon">
        <w-vegetarian-icon></w-vegetarian-icon>
      </div>
      <div class="category-sidebar-label-text">{{ vegetarianText }}</div>
    </div>
    <div class="category-sidebar-label">
      <div class="category-sidebar-label-icon">
        <w-spicy-icon></w-spicy-icon>
      </div>
      <div class="category-sidebar-label-text">{{ spicyText }}</div>
    </div>
    <div class="category-sidebar-label">
      <div class="category-sidebar-label-icon">
        <w-hot-icon></w-hot-icon>
      </div>
      <div class="category-sidebar-label-text">{{ hotText }}</div>
    </div>
    <div class="category-sidebar-label">
      <div class="category-sidebar-label-icon">
        <w-extra-hot-icon></w-extra-hot-icon>
      </div>
      <div class="category-sidebar-label-text">{{ extraHotText }}</div>
    </div>
  </div>
</template>

<script>
import i18n from '../i18n';

import {
  AlcoholIcon,
  VeganIcon,
  VegetarianIcon,
  SpicyIcon,
  HotIcon,
  ExtraHotIcon,
} from './icons';

export default {
  data() {
    return {
      alcoholText: i18n.gettext('Alcohol'),
      veganText: i18n.gettext('Vegan'),
      vegetarianText: i18n.gettext('Vegetarian'),
      spicyText: i18n.gettext('Spicy'),
      hotText: i18n.gettext('Hot'),
      extraHotText: i18n.gettext('Extra hot'),
    };
  },
  components: {
    'w-alcohol-icon': AlcoholIcon,
    'w-vegan-icon': VeganIcon,
    'w-vegetarian-icon': VegetarianIcon,
    'w-spicy-icon': SpicyIcon,
    'w-hot-icon': HotIcon,
    'w-extra-hot-icon': ExtraHotIcon,
  },
};
</script>

<style lang="scss">
.category-sidebar-labels {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 0.7rem;
  padding: 1rem 2rem;
}

.category-sidebar-label {
  display: flex;
  padding: 0.2rem 0.5rem;
}

.category-sidebar-label-icon {
  flex: 1 1 auto;
  padding-right: 0.5rem;
  svg {
    width: 1rem;
    height: 1rem;
  }
}

.category-sidebar-label-text {
  flex: 1 1 auto;
}
</style>

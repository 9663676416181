<template>
  <w-popover v-if="isShowing" :title="title" @close="close">
    {{ message }}
    <template v-slot:footer>
      <div class="buttons">
        <w-button @click="close">{{ cancelText }}</w-button>
        <w-button primary @click="openNewTab">{{ okText }}</w-button>
      </div>
    </template>
  </w-popover>
</template>

<script>
import { mapState } from 'vuex';

import i18n from '../../i18n';

import Button from '../Button';

import Popover from './Popover';

export default {
  data() {
    return {
      isShowing: false,
      title: i18n.gettext('Small embed detected'),
      message: i18n.gettext('Open menu in new tab for better user experience?'),
      okText: i18n.gettext('OK'),
      cancelText: i18n.gettext('Cancel'),
    };
  },
  computed: {
    ...mapState(['iframe', 'preview']),
  },
  methods: {
    close() {
      this.isShowing = false;
    },
    openNewTab() {
      open(location.href, '_blank');
    },
    handleResize() {
      if (window.innerWidth < 300 || window.innerHeight < 500) {
        this.isShowing = true;
      } else {
        this.isShowing = false;
      }
    },
  },
  mounted() {
    if (!this.iframe || this.preview) {
      return;
    }
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  components: {
    'w-button': Button,
    'w-popover': Popover,
  },
};
</script>

<style scoped>
.buttons {
  display: flex;
}
.buttons > button:not(:last-child) {
  margin-right: 0.5rem;
}
.buttons > button:not(:first-child) {
  margin-left: 0.5rem;
}
</style>

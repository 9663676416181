<template>
  <w-page :layout="layout">
    <w-category-list></w-category-list>
  </w-page>
</template>

<script>
import { mapGetters } from 'vuex'

import Page from '../Page'

import CategoryList from './CategoryList'

export default {
  computed: {
    ...mapGetters([
      'hasSingleCategory'
    ]),
    layout () {
      return this.hasSingleCategory ? 'full-width' : 'default'
    }
  },
  components: {
    'w-page': Page,
    'w-category-list': CategoryList
  }
}
</script>

<template>
  <w-message error v-if="showMessage">
    {{messageText}}
  </w-message>
</template>

<script>
import { mapGetters } from 'vuex'

import i18n from '../../i18n'

import Message from './Message'

export default {
  computed: {
    ...mapGetters([
      'getPriceTag',
      'cartIsFilled',
      'hasSelectedDelivery',
      'deliveryMinimumOrderValue',
      'cartHasReachedMinimumOrderValue'
    ]),
    showMessage () {
      return this.hasSelectedDelivery && this.cartIsFilled && !this.cartHasReachedMinimumOrderValue
    },
    messageText () {
      return i18n.sprintf(
        i18n.gettext('Your cart has not yet reached our minimum order value of %s for deliveries.'),
        this.getPriceTag(this.deliveryMinimumOrderValue)
      )
    }
  },
  components: {
    'w-message': Message
  }
}
</script>

<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 12 110 40" width="110" height="40">
    <path :fill="birdColor" d="M28,21.3c-7.1-2.9-8.7,1.9-9.2,2.8c-3.9-6-9.4-7-9.4-7c0.7,1.1,1.2,1.8,2.2,4.6
      C9.1,21.1,6,20.9,6,20.9s3.9,2.5,6.3,7.9c-4.5,2.1-8.1,1.9-12.4,2c1,0.5,8.7,4.9,17.1,1.8c3.3-1.2,5.8-3.5,8.1-6.3
      c1.4-1.9,2.3-3.5,6.5-5.2c0.4-0.1,0.4-0.6-0.2-0.5C31.5,20.6,29,21,28,21.3 M8.5,22c1.2,0.1,2.7,0.4,3.3,0.5
      c0.6,1.8,1.1,4.3,1.2,6.1C12.1,26.2,10.1,23.5,8.5,22"/>
    <path :fill="textColor" d="M35.9,38.7c-0.9,0-1.6-0.1-2.3-0.4c-0.7-0.3-1.2-0.7-1.7-1.2c-0.4-0.5-0.8-1.1-1.1-1.8
      c-0.2-0.7-0.3-1.5-0.3-2.3s0.1-1.6,0.3-2.3c0.2-0.7,0.6-1.3,1.1-1.8c0.5-0.5,1-0.9,1.7-1.2c0.7-0.3,1.4-0.4,2.3-0.4
      c0.8,0,1.6,0.1,2.3,0.4c0.7,0.3,1.2,0.7,1.7,1.2c0.4,0.5,0.8,1.1,1.1,1.8c0.2,0.7,0.3,1.4,0.3,2.3c0,0.9-0.1,1.6-0.3,2.3
      c-0.2,0.7-0.6,1.3-1.1,1.8c-0.4,0.5-1,0.9-1.7,1.2C37.5,38.6,36.7,38.7,35.9,38.7 M35.9,36.8c0.5,0,1-0.1,1.3-0.3s0.7-0.5,1-0.9
      s0.4-0.8,0.5-1.2c0.1-0.4,0.2-0.9,0.2-1.4c0-0.5,0-0.9-0.2-1.4c-0.1-0.5-0.3-0.9-0.5-1.2s-0.6-0.7-1-0.9
      c-0.4-0.2-0.8-0.3-1.3-0.3c-0.5,0-1,0.1-1.3,0.3c-0.4,0.2-0.7,0.5-1,0.9s-0.4,0.8-0.5,1.2c-0.1,0.4-0.2,0.9-0.2,1.4
      c0,0.5,0,0.9,0.2,1.4c0.1,0.5,0.3,0.9,0.5,1.2s0.6,0.7,1,0.9C34.9,36.7,35.3,36.8,35.9,36.8"/>
    <path :fill="textColor" d="M46.8,29.9c-0.4,0.2-0.6,0.4-0.9,0.7c-0.3,0.3-0.5,0.7-0.6,1.1c-0.1,0.5-0.2,1-0.2,1.6v5h-2.4v-11h2.2v2
      c0,0,1-2,4-2v2.2C48.9,29.6,47.6,29.5,46.8,29.9"/>
    <path :fill="textColor" d="M59.7,32.8L59.7,32.8v-9.3h-2.2v4.7c-0.3-0.2-0.6-0.3-0.9-0.5c-0.7-0.3-1.4-0.4-2.3-0.4
      c-0.8,0-1.6,0.1-2.3,0.4s-1.2,0.7-1.7,1.2c-0.4,0.5-0.8,1.1-1.1,1.8c-0.2,0.7-0.3,1.4-0.3,2.3s0.1,1.6,0.3,2.3
      c0.2,0.7,0.6,1.3,1.1,1.8c0.5,0.5,1,0.9,1.7,1.2c0.7,0.3,1.4,0.4,2.3,0.4c0.8,0,1.6-0.1,2.3-0.4c0.7-0.3,1.2-0.7,1.7-1.2
      c0.4-0.5,0.8-1.1,1.1-1.8c0.2-0.7,0.3-1.5,0.3-2.3C59.7,32.9,59.7,32.9,59.7,32.8 M57.2,34.4c-0.1,0.5-0.3,0.9-0.5,1.2
      c-0.2,0.3-0.6,0.7-1,0.9c-0.4,0.2-0.8,0.3-1.3,0.3c-0.5,0-1-0.1-1.3-0.3s-0.7-0.5-1-0.9c-0.2-0.3-0.4-0.8-0.5-1.2
      c-0.1-0.5-0.2-0.9-0.2-1.4c0-0.5,0-0.9,0.2-1.4c0.1-0.5,0.3-0.9,0.5-1.2c0.2-0.3,0.6-0.7,1-0.9c0.4-0.2,0.8-0.3,1.3-0.3
      c0.5,0,1,0.1,1.3,0.3s0.7,0.5,1,0.9c0.2,0.3,0.4,0.8,0.5,1.2c0.1,0.5,0.2,0.9,0.2,1.4C57.4,33.5,57.3,34,57.2,34.4"/>
    <path :fill="textColor" d="M63.4,33.6c0,0.4,0,0.8,0.2,1.2c0.1,0.4,0.3,0.7,0.5,1c0.2,0.3,0.5,0.5,0.9,0.7c0.3,0.1,0.8,0.3,1.3,0.3
      c0.7,0,1.2-0.1,1.7-0.4c0.4-0.3,0.7-0.7,1-1.3h2.2c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,0.9-1.1,1.2c-0.4,0.3-0.9,0.5-1.4,0.7
      c-0.5,0.1-1.1,0.2-1.7,0.2c-0.8,0-1.6-0.1-2.2-0.4c-0.6-0.3-1.2-0.7-1.6-1.2c-0.4-0.5-0.8-1.1-1-1.8c-0.2-0.7-0.3-1.5-0.3-2.3
      c0-0.8,0.1-1.5,0.3-2.2c0.2-0.7,0.6-1.3,1-1.8c0.4-0.5,1-0.9,1.6-1.2c0.6-0.3,1.3-0.5,2.2-0.5c0.8,0,1.6,0.2,2.3,0.5
      s1.2,0.8,1.7,1.4c0.4,0.6,0.8,1.2,1,2c0.2,0.8,0.2,1.5,0.1,2.4L63.4,33.6L63.4,33.6L63.4,33.6L63.4,33.6z M68.9,32.1
      c0-0.4-0.1-0.7-0.2-1.1s-0.3-0.7-0.5-0.9s-0.5-0.5-0.8-0.6c-0.3-0.1-0.7-0.2-1.1-0.2c-0.4,0-0.8,0.1-1.1,0.2
      c-0.3,0.1-0.6,0.3-0.9,0.6c-0.2,0.2-0.4,0.6-0.6,0.9c-0.1,0.3-0.2,0.7-0.2,1.1L68.9,32.1L68.9,32.1z"/>
    <path :fill="textColor" d="M76.7,30c-0.4,0.2-0.6,0.4-0.9,0.7c-0.3,0.3-0.5,0.7-0.6,1.2S75,32.9,75,33.5v5.2h-2.4V27.4h2.2v1.7
      c0,0,1-1.7,4-1.7v2.3C78.9,29.7,77.6,29.6,76.7,30"/>
    <path :fill="textColor" d="M90.1,31c-0.2-0.7-0.5-1.3-1-1.8c-0.4-0.5-0.9-1-1.5-1.2c-0.6-0.3-1.3-0.4-2.1-0.4S84,27.7,83.4,28
      c-0.6,0.3-1.1,0.7-1.5,1.2c-0.2,0.2-0.4,0.5-0.5,0.8v-6.5h-0.7v9.7l0,0l0,0c0,0.8,0.1,1.5,0.3,2.2c0.2,0.7,0.5,1.3,1,1.8
      s0.9,0.9,1.5,1.2c0.6,0.3,1.3,0.4,2.1,0.4s1.5-0.1,2.1-0.4c0.6-0.3,1.1-0.7,1.5-1.2c0.4-0.5,0.7-1.2,1-1.8
      c0.2-0.7,0.3-1.4,0.3-2.2C90.4,32.4,90.3,31.7,90.1,31L90.1,31z M89.4,35.1c-0.2,0.6-0.4,1.2-0.8,1.6c-0.3,0.5-0.8,0.8-1.3,1.1
      c-0.5,0.3-1.1,0.4-1.7,0.4c-0.6,0-1.2-0.1-1.7-0.4c-0.5-0.3-1-0.6-1.3-1.1s-0.6-1-0.8-1.6c-0.2-0.6-0.3-1.2-0.3-1.9
      s0.1-1.3,0.3-1.9c0.2-0.6,0.4-1.2,0.8-1.6c0.3-0.4,0.8-0.8,1.3-1.1c0.5-0.3,1.1-0.4,1.7-0.4c0.6,0,1.2,0.1,1.7,0.4
      c0.5,0.3,1,0.6,1.3,1.1c0.3,0.5,0.6,1,0.8,1.6c0.2,0.6,0.3,1.2,0.3,1.9C89.6,33.9,89.5,34.5,89.4,35.1L89.4,35.1z"/>
    <polygon :fill="textColor" points="92.1,27.5 92.9,27.5 92.9,38.9 92.1,38.9 			"/>
    <path :fill="textColor" d="M96.2,30.1L96.2,30.1c0.1-0.4,0.3-0.7,0.6-1c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.8-0.4,1.2-0.5
      c0.4-0.1,0.9-0.2,1.4-0.2v0.8c-0.4,0-0.9,0-1.4,0.1c-0.5,0.1-1,0.5-1.5,1c-0.2,0.2-0.3,0.4-0.5,0.6c-0.1,0.2-0.3,0.4-0.3,0.7
      c-0.1,0.2-0.1,0.5-0.2,0.8c0,0.3-0.1,0.7-0.1,1.1v6.2h-0.8V27.6h0.8L96.2,30.1L96.2,30.1z"/>
    <path :fill="textColor" d="M110,23.5h-0.8V30c-0.1-0.3-0.3-0.6-0.5-0.8c-0.4-0.5-0.9-1-1.5-1.2c-0.6-0.3-1.3-0.4-2.1-0.4
      s-1.5,0.1-2.1,0.4c-0.6,0.3-1.1,0.7-1.5,1.2c-0.4,0.5-0.7,1.2-1,1.8c-0.2,0.7-0.3,1.4-0.3,2.2s0.1,1.5,0.3,2.2
      c0.2,0.7,0.5,1.3,1,1.8c0.4,0.5,0.9,0.9,1.5,1.2c0.6,0.3,1.3,0.4,2.1,0.4s1.5-0.1,2.1-0.4c0.6-0.3,1.1-0.7,1.5-1.2
      c0.4-0.5,0.7-1.2,1-1.8c0.2-0.7,0.3-1.4,0.3-2.2l0,0l0,0L110,23.5L110,23.5z M108.9,35.1c-0.2,0.6-0.4,1.2-0.8,1.6
      c-0.3,0.5-0.8,0.8-1.3,1.1c-0.5,0.3-1.1,0.4-1.7,0.4c-0.7,0-1.2-0.1-1.7-0.4c-0.5-0.3-1-0.6-1.3-1.1c-0.3-0.5-0.6-1-0.8-1.6
      c-0.2-0.6-0.3-1.2-0.3-1.9s0.1-1.2,0.3-1.9s0.4-1.2,0.8-1.6c0.3-0.5,0.8-0.8,1.3-1.1c0.5-0.3,1.1-0.4,1.7-0.4
      c0.7,0,1.2,0.1,1.7,0.4c0.5,0.3,1,0.6,1.3,1.1c0.3,0.5,0.6,1,0.8,1.6c0.2,0.6,0.3,1.2,0.3,1.9S109.1,34.4,108.9,35.1L108.9,35.1z
      "/>
  </svg>
</template>

<script>
export default {
  props: {
    darkBG: Boolean
  },
  computed: {
    birdColor () {
      return this.darkBG ? '#FFFFFF' : '#00A3E0'
    },
    textColor () {
      return this.darkBG ? '#FFFFFF' : '#01426A'
    }
  }
}
</script>

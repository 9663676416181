<template>
  <div
    :class="{
      'form-field': true,
      'form-field--bordered': bordered,
      'form-field--has-error': hasError,
      'form-field--grow': grow,
      'form-field--disabled': disabled,
    }"
  >
    <label :for="name" class="form-field__label">{{ labelText }}</label>
    <slot :inputProps="inputProps" :inputListeners="inputListeners">
      <input v-bind="inputProps" v-on="inputListeners" />
    </slot>
    <div v-if="hasError" class="form-field__error">{{ error }}</div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  props: {
    type: String,
    name: String,
    label: String,
    bordered: Boolean,
    grow: Boolean,
    disabled: Boolean,
  },
  computed: {
    ...mapState('checkoutForm', ['formErrors', 'formValues']),
    ...mapGetters('checkoutForm', ['requiredFields']),
    error() {
      return this.formErrors[this.name];
    },
    hasError() {
      return !!this.error;
    },
    isRequired() {
      return this.requiredFields.includes(this.name);
    },
    labelText() {
      return `${this.label}${this.isRequired ? ' *' : ''}`;
    },
    inputProps() {
      return {
        id: this.name,
        type: this.type,
        name: this.name,
        value: this.formValues[this.name],
        autocomplete: 'off',
        disabled: this.disabled,
      };
    },
    inputListeners() {
      return {
        blur: this.handleBlur,
        input: this.handleInput,
      };
    },
  },
  methods: {
    ...mapActions('checkoutForm', ['setFormValue', 'validateFormField']),
    handleBlur(e) {
      this.validateFormField(e.target);
    },
    handleInput(e) {
      if (this.name) {
        this.setFormValue({ name: this.name, value: e.target.value });
      }
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/_variables.scss';

.form-field {
  padding-bottom: 1rem;

  select,
  textarea,
  input:not([type='radio']):not([type='checkbox']) {
    background-color: transparent;
    border: 2px solid var(--app-theme__lineColor);
    border-radius: 0.5rem;
    color: var(--app-them__textColor);
    padding: 1rem;
    font-size: $font-size-md;
    width: 100%;

    &:focus {
      border-color: var(--app-theme__primaryColor);
      outline: none;
    }

    &::selection {
      background-color: var(--app-theme__primaryColor);
    }
  }

  input[type='radio'],
  input[type='checkbox'] {
    margin-right: 0.5rem;
  }

  textarea {
    resize: none;
  }

  textarea {
    height: calc(3.5rem);
  }

  select {
    height: unset;
    padding: 0.9rem 1rem;
  }
}

.form-field--bordered {
  border: 2px solid var(--app-theme__lineColor);
  padding: 0 1rem;

  &.form-field--has-error {
    padding-bottom: 1rem;

    .form-field__error {
      margin-top: -0.5rem;
    }
  }

  > .form-field__label {
    background: var(--app-theme__backgroundColor);
    border-radius: 0.5rem;
    display: inline-block;
    margin-left: -0.5rem;
    padding: 0 0.5rem;
    transform: translate(0, -50%);
  }
}

.form-field--has-error {
  select,
  textarea,
  input:not([type='radio']):not([type='checkbox']) {
    border-color: var(--app-theme__errorColor);
    &:focus {
      border-color: var(--app-theme__errorColor);
    }
  }
}

.form-field--disabled {
  select,
  textarea,
  input:not([type='radio']):not([type='checkbox']) {
    background-color: rgba(var(--app-theme__lineColorRGB), 0.25);
  }
}

.form-field__error {
  color: var(--app-theme__errorColor);
  padding-top: 0.5rem;
}

.form-field__label {
  display: block;
  padding-bottom: 0.5rem;
}
</style>

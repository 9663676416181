<template>
  <div class="product-variation-group">
    <div class="product-variation-group__variation_list">
      <div
        v-for="variation in variations"
        :key="variation.id"
        class="product-variation-group__variation_list-item"
      >
        <w-radio-input
          v-if="orderingEnabled"
          :checked="selected(variation)"
          @change="select(variation)"
        >
          {{variation.title}}
        </w-radio-input>
        <span v-else class="product-variation-group__variation_list-item-title">
          {{variation.title}}
        </span>
        <span class="product-variation-group__variation_list-item-price">
          {{getPriceTag(variation)}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import RadioInput from '../RadioInput'

export default {
  props: [
    'variations',
    'getPriceTag',
    'orderingEnabled'
  ],
  computed: {
    ...mapState('cartItemForm', ['formValues']),
    selected () {
      return variation => variation.resource_uri === this.formValues.product_variation
    }
  },
  methods: {
    ...mapActions('cartItemForm', ['selectVariation']),
    select (variation) {
      this.selectVariation(variation)
    }
  },
  components: {
    'w-radio-input': RadioInput
  }
}
</script>

<style>
.product-variation-group__variation_list-item {
  align-items: center;
  display: flex;
  margin-bottom: 1rem;
}

.product-variation-group__variation_list-item-title {
  flex-grow: 1;
}

.product-variation-group__variation_list-item-price {
  flex-shrink: 0;
}
</style>

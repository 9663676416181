<template>
  <div class="generic-error">
    <div>{{ errorText }}</div>
    <div class="generic-error__message">{{ message }}</div>
    <div v-html="reloadText"></div>
  </div>
</template>

<script>
import i18n from '../../i18n';

export default {
  props: {
    message: String,
  },
  data() {
    return {
      errorText: i18n.gettext("There's been an error:"),
      reloadText: i18n.gettext(
        'Try <a href="javascript:window.location.reload()">reloading</a> the page.'
      ),
    };
  },
};
</script>

<style lang="scss">
.generic-error {
  color: var(--app-theme__errorColor);
  text-align: center;

  a,
  a:hover {
    color: var(--app-theme__errorColor);
    font-weight: 600;
    text-decoration: underline;
  }

  .generic-error__message {
    font-weight: 600;
    margin: 2rem 0;
  }
}
</style>

<template>
  <w-icon v-bind="$attrs" v-on="$listeners">
    <g
      id="symbol-hot"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <circle
        id="Oval-51"
        stroke="#BC213F"
        stroke-width="1.3"
        fill="#FFFFFF"
        cx="16"
        cy="16"
        r="15"
      ></circle>
      <g
        id="Group"
        transform="translate(15.000000, 18.000000) rotate(-52.000000) translate(-15.000000, -18.000000) translate(5.000000, 11.000000)"
        stroke="#BC213F"
        stroke-width="1.3"
      >
        <path
          d="M2.83542431,6.65113577 C6.05418116,7.50054443 8.30607072,7.71084447 9.59109299,7.28203591 C11.8537791,6.52698347 11.8219864,6.45726117 13.5266612,5.6403936 C13.8005029,5.50917069 14.4634317,5.19289005 15.5154476,4.69155168 C16.5684092,4.18974335 17.8298404,4.60403894 18.3802458,5.63243263 C18.9486345,6.6944266 18.6041993,8.0149636 17.5895281,8.66407013 C16.6099637,9.29068311 15.794481,9.74727469 15.1430801,10.0338449 C12.9272552,11.0086505 10.6379206,11.3545662 9.11304929,11.0842212 C7.90726837,10.8704479 6.23946345,10.156323 4.10963452,8.94184634 C3.85485843,8.7965731 3.61935511,8.61984879 3.40865927,8.41583302 L2.39409333,7.43343371 C2.20993011,7.25510935 2.20519678,6.9612551 2.38352114,6.77709187 C2.50029895,6.65649041 2.67309594,6.60834237 2.83542431,6.65113577 Z"
          id="Rectangle"
          transform="translate(10.216141, 7.484118) rotate(-14.000000) translate(-10.216141, -7.484118) "
        ></path>
        <path
          d="M14.2547086,4.32884249 C14.957711,4.55446632 15.5176451,4.9340602 15.9345109,5.46762415 C16.5598097,6.26797006 16.9969505,7.48933916 16.9969505,7.48933916 C18.4665188,6.34118656 18.7298065,5.10600769 17.7868136,3.78380255 C19.1932363,2.24188394 19.5121712,1.07120816 18.7436182,0.271775209"
          id="Line"
          stroke-linecap="square"
        ></path>
      </g>
      <g
        id="Group"
        transform="translate(8.000000, 18.000000) rotate(-52.000000) translate(-8.000000, -18.000000) translate(-2.000000, 11.000000)"
        stroke="#BC213F"
        stroke-width="1.3"
      >
        <path
          d="M2.83542431,6.65113577 C6.05418116,7.50054443 8.30607072,7.71084447 9.59109299,7.28203591 C11.8537791,6.52698347 11.8219864,6.45726117 13.5266612,5.6403936 C13.8005029,5.50917069 14.4634317,5.19289005 15.5154476,4.69155168 C16.5684092,4.18974335 17.8298404,4.60403894 18.3802458,5.63243263 C18.9486345,6.6944266 18.6041993,8.0149636 17.5895281,8.66407013 C16.6099637,9.29068311 15.794481,9.74727469 15.1430801,10.0338449 C12.9272552,11.0086505 10.6379206,11.3545662 9.11304929,11.0842212 C7.90726837,10.8704479 6.23946345,10.156323 4.10963452,8.94184634 C3.85485843,8.7965731 3.61935511,8.61984879 3.40865927,8.41583302 L2.39409333,7.43343371 C2.20993011,7.25510935 2.20519678,6.9612551 2.38352114,6.77709187 C2.50029895,6.65649041 2.67309594,6.60834237 2.83542431,6.65113577 Z"
          id="Rectangle"
          transform="translate(10.216141, 7.484118) rotate(-14.000000) translate(-10.216141, -7.484118) "
        ></path>
        <path
          d="M14.2547086,4.32884249 C14.957711,4.55446632 15.5176451,4.9340602 15.9345109,5.46762415 C16.5598097,6.26797006 16.9969505,7.48933916 16.9969505,7.48933916 C18.4665188,6.34118656 18.7298065,5.10600769 17.7868136,3.78380255 C19.1932363,2.24188394 19.5121712,1.07120816 18.7436182,0.271775209"
          id="Line"
          stroke-linecap="square"
        ></path>
      </g>
      <g
        id="Group"
        transform="translate(22.000000, 18.000000) rotate(-52.000000) translate(-22.000000, -18.000000) translate(12.000000, 11.000000)"
        stroke="#BC213F"
        stroke-width="1.3"
      >
        <path
          d="M2.83542431,6.65113577 C6.05418116,7.50054443 8.30607072,7.71084447 9.59109299,7.28203591 C11.8537791,6.52698347 11.8219864,6.45726117 13.5266612,5.6403936 C13.8005029,5.50917069 14.4634317,5.19289005 15.5154476,4.69155168 C16.5684092,4.18974335 17.8298404,4.60403894 18.3802458,5.63243263 C18.9486345,6.6944266 18.6041993,8.0149636 17.5895281,8.66407013 C16.6099637,9.29068311 15.794481,9.74727469 15.1430801,10.0338449 C12.9272552,11.0086505 10.6379206,11.3545662 9.11304929,11.0842212 C7.90726837,10.8704479 6.23946345,10.156323 4.10963452,8.94184634 C3.85485843,8.7965731 3.61935511,8.61984879 3.40865927,8.41583302 L2.39409333,7.43343371 C2.20993011,7.25510935 2.20519678,6.9612551 2.38352114,6.77709187 C2.50029895,6.65649041 2.67309594,6.60834237 2.83542431,6.65113577 Z"
          id="Rectangle"
          transform="translate(10.216141, 7.484118) rotate(-14.000000) translate(-10.216141, -7.484118) "
        ></path>
        <path
          d="M14.2547086,4.32884249 C14.957711,4.55446632 15.5176451,4.9340602 15.9345109,5.46762415 C16.5598097,6.26797006 16.9969505,7.48933916 16.9969505,7.48933916 C18.4665188,6.34118656 18.7298065,5.10600769 17.7868136,3.78380255 C19.1932363,2.24188394 19.5121712,1.07120816 18.7436182,0.271775209"
          id="Line"
          stroke-linecap="square"
        ></path>
      </g>
    </g>
  </w-icon>
</template>

<script>
import Icon from './Icon';

export default {
  components: {
    'w-icon': Icon,
  },
};
</script>

import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js';
import i18n from './i18n';

import { Route } from './enums';

export function parseFacilityId(pathname) {
  return pathname.match(/^\/([^\/]+)/)[1]; // eslint-disable-line
}

export function sortBy(objects, property) {
  return objects
    .slice()
    .sort((a, b) =>
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
    );
}

export function sortByPriority(objects) {
  return sortBy(objects, 'priority');
}

export function getVariations(
  rawVariationsArray,
  isOrderingEnabled,
  isQrModeEnabled
) {
  const variations = rawVariationsArray || [];
  if (!isOrderingEnabled || isQrModeEnabled) {
    return sortByPriority(variations.filter((v) => v.price_gross !== null));
  }
  return sortByPriority(variations.filter((v) => v.price_takeaway !== null));
}

export async function handleProductDetailsRouteChange(store, to, from, next) {
  const id = Number(to.params.id);
  if (!store.state.productsById[id]) {
    try {
      await store.dispatch('fetchProduct', id);
    } catch {
      if (from.name) {
        // from inside the app
        return next(false);
      } else {
        // direct navigation
        return next({
          name: Route.ProductList,
          replace: true,
        });
      }
    }
  }
  const product = store.state.productsById[id];
  if (product) {
    store.commit('setSelectedProductId', id);
    store.commit(
      'setSelectedCategoryId',
      Number(product.menu_category.match(/\d+$/)[0])
    );
  }
  const itemId = Number(to.query.item_id);
  if (itemId) {
    const item = store.getters.cartItems.find(({ id }) => id === itemId);
    if (!item) {
      return next({
        name: Route.ProductDetails,
        params: { id },
        replace: true,
      });
    }
    store.dispatch('cartItemForm/updateItem', item);
  } else {
    store.dispatch('cartItemForm/createItem', product);
  }
  next();
}

export function getNextHours(date, hours) {
  const weekday = date.isoWeekday() - 1; // hour weekdays are isoWeekday - 1
  const time = date.format('HH:mm:00');
  const filteredHours = hours.filter(({ weekdays }) =>
    weekdays.includes(weekday)
  );
  return filteredHours.find((h) => {
    // we need to account for the case when lead time pushes over begin time.........
    return h.begins > time || (time < h.ends && time > h.begins);
  });
}

export function getCurrentHours(date, hours) {
  const weekday = date.isoWeekday() - 1; // hour weekdays are isoWeekday - 1
  const currentTime = date.format('HH:mm:00');
  const filteredHours = hours.filter(
    ({ ends, begins, weekdays }) =>
      weekdays.includes(weekday) && begins <= currentTime && ends >= currentTime
  );
  return filteredHours.map((h) => {
    // remove all other weekdays from hour - these are the "current" as in for today, so we only need the today weekday
    const newH = Object.assign({}, h);
    newH.weekday = weekday;
    return newH;
  });
}

export function isInsideHours(date, hours, leadTime = 0) {
  const useThisDate = moment(date).add(leadTime, 'minutes');
  const time = useThisDate.format('HH:mm:00');
  const weekday = useThisDate.isoWeekday() - 1; // hour weekdays are isoWeekday - 1
  const currentHours = getCurrentHours(date, hours);
  let inside = false;
  currentHours.map((h) => {
    if (h.weekday === weekday && h.begins <= time && h.ends >= time) {
      inside = true;
    }
  });
  return inside;
}

const TIMESLOTS = (function (slotDuration) {
  const slotsPerHour = 60 / slotDuration;
  const slotsPerDay = 24 * slotsPerHour;
  return new Array(slotsPerDay).fill().map((_, i) => {
    const hours = Math.floor(i / slotsPerHour);
    const minutes = (i % slotsPerHour) * slotDuration;
    return `${hours >= 10 ? hours : `0${hours}`}:${
      minutes >= 10 ? minutes : `0${minutes}`
    }`;
  });
})(15);

function getHoursForDate(date, hours) {
  const weekday = date.isoWeekday() - 1; // hour weekdays are isoWeekday - 1
  return hours.filter(({ weekdays }) => weekdays.includes(weekday));
}

function getTimeslotsForHour(hour) {
  const begins = hour.begins.slice(0, 5);
  const ends = hour.ends.slice(0, 5);
  return TIMESLOTS.filter((slot) => slot >= begins && slot <= ends);
}

export function getTimeslotsForDate(date, hours) {
  const todaysHours = getHoursForDate(date, hours);
  const slotsPerHour = [];
  todaysHours.forEach((hour) => {
    slotsPerHour.push(...getTimeslotsForHour(hour));
  });
  return Array.from(new Set(slotsPerHour)).sort();
}

export function getAvailableFulfillmentTimes(
  currentDate,
  orderDate,
  hours,
  leadTime
) {
  let useThisDate = moment(orderDate);
  if (useThisDate.isSame(currentDate, 'd')) {
    useThisDate.add({ minutes: leadTime });
  } else if (
    useThisDate.isBefore(moment(currentDate).add({ minutes: leadTime }))
  ) {
    useThisDate = moment(currentDate).add({ minutes: leadTime });
  } else {
    useThisDate.set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
  }
  const slots = getTimeslotsForDate(useThisDate, hours).map((slot) => {
    const [hours, minutes] = slot.split(':');
    return moment(useThisDate).set({
      hours,
      minutes,
      seconds: 0,
      milliseconds: 0,
    });
  });
  const nearestSlotIndex = slots.findIndex((slot) =>
    slot.isSameOrAfter(useThisDate)
  );
  if (nearestSlotIndex === -1) {
    return [];
  }
  return slots.slice(nearestSlotIndex);
}

export function getAvailableFulfillmentDays(
  date,
  hours,
  daysInAdvance,
  leadTime
) {
  let days = [];
  // flatten array of arrays: https://stackoverflow.com/questions/10865025/merge-flatten-an-array-of-arrays
  const availableWeekdays = [].concat.apply(
    [],
    hours.map((hour) => hour.weekdays)
  );
  for (let i = 0; i <= daysInAdvance; i++) {
    let day = moment(date).add(i, 'days');
    let leadTimeDay = moment(date).add(leadTime, 'minutes');
    if (leadTimeDay > day) {
      if (leadTimeDay.isoWeekday() !== day.isoWeekday()) {
        // we do not need to consider this day since it is overtaken by lead time
        continue;
      }
    }
    let hasTimeSlot = true;
    if (i === 0) {
      day.add(leadTime, 'minutes');
      const timesSlots = getTimeslotsForDate(day, hours);
      const availableTimeSlots = timesSlots.filter(
        (t) => t > day.format('HH:mm')
      );
      hasTimeSlot = availableTimeSlots.length > 0;
    }
    let weekday = day.isoWeekday() - 1; // hour weekdays are isoWeekday - 1
    if (availableWeekdays.includes(weekday) && hasTimeSlot) {
      if (!days.find((d) => d.isSame(day, 'd'))) {
        days.push(day.hours(0).minutes(0));
      }
    }
  }
  return days;
}

function getPlaceAddressComponents(place, addressComponentTypes) {
  const result = {};
  for (const type of addressComponentTypes) {
    const component = place.address_components.find(
      (component) => !!component.types.includes(type)
    );
    result[type] = component ? component.long_name : '';
  }
  return result;
}

export function placeHasValidAddress(place) {
  const { route, street_number, postal_code, postal_town, locality, country } =
    getPlaceAddressComponents(place, [
      'route',
      'street_number',
      'postal_code',
      'postal_town',
      'locality',
      'country',
    ]);
  const city = postal_town || locality;
  return !!(route && street_number && postal_code && city && country);
}

export function getAddressFromPlace(place) {
  const { route, street_number, postal_code, postal_town, locality, country } =
    getPlaceAddressComponents(place, [
      'route',
      'street_number',
      'postal_code',
      'postal_town',
      'locality',
      'country',
    ]);
  const city = postal_town || locality;
  return {
    address1: `${route} ${street_number}`.trim(),
    zip_code: postal_code,
    city,
    country,
  };
}

export function isLightColor(color) {
  const hex = color.replace(/^#/, '');
  const red = parseInt(hex.substring(0, 2), 16);
  const green = parseInt(hex.substring(2, 4), 16);
  const blue = parseInt(hex.substring(4, 6), 16);
  // http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(
    0.299 * (red * red) + 0.587 * (green * green) + 0.114 * (blue * blue)
  );
  return hsp > 127.5;
}

export const isValidPrice = (price) => {
  return price === '' || /^[0-9\.\,]+([\.\,][0-9]{1,2})?$/g.test(price); // eslint-disable-line
};

export const reverseLocalePriceFormatting = (string = '') => {
  if (typeof string !== 'string') return parseFloat(0).toFixed(2);
  const segmentsComma = string.split(',');
  const segmentsPeriod = string.split('.');

  if (
    segmentsComma[segmentsComma.length - 1].includes('.') ||
    segmentsComma[segmentsComma.length - 1].length === 3
  ) {
    // comma separate thousands (maybe with decimal)
    return parseFloat(string.replace(',', '')).toFixed(2);
  } else if (
    segmentsPeriod[segmentsPeriod.length - 1].includes(',') ||
    segmentsPeriod[segmentsPeriod.length - 1].length === 3
  ) {
    // period separate thousands (maybe with comma)
    const removeStupidPeriodsFirst = string.replace('.', '');
    return parseFloat(removeStupidPeriodsFirst.replace(',', '.')).toFixed(2);
  } else if (
    !string.includes('.') &&
    !string.includes(',') &&
    /^[0-9]+$/g.test(string)
  ) {
    return parseFloat(string).toFixed(2);
  } else {
    return parseFloat(0).toFixed(2);
  }
};

export function roundComputedPrice(computedPrice) {
  return parseFloat(computedPrice.toFixed(2));
}

function getMinutesFormatted(minutes) {
  return i18n.sprintf(
    i18n.ngettext('1 minute', '%d minutes', minutes),
    minutes
  );
}

function getHoursFormatted(hours) {
  return i18n.sprintf(i18n.ngettext('1 hour', '%d hours', hours), hours);
}

function getDaysFormatted(days) {
  return i18n.sprintf(i18n.ngettext('1 day', '%d days', days), days);
}

export function getFormattedLeadTime(leadTime) {
  const days = Math.floor(leadTime / 60 / 24);
  const hours = Math.floor(leadTime / 60) - days * 24;
  const minutes = leadTime % 60;
  return `${days > 0 ? `${getDaysFormatted(days)} ` : ''}${
    hours > 0 ? `${getHoursFormatted(hours)} ` : ''
  }${minutes > 0 ? `${getMinutesFormatted(minutes)}` : ''}`.trim();
}

export function extractErrorMessage(error, defaultMessage = null) {
  if (!(error.response && error.response.data)) {
    return (
      error.message ||
      defaultMessage ||
      i18n.gettext('An unexpected error has occurred.')
    );
  }
  if (typeof error.response.data === 'object') {
    switch (error.response.data.error) {
      case 'CUSTOMER_BLOCKED_ERROR':
        return i18n.gettext(
          'Sorry, we do not allow online orders at the moment.'
        );
      default:
        return JSON.stringify(error.response.data);
    }
  }
  return String(error.response.data);
}

<template>
  <div class="cart-sidebar">
    <div class="cart-sidebar__header">
      <w-cart-button></w-cart-button>
    </div>
    <div class="cart-sidebar__body">
      <w-cart-item-list read-only></w-cart-item-list>
    </div>
    <w-sticky bottom>
      <div class="cart-sidebar__footer">
        <w-cart-total></w-cart-total>
      </div>
    </w-sticky>
  </div>
</template>

<script>
import Sticky from './Sticky';
import CartButton from './CartButton';
import CartItemList from './CartItemList';
import CartTotal from './CartTotal';

export default {
  components: {
    'w-sticky': Sticky,
    'w-cart-button': CartButton,
    'w-cart-item-list': CartItemList,
    'w-cart-total': CartTotal,
  },
};
</script>

<style lang="scss">
@import 'bulma/sass/utilities/_all';

@import '../styles/_variables';

.cart-sidebar {
  background-color: var(--app-theme__backgroundColor);
  box-shadow: 0 0 5px 0 var(--app-theme__lineColor);
  display: none;
  height: 100%;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  width: $drawer-width;

  @include from($device: $tablet) {
    display: block;
  }
}

.cart-sidebar__header {
  background-color: var(--app-theme__backgroundColor);
  color: var(--app-theme__primaryColor);
  display: flex;
  justify-content: flex-end;
  padding: 2rem;

  .cart-button {
    margin: -1rem -1rem 0 0;
  }
}

.cart-sidebar__body {
  padding: 2rem;
}

.cart-sidebar__footer {
  background-color: var(--app-theme__backgroundColor);
  padding: 0rem 2rem 2rem;
}
</style>

<template>
  <w-page
    layout="checkout"
    :class="{ 'checkout-layout--paypal': busyWithPayPal }"
    :title="titleText"
    @close="handleClose"
  >
    <w-checkout-form></w-checkout-form>
    <template v-slot:footer>
      <w-checkout-footer></w-checkout-footer>
    </template>
  </w-page>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import i18n from '../../i18n';

import Page from '../Page';

import CheckoutForm from './CheckoutForm';
import CheckoutFooter from './CheckoutFooter';

export default {
  data() {
    return {
      titleText: i18n.gettext('Checkout'),
    };
  },
  computed: {
    ...mapState('checkoutForm', ['submitting', 'busyWithPayPal']),
    ...mapGetters(['numItemsInCart']),
  },
  methods: {
    handleClose() {
      this.$router.back();
    },
  },
  watch: {
    numItemsInCart(numItemsInCart) {
      if (numItemsInCart === 0) {
        this.$router.back();
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.submitting) {
      return next(false);
    }
    next();
  },
  components: {
    'w-page': Page,
    'w-checkout-form': CheckoutForm,
    'w-checkout-footer': CheckoutFooter,
  },
};
</script>

<style>
/*
    if the user is checking out with paypal make sure the inline
    forms are working while the rest of the ui is blocked
  */
.app--blocked .checkout-layout--paypal .page__footer.blockable {
  opacity: 1;
  pointer-events: all;
}
</style>

const darkTheme = {
  lineColor: '#CCCCCC',
  menuColor: '#555555',
  textColor: '#FFFFFF',
  errorColor: '#E62C4F',
  alertColor: '#F8C150',
  primaryColor: '#00A7C4',
  backgroundColor: '#474747',
  gradientTopColor: '#474747',
  gradientBottomColor: '#FFFFFF'
}

const lightTheme = {
  lineColor: '#CCCCCC',
  menuColor: '#F7F7F7',
  textColor: '#555555',
  errorColor: '#E62C4F',
  alertColor: '#F8C150',
  primaryColor: '#00A7C4',
  backgroundColor: '#FFFFFF',
  gradientTopColor: '#00A7C4',
  gradientBottomColor: '#FFFFFF'
}

const parseHexColor = color => {
  return typeof color === 'string' && /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(color) ? color : null
}

export default ({
  theme,
  line_color: lineColor,
  menu_color: menuColor,
  text_color: textColor,
  error_color: errorColor,
  alert_color: alertColor,
  primary_color: primaryColor,
  background_color: backgroundColor,
  gradient_top_color: gradientTopColor,
  gradient_bottom_color: gradientBottomColor
}) => {
  const defaultColors = theme === 'dark' ? { ...darkTheme } : { ...lightTheme }
  return {
    lineColor: parseHexColor(lineColor) || defaultColors.lineColor,
    menuColor: parseHexColor(menuColor) || defaultColors.menuColor,
    textColor: parseHexColor(textColor) || defaultColors.textColor,
    errorColor: parseHexColor(errorColor) || defaultColors.errorColor,
    alertColor: parseHexColor(alertColor) || defaultColors.alertColor,
    primaryColor: parseHexColor(primaryColor) || defaultColors.primaryColor,
    backgroundColor: parseHexColor(backgroundColor) || defaultColors.backgroundColor,
    gradientTopColor: parseHexColor(gradientTopColor) || defaultColors.gradientTopColor,
    gradientBottomColor: parseHexColor(gradientBottomColor) || defaultColors.gradientBottomColor
  }
}

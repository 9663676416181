<template>
  <div class="category-list">
    <w-category-list-item
      v-for="category in categories"
      :key="category.id"
      :category="category"
      :products="productsByCategoryId[category.id]"
      :expanded="expanded(category.id)"
      :imagepresent="areImagesPresent"
      @toggle="toggle"
    ></w-category-list-item>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import CategoryListItem from './CategoryListItem';

export default {
  computed: {
    ...mapState(['selectedCategoryId', 'expandedCategories']),
    ...mapGetters(['categories', 'productsByCategoryId']),
    expanded() {
      return (id) => this.expandedCategories[id] || false;
    },
    areImagesPresent() {
      return !!this.categories.find(
        ({ id }) =>
          !!this.productsByCategoryId[id].find(({ image }) => image !== '')
      );
    },
  },
  methods: {
    ...mapActions(['toggleCategory']),
    ...mapMutations(['setSelectedCategoryId', 'setScrollPosition']),
    toggle(id) {
      this.toggleCategory(id);
    },
    handleScroll() {
      const elements = window.document.querySelectorAll('[data-category-id]');
      for (let i = elements.length - 1; i >= 0; i--) {
        const el = elements[i];
        if (el.getBoundingClientRect().y <= 0 || i === 0) {
          this.setSelectedCategoryId(Number(el.dataset.categoryId));
          break;
        }
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  beforeRouteLeave(to, from, next) {
    this.setScrollPosition({ x: 0, y: window.scrollY });
    next();
  },
  components: {
    'w-category-list-item': CategoryListItem,
  },
};
</script>

<style lang="scss">
@import 'bulma/sass/utilities/_all';

.category-list {
  margin: -0.5rem -1rem;

  @include from($device: $tablet) {
    margin: 0;
  }
}
</style>

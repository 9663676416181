import axios from 'axios'

import { parseFacilityId } from '../utils'

import ApiService from './ApiService'
import PlacesService from './PlacesService'
import StorageService from './StorageService'

const facilityId = parseFacilityId(window.location.pathname)
const axiosInstance = axios.create({ baseURL: `/v1/facility/${facilityId}`})
const storageKey = `${facilityId}/menu-widget`

export const apiService = new ApiService(axiosInstance)
export const placesService = new PlacesService(window.google.maps)
export const storageService = new StorageService(window.localStorage, storageKey)
export const sessionStorageService = new StorageService(window.sessionStorage, storageKey)

<template>
  <button
    :class="{ 'cart-button': true, 'cart-button--clickable': clickable }"
    :title="title"
    :aria-label="title"
    @click="handleClick"
  >
    <w-cart-icon :empty="cartIsEmpty"></w-cart-icon>
    <span v-if="cartIsFilled" class="cart-button__positions">
      {{ numItemsInCart }}
    </span>
  </button>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import i18n from '../i18n';

import { CartIcon } from './icons';

export default {
  computed: {
    ...mapState(['cartDrawerIsOpen']),
    ...mapGetters(['cartIsEmpty', 'cartIsFilled', 'numItemsInCart']),
    clickable() {
      return !!this.$listeners.click;
    },
    title() {
      return this.cartDrawerIsOpen
        ? i18n.gettext('Close cart')
        : i18n.gettext('Open cart');
    },
  },
  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
  components: {
    'w-cart-icon': CartIcon,
  },
};
</script>

<style>
.cart-button {
  align-items: center;
  background: none;
  border: none;
  color: inherit;
  display: flex;
}

.cart-button--clickable {
  cursor: pointer;
}

.cart-button__positions {
  margin: 1.5rem -0.5rem 0;
  width: 2rem;
  text-align: center;
}
</style>

import i18n from '../../i18n';
import { PaymentType } from '../../enums';

const isEmpty = (value) => value.trim().length === 0;

const isValidEmail = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value); // https://tylermcginnis.com/validate-email-address-javascript/

export const validateFormField = (
  name,
  value,
  { formValues, requiredFields, isQrOrder }
) => {
  switch (name) {
    case 'address1':
      if (requiredFields.includes('address1') && isEmpty(value)) {
        if (isQrOrder) {
          return i18n.gettext('Please enter your table name or number.');
        }
        return i18n.gettext('Please enter your street name and house number.');
      }
      break;
    case 'zip_code':
      if (requiredFields.includes('zip_code') && isEmpty(value)) {
        return i18n.gettext('Please enter your zip code.');
      }
      break;
    case 'city':
      if (requiredFields.includes('city') && isEmpty(value)) {
        return i18n.gettext('Please enter your city.');
      }
      break;
    case 'name':
      if (requiredFields.includes('name') && isEmpty(value)) {
        return i18n.gettext('Please enter your name.');
      }
      break;
    case 'email':
      if (requiredFields.includes('email') && !isValidEmail(value)) {
        return i18n.gettext('Please enter a valid email address.');
      }
      break;
    case 'phone':
      if (requiredFields.includes('phone') && isEmpty(value)) {
        // todo: add validation?
        return i18n.gettext('Please enter your phone number.');
      }
      break;
    case 'stripe':
      if (value === false) {
        return i18n.gettext('Invalid payment credentials.');
      }
      break;
    case 'owner_name': {
      if (requiredFields.includes('owner_name') && isEmpty(value)) {
        return formValues.payment_type === PaymentType.CreditCard
          ? i18n.gettext('Please enter the card owners name.')
          : i18n.gettext("Please enter the account holder's name.");
      }
      break;
    }
    case 'owner_email':
      if (requiredFields.includes('owner_email') && !isValidEmail(value)) {
        return i18n.gettext('Please enter a valid email address.');
      }
      break;
  }
  return null;
};

export const processFormField = (_, value) =>
  typeof value === 'string' ? value.trim() : value;

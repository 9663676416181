import { EventTarget } from 'event-target-shim';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js';

import { ApiEndpoint } from '../enums';

export default class ApiService extends EventTarget {
  _pendingRequestCount = 0;

  constructor(axios) {
    super();
    this.axios = axios;
  }

  _getMenuCartUuidHeader(cart) {
    return { 'MENU-CART-UUID': cart.uuid };
  }

  _incrementPendingRequestCount() {
    this._pendingRequestCount++;
    if (this._pendingRequestCount === 1) {
      this.dispatchEvent(new Event('fetchstart'));
    }
  }

  _decrementPendingRequestCount() {
    this._pendingRequestCount--;
    if (this._pendingRequestCount === 0) {
      this.dispatchEvent(new Event('fetchend'));
    }
  }

  async _fetch(config) {
    try {
      this._incrementPendingRequestCount();
      const response = await this.axios.request(config);
      return response.data.objects || response.data;
    } finally {
      this._decrementPendingRequestCount();
    }
  }

  getFacility() {
    return this._fetch({
      url: ApiEndpoint.Facility,
    });
  }

  getMenus() {
    return this._fetch({
      url: ApiEndpoint.Menu,
    });
  }

  getProduct(id) {
    return this._fetch({
      url: `${ApiEndpoint.Product}/${id}`,
    });
  }

  getCart(cart) {
    return this._fetch({
      url: `${ApiEndpoint.Cart}/${cart.id}`,
      headers: this._getMenuCartUuidHeader(cart),
    });
  }

  createCart(cart) {
    return this._fetch({
      url: ApiEndpoint.Cart,
      method: 'POST',
      data: cart,
    });
  }

  updateCart(cart) {
    return this._fetch({
      url: `${ApiEndpoint.Cart}/${cart.id}`,
      method: 'PATCH',
      headers: this._getMenuCartUuidHeader(cart),
      data: cart,
    });
  }

  createCartItem(item, cart) {
    return this._fetch({
      url: ApiEndpoint.CartItem,
      method: 'POST',
      data: item,
      headers: this._getMenuCartUuidHeader(cart),
    });
  }

  updateCartItem(item, cart) {
    return this._fetch({
      url: `${ApiEndpoint.CartItem}/${item.id}`,
      method: 'PATCH',
      data: item,
      headers: this._getMenuCartUuidHeader(cart),
    });
  }

  deleteCartItem(item, cart) {
    return this._fetch({
      url: `${ApiEndpoint.CartItem}/${item.id}`,
      method: 'DELETE',
      headers: this._getMenuCartUuidHeader(cart),
    });
  }

  createOrder(order, cart) {
    return this._fetch({
      url: ApiEndpoint.Order,
      method: 'POST',
      data: order,
      headers: this._getMenuCartUuidHeader(cart),
    });
  }

  updateOrder(order, cart) {
    return this._fetch({
      url: `${ApiEndpoint.Order}/${order.id}`,
      method: 'PATCH',
      data: order,
      headers: this._getMenuCartUuidHeader(cart),
    });
  }
  checkVoucher(ref_num) {
    return this._fetch({
      url: ApiEndpoint.Voucher,
      method: 'POST',
      data: {
        ref_num,
      },
    });
  }
  completeOrderPurchase(order, stripeIntentId) {
    return this._fetch({
      url: `${ApiEndpoint.Order}/complete_purchase/${order.id}`,
      method: 'POST',
      data: { payment_intent: stripeIntentId },
    });
  }

  getAvailableDays(date, menu) {
    return this._fetch({
      url: ApiEndpoint.AvailableDays,
      params: {
        menu_id: menu.id,
        date_gte: moment(date).format(),
      },
    });
  }

  getPickupAvailabilities(date, menu) {
    return this._fetch({
      url: ApiEndpoint.PickupAvailabilities,
      params: {
        menu_id: menu.id,
        date_gte: moment(date).format(),
      },
    });
  }

  getDeliveryAvailabilities(date, menu) {
    return this._fetch({
      url: ApiEndpoint.DeliveryAvailabilities,
      params: {
        menu_id: menu.id,
        date_gte: moment(date).format(),
      },
    });
  }
}

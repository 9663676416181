<template>
  <button
    :class="{
      button: true,
      'button--hollow': hollow,
      'button--primary': primary && !disabled,
      'button--loading': loading,
      'button--dense': dense,
    }"
    :disabled="disabled"
    @click="handleClick"
  >
    <span v-if="loading" class="button__spinner-backdrop">
      <svg
        class="button__spinner"
        viewBox="-30 -30 200 200"
        width="2rem"
        height="2rem"
      >
        <path
          d="M63.63 46.79C50.51 31.11 26.93 29.24 6.95 36.02l8.62 37.74C25.2 57.59 46.46 49.56 63.63 46.79z"
        />
        <path
          d="M88.94 64.86c10.6-17.69 5.16-41.27-7.41-58.31L48.92 26.68C67.3 30.93 81.22 49.35 88.94 64.86z"
        />
        <path
          d="M80.56 93.29c21.71 3.36 38.47-5.47 51.34-24.75l-29.84-26.42C103.41 62.48 94.76 78.99 80.56 93.29z"
        />
        <path
          d="M50.27 93.81c1.56 20.39 19.39 36.32 39.4 42.48l14.99-35.97C87.31 107.56 65.53 101.67 50.27 93.81z"
        />
        <path
          d="M40.47 64.21c-19.22 7.89-27.94 30.43-27.77 51.76l37.7 3.26C37.8 104.51 38.46 81.28 40.47 64.21z"
        />
      </svg>
    </span>
    <span class="button__content">
      <slot></slot>
    </span>
  </button>
</template>

<script>
export default {
  props: {
    hollow: Boolean,
    primary: Boolean,
    loading: Boolean,
    disabled: Boolean,
    dense: Boolean,
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss">
.button {
  background-color: var(--app-theme__lineColor);
  border: 2px solid var(--app-theme__lineColor);
  border-radius: 0.5rem;
  color: var(--app-theme__backgroundColor);
  cursor: pointer;
  font-size: 1rem;
  padding: 0.75rem 1rem;
  position: relative;
  width: 100%;
}

.button--dense {
  width: unset;
  padding: 0.5rem;
  border-width: 1px;
  min-width: 5rem;
}

.button:disabled {
  cursor: not-allowed;
}

.button--hollow {
  background-color: transparent;
  color: var(--app-theme__lineColor);
}

.button--primary {
  border-color: var(--app-theme__primaryColor);
}

.button--hollow.button--primary {
  color: var(--app-theme__primaryColor);
}

.button--primary:not(.button--hollow) {
  background-color: var(--app-theme__primaryColor);
  color: var(--app-theme__gradientBottomColor);
}

.button__spinner-backdrop {
  align-items: center;
  display: none;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.button__spinner {
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  fill: currentColor;
  stroke: currentColor;
}

.button__content > * {
  display: inline-block;
  vertical-align: middle;
}

.button--loading {
  .button__spinner-backdrop {
    display: flex;
  }
  .button__content {
    visibility: hidden;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>

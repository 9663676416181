<template>
  <w-icon>
    <path
      d="M23.7781746,8.22182541 L8.22182541,23.7781746"
      stroke-width="1.3"
      transform="translate(16.000000, 16.000000) rotate(-315.000000) translate(-16.000000, -16.000000)"
    ></path>
  </w-icon>
</template>

<script>
import Icon from './Icon';

export default {
  components: {
    'w-icon': Icon,
  },
};
</script>

<template>
  <!--
    key is necessary because without vue won't call lifecycle
    methods on backdrop component for reasons of "optimization"
  -->
  <w-backdrop :key="2" @close="close" center-content>
    <div ref="container" class="popover">
      <slot name="header">
        <div class="popover__header">
          <span class="popover__title">{{ title }}</span>
          <span class="popover__close" @click="close">
            <w-close-icon></w-close-icon>
          </span>
        </div>
      </slot>
      <div class="popover__body">
        <slot></slot>
      </div>
      <div class="popover__footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </w-backdrop>
</template>

<script>
import Backdrop from '../Backdrop';
import { CloseIcon } from '../icons';

export default {
  props: {
    title: String,
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
  components: {
    'w-backdrop': Backdrop,
    'w-close-icon': CloseIcon,
  },
};
</script>

<style lang="scss">
@import 'bulma/sass/utilities/_all';

@import '../../styles/_variables.scss';

.popover {
  background-color: var(--app-theme__backgroundColor);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  width: 100%;

  @include from($device: $tablet) {
    border-radius: 0.5rem;
    height: auto;
    max-height: 100%;
    min-width: 40rem;
    width: auto;
  }
}

.popover__header,
.popover__footer {
  padding: 1.5rem 1rem;

  @include from($device: $tablet) {
    padding: 1.5rem 2rem;
  }
}

.popover__body {
  padding: 0 1rem;

  @include from($device: $tablet) {
    padding: 0 2rem;
  }
}

.popover__header {
  align-items: center;
  display: flex;
  font-size: $font-size-xl;
  justify-content: space-between;

  @include from($device: $tablet) {
    font-size: $font-size-lg;
  }

  @include from($device: $desktop) {
    font-size: $font-size-xl;
  }

  .popover__title {
    color: var(--app-theme__primaryColor);
  }

  .popover__close {
    cursor: pointer;
  }
}

.popover__body {
  // flex-basis: 100%;
  flex-grow: 1;
  // min-height: 0; // needed vor scroll to work
  // overflow-y: auto;
}
</style>

<template>
  <div class="tip-element">
    <span v-if="hasTip" class="tip-element__has-tip">{{ hasTipText }}</span>
    <span v-else>{{ tipText }}</span>
    <div class="tip-element__buttons" ref="element">
      <w-button dense primary :hollow="!isRoundUpTip" @click="setRoundUpTip">{{
        roundUpText
      }}</w-button>
      <w-button dense primary :hollow="!is5PercentTip" @click="set5PercentTip"
        >5%</w-button
      >
      <w-button dense primary :hollow="!is10PercentTip" @click="set10PercentTip"
        >10%</w-button
      >
      <w-button dense primary :hollow="!is15PercentTip" @click="set15PercentTip"
        >15%</w-button
      >
      <w-button dense primary :hollow="!is20PercentTip" @click="set20PercentTip"
        >20%</w-button
      >
      <w-button dense primary :hollow="!is25PercentTip" @click="set25PercentTip"
        >25%</w-button
      >
      <div style="position: relative; display: inline;">
        <input
          :placeholder="customText"
          type="number"
          :class="{
            'tip-element__custom-input': true,
            'tip-element__custom-input-enabled': isCustomTip,
          }"
          :value="tip"
          @input="handleTipChange"
        />
        <span v-if="isCustomTip" class="tip-element__clear" @click="clearTip"
          ><w-close-icon></w-close-icon
        ></span>
      </div>
    </div>
    <div class="tip-element__error" v-if="formErrors.tip">
      {{ formErrors.tip }}
    </div>
  </div>
</template>

<script>
import i18n from '../../i18n';
import { CloseIcon } from '../icons';
import { mapState, mapActions, mapGetters } from 'vuex';
import Button from '../Button';

export default {
  data() {
    return {
      tipText: i18n.gettext('Tip our team'),
      hasTipText: i18n.gettext('Thank you! We are very pleased.'),
      customText: i18n.gettext('Add tip amount'),
      roundUpText: i18n.gettext('Round up'),
    };
  },
  computed: {
    ...mapState(['theme']),
    ...mapGetters(['cartSubtotal', 'deliveryFee']),
    ...mapState('checkoutForm', ['formErrors', 'tip', 'tipSelection']),
    ...mapGetters('checkoutForm', ['hasTip']),
    isRoundUpTip() {
      return this.tipSelection === 'roundup';
    },
    is5PercentTip() {
      return this.tipSelection === '5%';
    },
    is10PercentTip() {
      return this.tipSelection === '10%';
    },
    is15PercentTip() {
      return this.tipSelection === '15%';
    },
    is20PercentTip() {
      return this.tipSelection === '20%';
    },
    is25PercentTip() {
      return this.tipSelection === '25%';
    },
    isCustomTip() {
      return this.tipSelection === 'custom';
    },
  },
  methods: {
    ...mapActions('checkoutForm', [
      'setTip',
      'clearTip',
      'setTipSelection',
      'setFormError',
    ]),
    handleTipChange(e) {
      if (e.target.value > 0) {
        this.setTip(e.target.value);
      } else {
        this.clearTip();
      }
      if (e.target.value !== '' && this.tipSelection !== 'custom') {
        this.setTipSelection('custom');
      }
    },
    setRoundUpTip() {
      if (this.isRoundUpTip) {
        this.clearTip();
      } else {
        this.setTipSelection('roundup');
      }
    },
    set5PercentTip() {
      if (this.is5PercentTip) {
        this.clearTip();
      } else {
        this.setTipSelection('5%');
      }
    },
    set10PercentTip() {
      if (this.is10PercentTip) {
        this.clearTip();
      } else {
        this.setTipSelection('10%');
      }
    },
    set15PercentTip() {
      if (this.is15PercentTip) {
        this.clearTip();
      } else {
        this.setTipSelection('15%');
      }
    },
    set20PercentTip() {
      if (this.is5PercentTip) {
        this.clearTip();
      } else {
        this.setTipSelection('20%');
      }
    },
    set25PercentTip() {
      if (this.is25PercentTip) {
        this.clearTip();
      } else {
        this.setTipSelection('25%');
      }
    },
    setCustomTip() {
      if (this.isCustomTip) {
        this.clearTip();
      } else {
        this.setTipSelection('custom');
      }
    },
  },
  components: {
    'w-button': Button,
    'w-close-icon': CloseIcon,
  },
};
</script>

<style>
.tip-element {
  margin-bottom: 0.5rem;
}
.tip-element__buttons {
  margin-top: 0.5rem;
}
.tip-element__custom-input {
  border-width: 1px !important;
  width: unset !important;
  padding: 8px 30px 8px 16px !important;
}
.tip-element__custom-input-enabled {
  border-color: var(--app-theme__primaryColor) !important;
}
.tip-element__clear {
  position: absolute;
  top: -1px;
  right: 5px;
  user-select: none;
}
.tip-element__clear:hover {
  cursor: pointer;
}
.tip-element__has-tip {
  color: var(--app-theme__primaryColor);
}
.tip-element__buttons button {
  margin: 0rem 0.2rem 0.5rem 0rem;
  outline: none;
}
.tip-element__error {
  color: var(--app-theme__errorColor);
  padding-top: 0.25rem;
}
</style>

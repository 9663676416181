<template>
  <div class="cart-item-form">
    <div class="cart-item-form__quantity-input-container">
      <w-quantity-input
        :value="formValues.quantity"
        @increment="incrementQuantity"
        @decrement="decrementQuantity"
      ></w-quantity-input>
    </div>
    <div class="cart-item-form__total">{{ priceTag }}</div>
    <div class="cart-item-form__button-container">
      <w-button
        primary
        :loading="isUpdatingCart"
        :disabled="buttonDisabled"
        @click="createOrUpdateCartItem"
      >
        <w-increment-icon
          v-if="isNew"
          class="cart-item-form__submit-button-icon"
        ></w-increment-icon>
        <w-check-icon
          v-else-if="isSame"
          class="cart-item-form__submit-button-icon"
        ></w-check-icon>
        <span>{{ buttonText }}</span>
        <span class="cart-item-form__submit-button-total"
          >({{ priceTag }})</span
        >
      </w-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import i18n from '../../i18n';
import { Route } from '../../enums';
import { CartUpdateError } from '../../errors';

import Button from '../Button';
import { CheckIcon, IncrementIcon } from '../icons';

import QuantityInput from './QuantityInput';

export default {
  data() {
    return {
      isUpdatingCart: false,
    };
  },
  computed: {
    ...mapState('cartItemForm', ['formValues', 'formErrors']),
    ...mapGetters(['cartItems', 'getPriceTag']),
    ...mapGetters('cartItemForm', ['total', 'product', 'formIsInvalid']),
    priceTag() {
      return this.getPriceTag(this.total);
    },
    buttonText() {
      return this.isNew
        ? i18n.gettext('Add to cart')
        : this.hasChanged
          ? i18n.gettext('Update cart')
          : i18n.gettext('Saved to cart');
    },
    cartItem() {
      return this.cartItems.find(({ id }) => id === this.formValues.id);
    },
    isNew() {
      return !this.formValues.id;
    },
    isInCart() {
      return !this.isNew;
    },
    hasChanged() {
      if (!this.cartItem) {
        return true;
      }
      const { quantity, product_variation, product_modifiers } = this.cartItem;
      return !(
        this.formValues.quantity === quantity &&
        this.formValues.product_variation === product_variation &&
        this.formValues.product_modifiers.length === product_modifiers.length &&
        this.formValues.product_modifiers.every((resourceUri) =>
          product_modifiers.includes(resourceUri)
        )
      );
    },
    isSame() {
      return !this.hasChanged;
    },
    buttonDisabled() {
      return this.formIsInvalid || this.isSame;
    },
  },
  methods: {
    ...mapActions(['flashMessage']),
    ...mapActions('cartItemForm', [
      'submit',
      'createItem',
      'incrementQuantity',
      'decrementQuantity',
    ]),
    async createOrUpdateCartItem() {
      this.isUpdatingCart = true;
      try {
        await this.submit();
        this.createItem(this.product); // resets the form
      } catch {}
      this.isUpdatingCart = false;
    },
  },
  components: {
    'w-button': Button,
    'w-quantity-input': QuantityInput,
    'w-check-icon': CheckIcon,
    'w-increment-icon': IncrementIcon,
  },
};
</script>

<style lang="scss">
@import 'bulma/sass/utilities/_all';

.cart-item-form {
  display: flex;
}

.cart-item-form__quantity-input-container {
  display: flex;
  padding-right: 0.5rem;

  .button {
    color: inherit;
  }
}

.cart-item-form__total {
  display: none;
}

.cart-item-form__button-container {
  display: flex;
  flex: 1 0;
  padding-left: 0.5rem;
}

.cart-item-form__submit-button-icon {
  display: none;
}

@include from($device: $tablet) {
  .cart-item-form {
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    position: static;
    width: auto;
  }
}

@include from($device: $widescreen) {
  .cart-item-form__quantity-input-container {
    flex: 1 0;
  }

  .cart-item-form__total {
    align-items: center;
    display: flex;
    flex: 1 0;
    justify-content: center;
  }

  .cart-item-form__submit-button-icon {
    display: inline-block;
    margin-right: 0.25rem;
  }

  .cart-item-form__submit-button-total {
    display: none;
  }
}
</style>

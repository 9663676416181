<template>
  <label class="checkbox-input">
    <span class="checkbox-input__input-wrapper">
      <input class="checkbox-input__input" type="checkbox" v-bind="$attrs" v-on="$listeners" />
      <span class="checkbox-input__checkmark"></span>
    </span>
    <span class="checkbox-input__label">
      <slot></slot>
    </span>
  </label>
</template>

<script>
export default {
}
</script>

<style lang="scss">
// adapted from https://www.w3schools.com/howto/howto_css_custom_checkbox.asp

.checkbox-input {
  align-items: center;
  display: flex;
  width: 100%;
}

.checkbox-input__input-wrapper {
  display: block;
  flex-shrink: 0;
  height: 1.25rem;
  position: relative;
  width: 1.25rem;
}

.checkbox-input__input {
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

.checkbox-input__checkmark {
  border: 2px solid var(--app-theme__lineColor);
  border-radius: 0.25rem;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.checkbox-input__input:checked ~ .checkbox-input__checkmark {
  background-color: var(--app-theme__primaryColor);
  border-color: var(--app-theme__primaryColor);
}

.checkbox-input__checkmark:after {
  border: solid var(--app-theme__backgroundColor);
  border-width: 0 0.125rem 0.125rem 0;
  content: "";
  display: none;
  height: 0.75rem;
  left: 0.25rem;
  position: absolute;
  top: 0;
  transform: rotate(45deg);
  width: 0.5rem;
}

.checkbox-input__input:checked ~ .checkbox-input__checkmark:after {
  display: block;
}

.checkbox-input__label {
  flex-grow: 1;
  margin-left: 0.5rem;
}
</style>

import Vue from 'vue';
import VueRouter from 'vue-router';

import { Route } from './enums';
import { handleProductDetailsRouteChange } from './utils';

import Checkout from './components/checkout';
import CheckoutSuccess from './components/checkoutSuccess';
import ProductList from './components/productList';
import ProductDetails from './components/productDetails';

Vue.use(VueRouter);

function cancelNavigation(from, next) {
  if (from.name) {
    // from inside the app
    return next(false);
  } else {
    // direct navigation
    return next({
      name: Route.ProductList,
      replace: true,
    });
  }
}

export default ({ base, store }) =>
  new VueRouter({
    base,
    mode: 'history',
    routes: [
      {
        path: '/',
        name: Route.ProductList,
        component: ProductList,
      },
      {
        path: '/checkout',
        name: Route.Checkout,
        component: Checkout,
        async beforeEnter(_, from, next) {
          if (!store.getters.checkoutEnabled) {
            return cancelNavigation(from, next);
          }
          if (!store.getters.hasSelectedQrOrdering) {
            await store.dispatch('availabilities/fetchAvailableDays');
            const availabilities = await store.dispatch(
              'availabilities/fetchAvailabilities'
            );
            if (availabilities.length === 0) {
              store.dispatch('toggleOrderMethodPopover');
              return cancelNavigation(from, next);
            }
          }
          store.dispatch('checkoutForm/resetForm');
          next();
        },
      },
      {
        path: '/checkout/success',
        name: Route.CheckoutSuccess,
        component: CheckoutSuccess,
        beforeEnter(_, from, next) {
          if (from.name !== Route.Checkout) {
            return next({
              name: Route.Checkout,
              replace: true,
            });
          }
          next();
        },
      },
      {
        path: '/:id',
        name: Route.ProductDetails,
        component: ProductDetails,
        beforeEnter(...args) {
          handleProductDetailsRouteChange(store, ...args);
        },
      },
    ],
    scrollBehavior(to, _, savedPosition) {
      if (to.name === Route.ProductList && store.state.scrollPosition) {
        return store.state.scrollPosition;
      }
      return savedPosition || { x: 0, y: 0 };
    },
  });

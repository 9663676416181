<template>
  <w-icon>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(3.969452, 1.000000)" stroke="currentColor" stroke-width="1.3">
          <path d="M15.7184016,19.5602241 L18.047569,19.5602241 L18.047569,25.3707772 C18.047569,25.923062 17.5998538,26.3707772 17.047569,26.3707772 L16.7184016,26.3707772 C16.1661169,26.3707772 15.7184016,25.923062 15.7184016,25.3707772 L15.7184016,19.5602241 L15.7184016,19.5602241 Z"></path>
          <path d="M8.04756902,19.5602241 L10.3767364,19.5602241 L10.3767364,25.3707772 C10.3767364,25.923062 9.92902115,26.3707772 9.3767364,26.3707772 L9.04756902,26.3707772 C8.49528427,26.3707772 8.04756902,25.923062 8.04756902,25.3707772 L8.04756902,19.5602241 L8.04756902,19.5602241 Z"></path>
          <path d="M2.00842959,6 L22.0872684,6 C22.6226138,6 23.0630019,6.42160663 23.0863194,6.95644405 L24.0018631,27.9564441 C24.0259184,28.5082047 23.5981287,28.9749957 23.0463681,28.999051 C23.0318581,28.9996836 23.017336,29 23.0028122,29 L1.0468379,29 C0.494553148,29 0.0468378975,28.5522847 0.0468378975,28 C0.0468378975,27.9847466 0.0471868955,27.9694953 0.0478846175,27.9542578 L1.00947631,6.95425785 C1.03392543,6.42031872 1.473931,6 2.00842959,6 Z"></path>
          <path d="M6.53616885,11 C6.45319579,14.3333333 7.28886547,16 9.04317789,16 C10.7974903,16 11.6238311,14.3333333 11.5222003,11"></path>
          <path d="M8.04066539,0 C7.89131389,6 9.39551932,9 12.5532817,9 C15.711044,9 17.1984575,6 17.0155219,0" transform="translate(12.530548, 4.500000) scale(1, -1) translate(-12.530548, -4.500000) "></path>
          <path d="M9.04756902,11 L9.04756902,19.0646214"></path>
          <path d="M18.047569,11.5785561 C18.047569,11.1858398 14.2680033,12.6862174 14.2680033,15.7355654 C14.2680033,16.7905624 15.1469631,19.5602241 15.7184016,19.5602241 L18.047569,19.5602241 C18.047569,19.5602241 18.047569,11.8387987 18.047569,11.5785561 Z"></path>
        </g>
    </g>
  </w-icon>
</template>

<script>
import Icon from './Icon'

export default {
  components: {
    'w-icon': Icon
  }
}
</script>

<template>
  <div v-if="show" class="category-sidebar">
    <div class="category-sidebar-inner">
      <w-sticky scroll-target=".category-sidebar">
        <w-category-sidebar-header></w-category-sidebar-header>
      </w-sticky>
      <ul class="category-sidebar__category-list">
        <li
          v-for="category in categories"
          :key="category.id"
          :class="{
            'category-sidebar__category-list-item': true,
            'category-sidebar__category-list-item--selected': isSelected(
              category.id
            ),
          }"
          @click="select(category.id)"
        >
          {{ category.name }}
        </li>
      </ul>
      <template v-if="hasProductLabels">
        <!-- hidden labels act as placeholder -->
        <w-product-labels
          v-if="hasProductLabels"
          style="visibility: hidden"
        ></w-product-labels>
        <!-- without placeholder these could overlap categories -->
        <w-product-labels
          v-if="hasProductLabels"
          style="bottom: 0; left: 0; position: absolute; width: 100%"
        ></w-product-labels>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import { Route } from '../enums';

import Sticky from './Sticky';
import ProductLabels from './ProductLabels';
import CategorySidebarHeader from './CategorySidebarHeader';

export default {
  computed: {
    ...mapState(['facility']),
    ...mapGetters(['categories', 'selectedCategory', 'hasProductLabels']),
    show() {
      return this.categories.length > 1;
    },
    isSelected() {
      return (id) => id === this.selectedCategory.id;
    },
  },
  methods: {
    ...mapMutations(['setSelectedCategoryId', 'setScrollPosition']),
    select(categoryId) {
      this.setSelectedCategoryId(categoryId);
      const selector = `[data-category-id="${categoryId}"]`;
      if (this.$router.currentRoute.name === Route.ProductList) {
        const { offsetTop } = window.document.querySelector(selector);
        window.scrollTo(0, offsetTop);
      } else {
        this.setScrollPosition({ selector });
        this.$router.push({ name: Route.ProductList });
      }
    },
  },
  components: {
    'w-sticky': Sticky,
    'w-category-sidebar-header': CategorySidebarHeader,
    'w-product-labels': ProductLabels,
  },
};
</script>

<style lang="scss">
@import 'bulma/sass/utilities/_all';

@import '../styles/_variables.scss';
@import '../styles/_mixins.scss';

.category-sidebar {
  display: none;
  height: 100%;
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: $sidebar-width;

  @include from($device: $tablet) {
    display: block;
  }
}

.category-sidebar-inner {
  background-color: var(--app-theme__backgroundColor);
  min-height: 100%;
  position: relative;
}

.category-sidebar__category-list {
  padding: 0 $column-gap-sidebar-width-percentage-tablet;
}

.category-sidebar__category-list-item {
  cursor: pointer;
  padding: 1rem 0;

  &:hover {
    color: var(--app-theme__primaryColor);
  }
}

.category-sidebar__category-list-item--selected {
  color: var(--app-theme__primaryColor);
}

@include from($device: $desktop) {
  .category-sidebar__category-list {
    padding: 0 $column-gap-sidebar-width-percentage-desktop;
  }
}

.app--iframe {
  .category-sidebar-inner {
    @include from($device: $tablet) {
      @include sidebar-background-tablet();
    }
    @include from($device: $desktop) {
      @include sidebar-background-desktop();
    }
  }
  .category-sidebar__category-list {
    padding: 0 10% 0 $column-gap-sidebar-width-percentage-tablet + 10%;
  }
  @include from($device: $desktop) {
    .category-sidebar__category-list {
      padding: 0 10% 0 $column-gap-sidebar-width-percentage-desktop + 10%;
    }
  }
}
</style>

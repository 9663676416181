import Vue from 'vue';
import Vuex from 'vuex';

import root from './root';
import cartItemForm from './cartItemForm';
import checkoutForm from './checkoutForm';
import availabilities from './availabilities';

Vue.use(Vuex);

export default (...args) =>
  new Vuex.Store({
    ...root(...args),
    modules: {
      cartItemForm,
      checkoutForm: checkoutForm(...args),
      availabilities: availabilities(...args),
    },
  });

<template>
  <div class="stripe-element">
    <div class="stripe-element__element" ref="element"></div>
    <div class="stripe-element__error" v-if="formErrors.stripe">
      {{formErrors.stripe}}
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    type: String,
    options: Object
  },
  computed: {
    ...mapState([
      'theme',
      'stripeInstance'
    ]),
    ...mapState('checkoutForm', [
      'formErrors'
    ])
  },
  methods: {
    ...mapActions([
      'addStripeElement'
    ]),
    ...mapActions('checkoutForm', [
      'setFormValue',
      'setFormError'
    ])
  },
  mounted () {
    const element = this.stripeInstance.elements().create(this.type, {
      style: {
        base: {
          color: this.theme.textColor,
          '::placeholder': {
            color: this.theme.lineColor
          },
          iconColor: this.theme.textColor,
          fontSize: '16px'
        },
        invalid: {
          color: this.theme.errorColor,
          iconColor: this.theme.errorColor
        }
      },
      ...this.options
    })
    element.addEventListener('ready', () => {
      const input = this.$refs.element.getElementsByTagName('input')[0]
      if (input) {
        input.focus()
      }
      this.setFormValue({
        name: 'stripe',
        value: false
      })
    })
    element.addEventListener('change', e => {
      if (e.error) {
        this.setFormError({
          name: 'stripe',
          value: e.error.message
        })
      } else if (e.complete) {
        this.setFormValue({
          name: 'stripe',
          value: true
        })
      }
    })
    element.mount(this.$refs.element)
    this.addStripeElement({ type: this.type, element })
  },
  beforeDestroy () {
    this.setFormValue({
      name: 'stripe',
      value: null
    })
  }
}
</script>

<style>
.stripe-element {
  margin-bottom: 1rem;
}
.stripe-element__element {
  background-color: var(--app-theme__menuColor);
  padding: 1rem;
}
.stripe-element__error {
  color: var(--app-theme__errorColor);
  padding-top: 0.25rem;
}
</style>

export const ApiEndpoint = {
  Facility: '/',
  Menu: '/menu_widget',
  Product: '/menu_widget_product',
  Cart: '/menu_widget_cart',
  CartItem: '/menu_widget_cart_item',
  Order: '/menu_widget_order',
  AvailableDays: '/menu_widget_availabilities/available_days',
  PickupAvailabilities: '/menu_widget_availabilities/pickup_availabilities',
  DeliveryAvailabilities: '/menu_widget_availabilities/delivery_availabilities',
  Voucher: '/menu_widget_order/verify_voucher',
};

export const Route = {
  Checkout: 'CHECKOUT',
  CheckoutSuccess: 'CHECKOUT_SUCCESS',
  ProductList: 'PRODUCT_LIST',
  ProductDetails: 'PRODUCT_DETAILS',
};

export const CartType = {
  Pickup: 'pickup',
  Delivery: 'delivery',
  QrOrder: 'qr_order',
};

export const CartStatus = {
  Open: 'open',
  Closed: 'closed',
};

export const OrderType = CartType;

export const PaymentType = {
  Cash: 'OUTSTANDING',
  PayPal: 'PAYPAL',
  CreditCard: 'CCARD',
  DirectDebit: 'SEPA-DD',
  Bambora: 'BAMBORA',
  Voucher: 'VOUCHER',
};

export const StripeElement = {
  Card: 'card',
  Iban: 'iban',
};

export const StorageKey = {
  Cart: 'CART',
  Order: 'ORDER',
  Places: 'PLACES',
  Params: 'PARAMS',
};

export const QrOrderType = {
  SeatOrder: 'seat_order',
  RoomOrder: 'room_order',
  TableOrder: 'table_order',
};

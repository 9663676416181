import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js';

import 'moment/locale/cs';
import 'moment/locale/da';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fi';
import 'moment/locale/fr';
import 'moment/locale/hr';
import 'moment/locale/it';
import 'moment/locale/lt';
import 'moment/locale/lv';
import 'moment/locale/nl';
import 'moment/locale/pl';
import 'moment/locale/pt';
import 'moment/locale/ru';
import 'moment/locale/sv';
import 'moment/locale/tr';

const configureMoment = ({ timezone, languageCode }) => {
  moment.locale(moment.locales().includes(languageCode) ? languageCode : 'en');
  if (timezone) {
    moment.tz.setDefault(timezone);
  }

  const locale = moment.locale();
  const localeData = moment.localeData();
  moment.updateLocale(locale, {
    longDateFormat: {
      LT: 'HH:mm',
      LTS: 'HH:mm:ss',
      L: localeData.longDateFormat('L'),
      LL: localeData.longDateFormat('LL'),
      LLL: localeData.longDateFormat('LLL'),
      LLLL: localeData.longDateFormat('LLLL'),
    },
  });
  return moment;
};

export default configureMoment;

<template>
  <w-page layout="error">
    <w-menu-error v-if="isMenuError" :message="message"></w-menu-error>
    <w-generic-error v-else :message="message"></w-generic-error>
  </w-page>
</template>

<script>
import { mapState } from 'vuex'

import { MissingMenuError, MenuNotPublishedError } from '../../errors'

import Page from '../Page'

import MenuError from './MenuError'
import GenericError from './GenericError'

export default {
  computed: {
    ...mapState([
      'error'
    ]),
    message () {
      return this.error.message
    },
    isMenuError () {
      return this.error instanceof MissingMenuError || this.error instanceof MenuNotPublishedError
    }
  },
  components: {
    'w-page': Page,
    'w-menu-error': MenuError,
    'w-generic-error': GenericError
  }
}
</script>

<template>
  <w-icon v-bind="$attrs" v-on="$listeners">
    <g
      id="symbol-vegan"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <circle
        id="Oval-51"
        stroke="#22CCAA"
        stroke-width="1.3"
        fill="#FFFFFF"
        cx="16"
        cy="16"
        r="15"
      ></circle>
      <g
        id="Group"
        transform="translate(22.193577, 18.499087) rotate(-8.000000) translate(-22.193577, -18.499087) translate(15.193577, 9.499087)"
        stroke="#22CCAA"
        stroke-width="1.3"
      >
        <path
          d="M4.58737998,15.8984999 C4.58737998,15.8984999 10.2981879,14.7060065 11.6914443,11.047701 C13.0847007,7.38939542 9.4179072,1.59405945 9.4179072,1.59405945 C9.4179072,1.59405945 8.21537292,3.61342842 6.25120862,4.77495666 C4.97704427,5.52844651 3.07693896,6.06199258 2.52874333,7.50140254 C1.13548694,11.1597081 4.58737998,15.8984999 4.58737998,15.8984999 Z"
          id="Oval-51"
          transform="translate(7.101586, 8.746280) rotate(16.000000) translate(-7.101586, -8.746280) "
        ></path>
        <path
          d="M0.996663139,6.42183954 C2.47734324,7.50230825 3.79104808,8.73775522 4.93777768,10.1281805 C6.08450727,11.5186057 7.43746909,13.9498254 8.99666314,17.4218395"
          id="Line"
          stroke-linecap="square"
          transform="translate(4.996663, 11.921840) scale(-1, 1) translate(-4.996663, -11.921840) "
        ></path>
      </g>
      <path
        d="M15.3805573,23 C15.3805573,23 7.98083242,22.1211363 5.78195917,17.7894626 C3.58308592,13.457789 7.56745569,6 7.56745569,6 C7.56745569,6 9.33230047,8.34710372 11.9651685,9.56808924 C13.6731246,10.3601494 16.1511545,10.8198036 17.016331,12.5241594 C19.2152042,16.8558331 15.3805573,23 15.3805573,23 Z"
        id="Oval-51"
        stroke="#22CCAA"
        stroke-width="1.3"
      ></path>
      <path
        d="M9.94922175,13 C11.4975453,14.6329713 12.8047289,16.4130366 13.8707724,18.340196 C14.936816,20.2673553 16.0045206,23.48729 17.0738862,28"
        id="Line"
        stroke="#22CCAA"
        stroke-width="1.3"
        stroke-linecap="square"
      ></path>
    </g>
  </w-icon>
</template>

<script>
import Icon from './Icon';

export default {
  components: {
    'w-icon': Icon,
  },
};
</script>

import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js';

export default (
  _,
  {
    theme,
    source,
    iframe,
    preview,
    facility,
    staticUrl,
    qrModeEnabled,
    stripeInstance,
    qrOrderType,
    isOrderbirdFacility,
    qrModeLocation,
    newsletterSignup,
  }
) => ({
  orderDate: moment(),

  theme,
  source,
  iframe,
  preview,
  staticUrl,
  qrModeEnabled,
  qrOrderType,
  qrModeLocation,
  isOrderbirdFacility,
  newsletterSignup,

  stripeInstance,
  stripeElements: {},

  error: null,
  fetching: false,
  flashMessage: null,

  facility,
  cart: null,
  order: null,
  menus: null,
  voucher: null,
  productsById: {},
  productsByResourceUri: {},
  forbiddenProducts: [],
  selectedProductId: null,
  selectedCategoryId: null,

  googleMapsPlace: null,

  cartDrawerIsOpen: false,
  showOrderMethodPopover: false,

  scrollPosition: null, // last scroll position in product / category list
  expandedCategories: {},

  rememberLastOrder: false,
});

<template>
  <w-icon>
    <g transform="translate(-4, -4)">
      <path d="M19.6331556,31.4081997 C19.979817,31.8347396 20.5565998,31.8570076 20.9289733,31.4390493 C20.9289733,31.4390493 29.1500003,22.6919606 29.1499996,16.7233516 C29.149999,10.7547426 25.4977258,8 20.2608416,8 C15.0239574,8 12,11.0718459 12,16.7233516 C12,22.3748573 19.6331556,31.4081997 19.6331556,31.4081997 Z M20.5079602,19.6424719 C22.2391032,19.6424719 23.6424719,18.2391032 23.6424719,16.5079602 C23.6424719,14.7768172 22.2391032,13.3734486 20.5079602,13.3734486 C18.7768172,13.3734486 17.3734486,14.7768172 17.3734486,16.5079602 C17.3734486,18.2391032 18.7768172,19.6424719 20.5079602,19.6424719 Z" stroke="currentColor" stroke-width="1.3"></path>
    </g>
  </w-icon>
</template>

<script>
import Icon from './Icon'

export default {
  components: {
    'w-icon': Icon
  }
}
</script>

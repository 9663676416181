<template>
  <div
    :class="{
      message: true,
      'message--alert': alert,
      'message--error': error
    }"
    v-on="$listeners"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    alert: Boolean,
    error: Boolean
  }
}
</script>

<style>
.message--alert{
  color: var(--app-theme__alertColor);
}
.message--error {
  color: var(--app-theme__errorColor);
}
</style>

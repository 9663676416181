<template>
  <w-message v-if="cartIsEmpty">
    <div>{{messageText}}</div>
  </w-message>
</template>

<script>
import { mapGetters } from 'vuex'

import i18n from '../../i18n'

import Message from './Message'

export default {
  data () {
    return {
      messageText: i18n.gettext('Your cart is currently empty.')
    }
  },
  computed: {
    ...mapGetters(['cartIsEmpty']),
  },
  components: {
    'w-message': Message
  }
}
</script>

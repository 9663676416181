<template>
  <div v-if="show" class="cart-drawer-toggle">
    <w-cart-button @click="toggleCartDrawer"></w-cart-button>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import CartButton from './CartButton';

export default {
  computed: {
    ...mapState(['cartDrawerIsOpen']),
    ...mapGetters(['orderingEnabled']),
    show() {
      return this.orderingEnabled;
    },
  },
  methods: {
    ...mapActions(['toggleCartDrawer']),
  },
  components: {
    'w-cart-button': CartButton,
  },
};
</script>

<style lang="scss">
@import 'bulma/sass/utilities/_all';

@import '../styles/_variables.scss';

.cart-drawer-toggle {
  color: var(--app-theme__gradientBottomColor);
  display: none;
  position: fixed;
  right: 1rem;
  top: 1rem;

  @at-root .app--iframe & {
    color: var(--app-theme__primaryColor);
  }

  @include from($device: $tablet) {
    display: block;
  }
}
</style>

import {
  MissingMenuError,
  MenuNotPublishedError,
  ProductFetchError,
  CartUpdateError,
} from '../../errors';

import { CartType, CartStatus, OrderType, StorageKey } from '../../enums';

export default ({ apiService, storageService }) => ({
  async initialize({ state, getters, commit, dispatch }) {
    try {
      const menus = await apiService.getMenus();
      commit('setMenus', Object.freeze(menus));

      if (!(getters.menu && getters.hasCategories && getters.hasProducts)) {
        throw new MissingMenuError();
      }
      if (!getters.menu.enabled && !state.preview) {
        throw new MenuNotPublishedError();
      }

      const facility = await apiService.getFacility();
      commit('setFacility', facility);
      if (!getters.qrOrderingEnabled) {
        await dispatch('availabilities/fetchAvailableDays');
      }
    } catch (error) {
      commit('setError', error);
      return;
    }
    try {
      const { defaultCartType } = getters;
      let cart = storageService.get(StorageKey.Cart);
      if (!cart) {
        throw new Error();
      }
      cart = await apiService.getCart(cart);
      if (cart.status !== CartStatus.Open) {
        throw new Error();
      }
      if (cart.type !== defaultCartType) {
        if (cart.type === CartType.QrOrder) {
          // if the stored cart is of type qr_order throw it away as
          // there could be items on it that are not orderable online / right now
          throw new Error();
        }
        cart = await apiService.updateCart({ ...cart, type: defaultCartType });
      }
      commit('setCart', cart);
    } catch {
      dispatch('resetCart');
    }
    if (getters.hasSingleCategory) {
      dispatch('toggleCategory', getters.categories[0].id);
    }
    if (getters.deliveryEnabled) {
      const places = storageService.get(StorageKey.Places) || [];
      if (places.length > 0) {
        dispatch('selectGoogleMapsPlace', places[0]);
      }
    }
    if (
      (getters.orderingEnabled && !state.preview) ||
      getters.qrOrderingEnabled
    ) {
      dispatch('toggleOrderMethodPopover');
    }
  },
  async fetchProduct({ state, commit, dispatch }, id) {
    try {
      const product = await apiService.getProduct(id);
      commit('setProductsById', {
        ...state.productsById,
        [id]: product,
      });
      commit('setProductsByResourceUri', {
        ...state.productsByResourceUri,
        [product.resource_uri]: product,
      });
    } catch (error) {
      dispatch('flashMessage', new ProductFetchError());
      throw error;
    }
  },
  toggleCartDrawer({ state, commit, dispatch }) {
    const isOpen = !state.cartDrawerIsOpen;
    commit('setCartDrawerIsOpen', isOpen);
  },
  toggleOrderMethodPopover({ state, commit, dispatch }) {
    const showPopover = !state.showOrderMethodPopover;
    commit('setShowOrderMethodPopover', showPopover);
  },
  addStripeElement({ state, commit }, { type, element }) {
    commit('setStripeElements', {
      ...state.stripeElements,
      [type]: element,
    });
  },
  toggleCategory({ state, commit }, categoryId) {
    const { expandedCategories } = state;
    commit('setExpandedCategories', {
      ...expandedCategories,
      [categoryId]: !expandedCategories[categoryId],
    });
  },
  flashMessage({ commit }, message) {
    commit('setFlashMessage', message);
  },
  async selectOrderType({ state, getters, commit, dispatch }, type) {
    if (type === getters.selectedOrderType) {
      return;
    }
    const cartType =
      type === OrderType.Pickup ? CartType.Pickup : CartType.Delivery;
    if (!state.cart.id) {
      commit('setCart', { ...state.cart, type: cartType });
      return;
    }
    const cart = await apiService.updateCart({ ...state.cart, type: cartType });
    commit('setCart', cart);
    storageService.set(StorageKey.Cart, cart);
  },
  selectGoogleMapsPlace({ commit }, place) {
    place = JSON.parse(JSON.stringify(place));
    commit('setGoogleMapsPlace', place);
    if (place === null) {
      return;
    }
    const places = storageService.get(StorageKey.Places) || [];
    const newPlaces = [...places];
    const index = places.findIndex(
      ({ formatted_address }) => formatted_address === place.formatted_address
    );
    if (index > -1) {
      newPlaces.splice(index, 1); // make sure we don't store places twice
    }
    newPlaces.unshift(place); // put last selected place first in list
    storageService.set(StorageKey.Places, newPlaces.slice(0, 5)); // store at max five places
  },
  toggleRememberLastOrder({ state, commit }) {
    commit('setRememberLastOrder', !state.rememberLastOrder);
  },
  // CART RELATED
  resetCart({ getters, commit }) {
    commit('setCart', {
      type: getters.defaultCartType,
      product_cart_items: [],
    });
    storageService.set(StorageKey.Cart, null);
  },
  async createCartItem({ state, getters, commit, dispatch }, item) {
    try {
      let cart = state.cart;
      if (!cart.id) {
        cart = await apiService.createCart({ type: cart.type });
        commit('setCart', cart);
        storageService.set(StorageKey.Cart, cart);
      }
      const createdItem = await apiService.createCartItem(
        {
          ...item,
          facility: state.facility.resource_uri,
          menu_widget_cart: cart.resource_uri,
        },
        cart
      );
      commit('setCart', {
        ...cart,
        product_cart_items: [...getters.cartItems, createdItem],
      });
      return createdItem;
    } catch (error) {
      dispatch('flashMessage', new CartUpdateError());
      throw error;
    }
  },
  async updateCartItem({ state, getters, commit, dispatch }, item) {
    try {
      const { cart } = state;
      const updatedItem = await apiService.updateCartItem(item, cart);
      commit('setCart', {
        ...cart,
        product_cart_items: getters.cartItems.map((item) =>
          item.resource_uri !== updatedItem.resource_uri ? item : updatedItem
        ),
      });
      if (state.cartItemForm.formValues.id === item.id) {
        // might get called from product details or cart item list
        dispatch('cartItemForm/updateItem', updatedItem);
      }
      return updatedItem;
    } catch (error) {
      dispatch('flashMessage', new CartUpdateError());
      throw error;
    }
  },
  async deleteCartItem({ state, getters, commit, dispatch }, item) {
    try {
      const { cart } = state;
      await apiService.deleteCartItem(item, cart);
      commit('setCart', {
        ...cart,
        product_cart_items: getters.cartItems.filter(
          ({ resource_uri }) => resource_uri !== item.resource_uri
        ),
      });
    } catch (error) {
      dispatch('flashMessage', new CartUpdateError());
      throw error;
    }
  },
});

<template>
  <w-icon v-bind="$attrs" v-on="$listeners">
    <g strokeLinecap="square">
      <path d="M5.5 6.5L25.5 26.5M25 7L5 27"></path>
    </g>
  </w-icon>
</template>

<script>
import Icon from './Icon'

export default {
  components: {
    'w-icon': Icon
  }
}
</script>

<template>
  <div class="checkout-footer">
    <w-cart-total class="checkout-footer__total"></w-cart-total>
    <div v-if="!checkoutEnabled">
      <w-acceptance-hours-message
        class="checkout-footer__message"
      ></w-acceptance-hours-message>
      <w-order-value-message
        class="checkout-footer__message"
      ></w-order-value-message>
      <w-forbidden-products-message
        class="checkout-footer__message"
      ></w-forbidden-products-message>
    </div>
    <w-paypal-buttons v-if="payWithPayPal" :disabled="submitDisabled">
    </w-paypal-buttons>
    <w-button
      v-else
      class="checkout-footer__button"
      primary
      :loading="submitting"
      :disabled="submitDisabled"
      @click="submitOrder"
    >
      {{ orderButtonText }}
    </w-button>
    <div class="checkout-footer__disclaimer" v-html="disclaimerText"></div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

import { BamboraCheckout } from '../../../common/';

import i18n from '../../i18n';
import { Route, PaymentType, StripeElement } from '../../enums';
import { extractErrorMessage } from '../../utils';

import Button from '../Button';
import CartTotal from '../CartTotal';
import {
  ForbiddenProductsMessage,
  AcceptanceHoursMessage,
  OrderValueMessage,
} from '../messages';

import PayPalButtons from './PayPalButtons';

export default {
  data() {
    return {
      isCheckingOut: false,
    };
  },
  computed: {
    ...mapState(['facility', 'stripeInstance', 'stripeElements']),
    ...mapGetters([
      'cartTotal',
      'getPriceTag',
      'checkoutEnabled',
      'isVoucherCoversFull',
      'voucherAmount',
      'termsAndConditionsUrl',
      'privacyPolicyUrl',
    ]),
    ...mapState('checkoutForm', ['submitting', 'formValues']),
    ...mapGetters('checkoutForm', [
      'formIsValid',
      'payCash',
      'payWithPayPal',
      'payWithBambora',
      'payWithCreditCard',
      'payWithDirectDebit',
    ]),
    totalPriceTag() {
      return this.getPriceTag(Math.max(this.cartTotal - this.voucherAmount, 0));
    },
    orderButtonText() {
      if (this.isVoucherCoversFull) {
        return i18n.gettext('Pay with voucher');
      }
      return this.payCash
        ? i18n.gettext('Pay cash')
        : i18n.gettext('Order now');
    },
    disclaimerText() {
      if (this.formValues.payment_type === PaymentType.PayPal) {
        return i18n.sprintf(
          i18n.gettext(
            'By checking out with PayPal you agree to our <a href="%s" target="_blank">Terms & Conditions</a> and <a href="%s" target="_blank">Privacy Policy.</a>'
          ),
          this.termsAndConditionsUrl,
          this.privacyPolicyUrl
        );
      }
      return i18n.sprintf(
        i18n.gettext(
          'By clicking the "%s" button you agree to our <a href="%s" target="_blank">Terms & Conditions</a> and <a href="%s" target="_blank">Privacy Policy.</a>'
        ),
        this.orderButtonText,
        this.termsAndConditionsUrl,
        this.privacyPolicyUrl
      );
    },
    submitDisabled() {
      return !(this.checkoutEnabled && this.formIsValid) || this.isCheckingOut;
    },
  },
  methods: {
    ...mapActions('checkoutForm', [
      'submitForm',
      'setFormValue',
      'setFormError',
    ]),
    ...mapMutations('checkoutForm', ['setServerError']),
    async submitOrder() {
      if (this.payWithBambora) {
        try {
          this.isCheckingOut = true;
          const result = await new BamboraCheckout(this.facility.id).checkout(
            this.cartTotal - this.voucherAmount,
            this.formValues.email
          );
          if (!result.transactionId) {
            // checkout has been canceled
            return;
          }
          this.setFormValue({ name: 'token', value: result.transactionId });
          this.setFormValue({
            name: 'bambora_session_token',
            value: result.sessionToken,
          });
        } catch (e) {
          this.setServerError(
            extractErrorMessage(
              e,
              i18n.gettext(
                "There's been a problem. Please try again or choose another payment option."
              )
            )
          );
          return;
        } finally {
          this.isCheckingOut = false;
        }
      }
      try {
        await this.submitForm();
        this.$router.replace({ name: Route.CheckoutSuccess });
      } catch {}
    },
  },
  components: {
    'w-button': Button,
    'w-paypal-buttons': PayPalButtons,
    'w-forbidden-products-message': ForbiddenProductsMessage,
    'w-acceptance-hours-message': AcceptanceHoursMessage,
    'w-order-value-message': OrderValueMessage,
    'w-cart-total': CartTotal,
  },
};
</script>

<style lang="scss">
@import 'bulma/sass/utilities/_all';

.checkout-footer a {
  color: var(--app-theme__primaryColor);
}
.checkout-footer__total {
  margin-bottom: 2rem;
  > * {
    padding: 0;
  }
  @include from($device: $tablet) {
    display: none;
  }
}
.checkout-footer__button {
  display: flex;
  justify-content: center;
}
.checkout-footer__disclaimer {
  padding-top: 1rem;
  text-align: center;
}
.checkout-footer__message {
  margin-bottom: 1rem;
}
</style>

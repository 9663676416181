import './styles/index.scss';

const hexToRgb = (hexString = '#FFFFFF') => {
  let hex = String(hexString).replace(/[^0-9a-f]/gi, '');
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const rgbString = result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16
      )}`
    : '0, 0, 0';
  return rgbString;
};

const configureStyles = ({
  linkBackgroundColor,
  backgroundColor,
  color,
  bannerColor,
  fontSize,
  fullscreen,
}) => {
  const sheet = document.createElement('style');
  if (fontSize) {
    sheet.innerHTML += `
      body {
        font-size: ${fontSize} !important;
      }
      `;
  }

  document.addEventListener(
    'DOMContentLoaded',
    () => {
      document.body.appendChild(sheet);
    },
    false
  );
};

export default configureStyles;

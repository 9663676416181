import state from './state'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'

export default (serviceContainer, initialState) => ({
  state: state(serviceContainer, initialState),
  getters: getters(serviceContainer),
  actions: actions(serviceContainer),
  mutations,
  namespaced: true
})

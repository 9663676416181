import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js';

import { OrderType } from '../enums';

export default ({ apiService }) => ({
  state: {
    availableDays: null,
    availabilitiesByDay: null,
  },
  getters: {
    pickupDays(state) {
      return state.availableDays
        .filter(({ can_pickup }) => can_pickup)
        .map(({ date }) => moment(date).format('YYYY-MM-DD'));
    },
    deliveryDays(state) {
      return state.availableDays
        .filter(({ can_deliver }) => can_deliver)
        .map(({ date }) => moment(date).format('YYYY-MM-DD'));
    },
    canPickupToday(_, getters) {
      const now = moment();
      return !!getters.pickupDays.find((date) => moment(date).isSame(now, 'd'));
    },
    canDeliverToday(_, getters) {
      const now = moment();
      return !!getters.deliveryDays.find((date) =>
        moment(date).isSame(now, 'd')
      );
    },
    canPickupInAdvance(_, getters) {
      const now = moment();
      return !!getters.pickupDays.find((date) =>
        moment(date).isAfter(now, 'd')
      );
    },
    canDeliverInAdvance(_, getters) {
      const now = moment();
      return !!getters.deliveryDays.find((date) =>
        moment(date).isAfter(now, 'd')
      );
    },
    orderDays(_, getters, ___, rootGetters) {
      switch (rootGetters.selectedOrderType) {
        case OrderType.Pickup:
          return getters.pickupDays;
        case OrderType.Delivery:
          return getters.deliveryDays;
        default:
          throw new Error('ordering in advance not supported for qr orders');
      }
    },
    canOrderToday(_, getters, __, rootGetters) {
      switch (rootGetters.selectedOrderType) {
        case OrderType.Pickup:
          return getters.canPickupToday;
        case OrderType.Delivery:
          return getters.canDeliverToday;
        default:
          return rootGetters.isInsideCurrentQrOrderingHours;
      }
    },
    canOrderInAdvance(_, getters, __, rootGetters) {
      switch (rootGetters.selectedOrderType) {
        case OrderType.Pickup:
          return getters.canPickupInAdvance;
        case OrderType.Delivery:
          return getters.canDeliverInAdvance;
        default:
          return false;
      }
    },
    canOrderTodayOrInAdvance(_, getters) {
      return getters.canOrderToday || getters.canOrderInAdvance;
    },
  },
  actions: {
    async fetchAvailableDays({ rootGetters, commit }) {
      const availableDays = await apiService.getAvailableDays(
        moment(),
        rootGetters.menu
      );
      commit('setAvailableDays', availableDays);
      return availableDays;
    },
    async fetchAvailabilities({ state, getters, rootGetters, commit }, date) {
      let availabilities;
      switch (rootGetters.selectedOrderType) {
        case OrderType.Pickup:
          if (!date) date = getters.pickupDays[0];
          availabilities = await apiService.getPickupAvailabilities(
            date,
            rootGetters.menu
          );
          break;
        case OrderType.Delivery:
          if (!date) date = getters.deliveryDays[0];
          availabilities = await apiService.getDeliveryAvailabilities(
            date,
            rootGetters.menu
          );
          break;
        default:
          throw new Error("can't fetch availabilities for qr orders");
      }
      availabilities = availabilities
        .filter(({ available }) => available)
        .map(({ date }) => date);
      commit('setAvailabilitiesByDay', {
        ...state.availabilitiesByDay,
        [moment(date).format('YYYY-MM-DD')]: availabilities,
      });
      return availabilities;
    },
  },
  mutations: {
    setAvailableDays(state, availableDays) {
      state.availableDays = availableDays;
    },
    setAvailabilitiesByDay(state, availabilitiesByDay) {
      state.availabilitiesByDay = availabilitiesByDay;
    },
  },
  namespaced: true,
});

<template>
  <div class="footer">
    <div class="footer__labels-container" v-if="hasProductLabels">
      <div class="footer_labels">
        <div class="footer_label">
          <div class="footer_label-icon"><w-alcohol-icon></w-alcohol-icon></div>
          <div class="footer_label-text">{{ alcoholText }}</div>
        </div>
        <div class="footer_label">
          <div class="footer_label-icon"><w-vegan-icon></w-vegan-icon></div>
          <div class="footer_label-text">{{ veganText }}</div>
        </div>
        <div class="footer_label">
          <div class="footer_label-icon">
            <w-vegetarian-icon></w-vegetarian-icon>
          </div>
          <div class="footer_label-text">{{ vegetarianText }}</div>
        </div>
        <div class="footer_label">
          <div class="footer_label-icon"><w-spicy-icon></w-spicy-icon></div>
          <div class="footer_label-text">{{ spicyText }}</div>
        </div>
        <div class="footer_label">
          <div class="footer_label-icon"><w-hot-icon></w-hot-icon></div>
          <div class="footer_label-text">{{ hotText }}</div>
        </div>
        <div class="footer_label">
          <div class="footer_label-icon">
            <w-extra-hot-icon></w-extra-hot-icon>
          </div>
          <div class="footer_label-text">{{ extraHotText }}</div>
        </div>
      </div>
    </div>
    <div class="footer__powered-by">
      {{ poweredByText }}
      <a
        class="footer__logo footer__link footer__brand-link"
        :href="brandUrl"
        target="_blank"
      >
        <w-orderbird-logo
          v-if="isOrderbirdFacility"
          :darkBG="hasDarkBackground"
        ></w-orderbird-logo>
        <w-resmio-logo v-else></w-resmio-logo>
      </a>
    </div>
    <div class="footer__legal">
      <a class="footer__link" :href="termsAndConditionsUrl" target="_blank">{{
        termsAndConditionsText
      }}</a>
      |
      <a class="footer__link" :href="privacyPolicyUrl" target="_blank">{{
        privacyPolicyText
      }}</a>
      |
      <a class="footer__link" :href="legalNoticeUrl" target="_blank">{{
        legalNoticeText
      }}</a>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import i18n from '../i18n';

import ResmioLogo from './ResmioLogo';
import OrderbirdLogo from './OrderbirdLogo';
import { isLightColor } from '../utils';

import {
  AlcoholIcon,
  VeganIcon,
  VegetarianIcon,
  SpicyIcon,
  HotIcon,
  ExtraHotIcon,
} from './icons';

export default {
  data() {
    return {
      poweredByText: i18n.gettext('Powered by'),
      termsAndConditionsText: i18n.gettext('Terms & Conditions'),
      privacyPolicyText: i18n.gettext('Privacy Policy'),
      legalNoticeText: i18n.gettext('Legal Notice'),
      alcoholText: i18n.gettext('Alcohol'),
      veganText: i18n.gettext('Vegan'),
      vegetarianText: i18n.gettext('Vegetarian'),
      spicyText: i18n.gettext('Spicy'),
      hotText: i18n.gettext('Hot'),
      extraHotText: i18n.gettext('Extra hot'),
    };
  },
  computed: {
    ...mapState(['theme', 'iframe', 'isOrderbirdFacility']),
    ...mapGetters([
      'hasProductLabels',
      'termsAndConditionsUrl',
      'privacyPolicyUrl',
      'legalNoticeUrl',
    ]),
    brandUrl() {
      return this.isOrderbirdFacility
        ? i18n.gettext('https://orderbird.com')
        : i18n.gettext('https://resmio.com');
    },
    hasDarkBackground() {
      const color = this.iframe
        ? this.theme.backgroundColor
        : this.theme.gradientBottomColor;
      return !isLightColor(color);
    },
  },
  components: {
    'w-resmio-logo': ResmioLogo,
    'w-orderbird-logo': OrderbirdLogo,
    'w-alcohol-icon': AlcoholIcon,
    'w-vegan-icon': VeganIcon,
    'w-vegetarian-icon': VegetarianIcon,
    'w-spicy-icon': SpicyIcon,
    'w-hot-icon': HotIcon,
    'w-extra-hot-icon': ExtraHotIcon,
  },
};
</script>

<style lang="scss">
@import 'bulma/sass/utilities/_all';

@import '../styles/_variables.scss';
@import '../styles/_mixins.scss';

.footer {
  color: var(--app-theme__lineColor);
  margin: auto 0 11.5rem;
  padding: 1rem;
}
.footer__powered-by {
  align-items: center;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
}
.footer__logo {
  padding: 0.25rem 0 0 0.25rem;
}
.footer__legal {
  margin-top: -0.5rem;
  text-align: center;
}
.footer__link {
  color: inherit;
}
.footer__link:hover {
  color: var(--app-theme__primaryColor);
}
.footer__brand-link,
.footer__brand-link:hover {
  color: #474747;
}

@include from($device: $tablet) {
  .footer {
    margin-bottom: 0;
  }
  .footer__labels-container {
    display: none;
  }
}

.footer__labels-container {
  font-size: 0.7rem;
}

.footer_labels {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0rem 2rem;
}

.footer_label {
  display: flex;
  padding: 0.2rem 0.5rem;
}

.footer_label-icon {
  flex: 1 1 auto;
  padding-right: 0.5rem;
  svg {
    width: 1rem;
    height: 1rem;
  }
}

.footer_label-text {
  flex: 1 1 auto;
  color: var(--app-theme__textColor);
}
.app--iframe {
  .footer__brand-link,
  .footer__brand-link:hover {
    color: var(--app-theme__textColor);
  }
}
</style>

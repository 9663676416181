<template>
  <w-icon class="check-icon">
    <path d="M3 18.2L11.4 26.7C11.8 27.1 12.4 27.1 12.8 26.7L30.1 9" />
  </w-icon>
</template>

<script>
import Icon from './Icon'

export default {
  components: {
    'w-icon': Icon
  }
}
</script>

<style>
.check-icon {
  width: 1.25rem;
  height: 1.25rem;
}
</style>

<template>
  <w-icon>
    <g transform="translate(0.75, -1)">
      <path
        d="M16 30.1C23.4 30.1 29.3 24.2 29.3 16.8 29.3 9.5 23.4 3.5 16 3.5 8.6 3.5 2.7 9.5 2.7 16.8 2.7 24.2 8.6 30.1 16 30.1ZM3.7 22.1L28.1 22.1M2.6 16.8L29.4 16.8M3.7 11.5L28.1 11.5M16.1 30.3C18.3 30.3 20.1 24.4 20.1 17 20.1 9.7 18.3 3.7 16.1 3.7 13.9 3.7 12.2 9.7 12.2 17 12.2 24.4 13.9 30.3 16.1 30.3ZM16.1 30.3C21.2 30.3 25.3 24.4 25.3 17 25.3 9.7 21.2 3.7 16.1 3.7 11 3.7 6.9 9.7 6.9 17 6.9 24.4 11 30.3 16.1 30.3Z"
      />
    </g>
  </w-icon>
</template>

<script>
import Icon from './Icon';

export default {
  components: {
    'w-icon': Icon,
  },
};
</script>

import { getVariations, sortByPriority } from '../../utils';
import { CartType } from '../../enums';

function getCartItemTotal({ item, product, cartType }) {
  const variation = product.product_variations.find(
    ({ resource_uri }) => resource_uri === item.product_variation
  );
  const modifiers = product.product_modifiers.filter(({ resource_uri }) =>
    item.product_modifiers.includes(resource_uri)
  );
  const modifierPriceMultiplicator = variation
    ? Number(variation.modifier_price_multiplicator)
    : 1.0;

  let basePrice;
  if (cartType === CartType.Delivery || cartType === CartType.Pickup) {
    basePrice = Number(
      variation ? variation.price_takeaway : product.price_takeaway
    );
  } else if (cartType === CartType.QrOrder) {
    basePrice = Number(variation ? variation.price_gross : product.price_gross);
  }

  const modifierPrices = modifiers.reduce(
    (sum, { price_gross }) =>
      sum + Number(price_gross) * modifierPriceMultiplicator,
    0.0
  );
  return (basePrice + modifierPrices) * item.quantity;
}

export default {
  product(state, _, rootState) {
    return rootState.productsByResourceUri[state.formValues.product];
  },
  category(_, getters, __, rootGetters) {
    return rootGetters.categories.find(
      ({ resource_uri }) => resource_uri === getters.product.menu_category
    );
  },
  selectedVariation(state, getters) {
    return getters.product.product_variations.find(
      ({ resource_uri }) => resource_uri === state.formValues.product_variation
    );
  },
  selectedModifiers(state, getters) {
    return getters.product.product_modifiers.filter(({ resource_uri }) =>
      state.formValues.product_modifiers.includes(resource_uri)
    );
  },
  modifierPriceMultiplicator(_, getters) {
    return getters.selectedVariation
      ? Number(getters.selectedVariation.modifier_price_multiplicator)
      : 1.0;
  },
  // 3 state of the root, 4 getters of the root
  total(state, getters, _, rootGetters) {
    return getCartItemTotal({
      product: getters.product,
      item: state.formValues,
      cartType: rootGetters.cartType,
    });
  },
  allergens(_, getters) {
    return getters.product.product_allergens;
  },
  variations(_, getters, rootState, rootGetters) {
    return getVariations(
      getters.product.product_variations,
      rootGetters.orderingEnabled,
      rootState.qrModeEnabled
    );
  },
  modifiers(_, getters) {
    return getters.product.product_modifiers;
  },
  modifierGroups(_, getters) {
    const filteredGroups = getters.product.product_modifier_groups.filter(
      ({ minimum_quantity, maximum_quantity }) =>
        !(
          (minimum_quantity === 0 && maximum_quantity === 0) ||
          minimum_quantity > maximum_quantity
        )
    );
    return sortByPriority(filteredGroups);
  },
  modifiersByGroupId(_, getters) {
    const modifiersByGroupId = {};
    getters.modifierGroups.forEach(({ id, resource_uri }) => {
      const modifiers = getters.modifiers.filter(
        ({ product_modifier_group }) => product_modifier_group === resource_uri
      );
      modifiersByGroupId[id] = sortByPriority(modifiers);
    });
    return modifiersByGroupId;
  },
  selectedModifiersByGroupId(state, getters) {
    const map = {};
    getters.modifierGroups.forEach(({ id }) => {
      map[id] = getters.modifiersByGroupId[id].filter(({ resource_uri }) =>
        state.formValues.product_modifiers.includes(resource_uri)
      );
    });
    return map;
  },
  formIsValid(state) {
    return !Object.values(state.formErrors).find((value) => !!value);
  },
  formIsInvalid(_, getters) {
    return !getters.formIsValid;
  },
};

import i18n from './i18n'

export class MissingMenuError extends Error {
  message = i18n.gettext('This restaurant has not yet set up a menu.')
}

export class MenuNotPublishedError extends Error {
  message = i18n.gettext('We are currently revising our menu for you!')
}

export class ProductFetchError extends Error {
  message = i18n.gettext('There\'s been a problem fetching this product. Please try again later.')
}

export class CartUpdateError extends Error {
  message = i18n.gettext('There\'s been a problem updating your cart. Please try again later.')
}

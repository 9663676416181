<template>
  <div :class="{ app: true, 'app--blocked': blocked, 'app--iframe': iframe }">
    <w-error v-if="error"></w-error>
    <router-view v-else></router-view>
    <w-open-new-tab-popover></w-open-new-tab-popover>
    <w-flash-message-popover></w-flash-message-popover>
  </div>
</template>

<script>
import NProgress from 'nprogress';
import { mapState } from 'vuex';

import Error from './error';
import { FlashMessagePopover, OpenNewTabPopover } from './popovers';

export default {
  computed: {
    ...mapState(['error', 'iframe', 'fetching']),
    ...mapState('checkoutForm', ['submitting', 'busyWithPayPal']),
    blocked() {
      return this.fetching || this.submitting || this.busyWithPayPal;
    },
  },
  watch: {
    fetching(fetching) {
      if (fetching) {
        NProgress.start();
      } else {
        NProgress.done();
      }
    },
  },
  mounted() {
    window.addEventListener('beforeunload', (e) => {
      if (this.blocked) {
        e.preventDefault();
        event.returnValue = '';
      }
    });
  },
  components: {
    'w-error': Error,
    'w-flash-message-popover': FlashMessagePopover,
    'w-open-new-tab-popover': OpenNewTabPopover,
  },
};
</script>

<style lang="scss">
.app {
  background: linear-gradient(
    var(--app-theme__gradientTopColor) 0vh,
    var(--app-theme__gradientBottomColor) 100vh
  );
  background-attachment: fixed;
  position: relative;

  .blockable {
    transition: opacity 0.3s;
  }

  &.app--blocked .blockable {
    opacity: 0.5;
    pointer-events: none;
  }

  &.app--iframe {
    background: var(--app-theme__backgroundColor);
  }
}
</style>

<template>
  <div
    :class="{
      'sidebar-header': true,
      'sidebar-header--cart-enabled': orderingEnabled,
    }"
  >
    <div
      :class="{
        'sidebar-header__back': true,
        'sidebar-header__back--hidden': hideBack,
      }"
      @click="goBack"
    >
      <w-arrow-icon class="sidebar-header__back-icon"></w-arrow-icon>
    </div>
    <div class="sidebar-header__content">
      <div class="sidebar-header__title">
        {{ title }}
      </div>
      <div class="sidebar-header__facility">
        {{ facilityName }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import i18n from '../i18n';

import { ArrowIcon } from './icons';

export default {
  data() {
    return {
      title: i18n.gettext('Menu'),
    };
  },
  computed: {
    ...mapState(['facility']),
    ...mapGetters(['orderingEnabled']),
    hideBack() {
      //return !this.facilityWebsite
      return true; // for now
    },
    facilityName() {
      return this.facility.name;
    },
    facilityWebsite() {
      return this.facility.website;
    },
  },
  methods: {
    goBack() {
      window.open(this.facilityWebsite, '_blank');
    },
  },
  components: {
    'w-arrow-icon': ArrowIcon,
  },
};
</script>

<style lang="scss">
@import 'bulma/sass/utilities/_all';

@import '../styles/_variables.scss';
@import '../styles/_mixins.scss';

.sidebar-header {
  background-color: var(--app-theme__backgroundColor);
  display: flex;
}

.sidebar-header__back {
  align-items: flex-end;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  padding: 2rem 0;
  width: $column-gap-sidebar-width-percentage-tablet;

  @include from($device: $desktop) {
    padding: 5rem 0 3rem;
    width: $column-gap-sidebar-width-percentage-desktop;
  }
}

.sidebar-header__back--hidden {
  visibility: hidden;
}

.sidebar-header__back-icon {
  margin-bottom: 0.1875rem;
  transform: rotate(90deg);
}

.sidebar-header__content {
  flex-grow: 1;
  padding: 2rem 0;

  @include from($device: $desktop) {
    padding: 5rem 0 3rem;
  }
}

.sidebar-header__title {
  text-transform: uppercase;

  @include from($device: $desktop) {
    font-size: $font-size-lg;
  }
}

.sidebar-header__facility {
  font-size: $font-size-lg;

  @include from($device: $desktop) {
    font-size: $font-size-xl;
  }
}

.sidebar-header--cart-enabled {
  .sidebar-header__back,
  .sidebar-header__content {
    @include from($device: $tablet) {
      padding-top: 5rem;
    }
    @include from($device: $desktop) {
      padding-bottom: 3rem;
      width: $column-gap-sidebar-width-percentage-desktop;
    }
  }
}

.app--iframe {
  .sidebar-header {
    @include sidebar-background-tablet();
  }
  .sidebar-header__back {
    visibility: hidden;
  }
  .sidebar-header__content {
    padding: 4.25rem 10% 2.25rem;
  }
  .sidebar-header__facility {
    display: none;
  }
  @include from($device: $desktop) {
    .sidebar-header {
      @include sidebar-background-desktop();
    }
  }
}
</style>

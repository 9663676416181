<template>
  <div class="quantity-input">
    <w-button
      class="quantity-input__decrement"
      hollow
      :disabled="!canDecrement"
      @click="decrement"
    >
      <w-decrement-icon class="quantity-input__decrement-icon"></w-decrement-icon>
    </w-button>
    <div class="quantity-input__quantity">{{value}}</div>
    <w-button class="quantity-input__increment" hollow @click="increment">
      <w-increment-icon class="quantity-input__increment-icon"></w-increment-icon>
    </w-button>
  </div>
</template>

<script>
import i18n from '../../i18n'

import Button from '../Button'
import { IncrementIcon, DecrementIcon } from '../icons'

export default {
  props: ['value'],
  computed: {
    canDecrement () {
      return this.value > 1
    }
  },
  methods: {
    increment () {
      this.$emit('increment')
    },
    decrement () {
      this.$emit('decrement')
    }
  },
  components: {
    'w-button': Button,
    'w-increment-icon': IncrementIcon,
    'w-decrement-icon': DecrementIcon
  }
}
</script>

<style>
.quantity-input {
  display: flex;
  width: 100%;
}
.quantity-input__decrement {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.quantity-input__increment {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.quantity-input__increment-icon,
.quantity-input__decrement-icon {
  display: inline-block;
}
.quantity-input__quantity {
  align-items: center;
  border-top: 2px solid var(--app-theme__lineColor);
  border-bottom: 2px solid var(--app-theme__lineColor);
  display: flex;
  justify-content: center;
  min-width: 2rem;
}
</style>

<template>
  <component :is="layoutComponent" :fixed-height="fixedHeight">
    <div class="page">
      <div v-if="renderHeader" class="page__header">
        <div class="page__title">
          <slot v-if="hasHeaderSlot" name="header"></slot>
          <template v-else-if="hasTitle">
            {{ title }}
          </template>
        </div>
        <div v-if="showClose" class="page__close" @click="handleClose">
          <w-close-icon></w-close-icon>
        </div>
      </div>
      <div class="page__body blockable">
        <slot></slot>
      </div>
      <div
        v-if="hasFooterSlot"
        :class="{
          page__footer: true,
          'page__footer--hide-on-mobile': mobileFooter,
          blockable: true,
        }"
      >
        <slot name="footer"></slot>
      </div>
      <w-mobile-action v-if="showMobileFooter">
        <slot name="footer"></slot>
      </w-mobile-action>
    </div>
  </component>
</template>

<script>
import { CloseIcon } from './icons';
import {
  CheckoutLayout,
  CheckoutSuccessLayout,
  DefaultLayout,
  ErrorLayout,
  FullWidthLayout,
} from './layouts';
import MobileAction from './MobileAction';

export default {
  props: {
    title: String,
    layout: String,
    fixedHeight: Boolean,
    mobileFooter: Boolean,
  },
  computed: {
    showClose() {
      return !!this.$listeners.close;
    },
    hasTitle() {
      return !!this.title;
    },
    hasHeaderSlot() {
      return !!this.$slots.header;
    },
    renderHeader() {
      return this.hasHeaderSlot || this.hasTitle || this.showClose;
    },
    hasFooterSlot() {
      return !!this.$slots.footer;
    },
    showMobileFooter() {
      return this.hasFooterSlot && this.mobileFooter;
    },
    layoutComponent() {
      return `w-${this.layout}-layout`;
    },
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
  },
  components: {
    'w-close-icon': CloseIcon,
    'w-mobile-action': MobileAction,
    'w-error-layout': ErrorLayout,
    'w-default-layout': DefaultLayout,
    'w-full-width-layout': FullWidthLayout,
    'w-checkout-layout': CheckoutLayout,
    'w-checkout-success-layout': CheckoutSuccessLayout,
  },
};
</script>

<style lang="scss">
@import 'bulma/sass/utilities/_all';

@import '../styles/_variables.scss';

.page {
  background-color: var(--app-theme__backgroundColor);
  border-radius: 0.5rem;
}

.page__header {
  display: flex;
  font-size: 1.5rem;
  padding: 1.5rem 2rem;

  @include from($device: $tablet) {
    font-size: 1.25rem;
  }

  @include from($device: $desktop) {
    font-size: 1.5rem;
  }
}

.page__body {
  padding: 1.5rem 2rem;
}

.page__footer {
  padding: 1.5rem 2rem;

  &.page__footer--hide-on-mobile {
    @include until($device: $tablet) {
      display: none;
    }
  }
}

.page__title {
  color: var(--app-theme__primaryColor);
  flex: 1;
}

.page__close {
  cursor: pointer;
}
</style>

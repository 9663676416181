import state from './state'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'

export default serviceContainer => ({
  state,
  getters,
  actions: actions(serviceContainer),
  mutations,
  namespaced: true
})

<template>
  <w-icon>
    <g
      id="symbol-room-seat-orders"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Group"
        transform="translate(3.000000, 4.864148)"
        stroke="currentColor"
        stroke-width="1"
      >
        <path
          d="M7.84793093,16 C14.5145976,15.0787736 18.1732226,11.7348635 18.1732226,5.98942323 C18.1732226,0.243982998 14.5145976,-3.07877356 7.84793093,-4"
          id="Oval"
          transform="translate(13.010577, 6.000000) rotate(-90.000000) translate(-13.010577, -6.000000) "
        ></path>
        <rect
          id="Rectangle"
          x="0.65"
          y="11.4873542"
          width="24.7"
          height="1.7"
          rx="0.85"
        ></rect>
      </g>
      <circle id="Oval" fill="currentColor" cx="16" cy="4.5" r="1.5"></circle>
      <polyline
        id="Rectangle"
        stroke="currentColor"
        stroke-width="1"
        transform="translate(9.187500, 25.500000) rotate(-45.000000) translate(-9.187500, -25.500000) "
        points="7 22 11.375 22 11.375 29 7 29"
      ></polyline>
      <path
        d="M9.61422811,22.7993139 C10.8097986,21.6358502 11.5887482,20.890952 11.9510768,20.5646193 C12.4945697,20.0751203 17.5359445,20.2558697 18.3247697,20.2558697 C19.1135948,20.2558697 19.7250758,21.6762414 18.3247697,22.2726223 C17.9883983,22.2726223 16.9977323,22.2726223 15.3527714,22.2726223 C18.5974862,22.2726223 20.3496723,22.2726223 20.6093296,22.2726223 C21.2826414,21.9269665 22.0762239,21.2743222 22.9900773,20.3146894 C23.5394833,19.8962184 24.0499589,19.8962184 24.5215042,20.3146894 C24.9930494,20.7331605 24.9930494,21.3005952 24.5215042,22.0169933 C23.0275967,23.6286154 21.8113105,24.5355814 20.8726455,24.7378914 C19.9339805,24.9402013 17.6206194,24.9402013 13.9325621,24.7378914 L12.4945697,25.9502898"
        id="Path-15"
        stroke="currentColor"
        stroke-width="1.3"
      ></path>
    </g>
  </w-icon>
</template>

<script>
import Icon from './Icon';

export default {
  components: {
    'w-icon': Icon,
  },
};
</script>

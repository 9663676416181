<template>
  <!--
    key is necessary because without vue won't call lifecycle
    methods on backdrop component for reasons of "optimization"
  -->
  <w-backdrop :key="1" v-if="isVisible" @close="toggle">
    <transition
      appear
      appear-class="cart-drawer--closed"
      leave-to-class="cart-drawer--closed"
      @after-appear="handleAfterAppear"
      @after-leave="handleAfterLeave"
    >
      <div v-if="cartDrawerIsOpen" class="cart-drawer">
        <div class="cart-drawer__header">
          <w-close-icon
            class="cart-drawer__close"
            @click="toggle"
          ></w-close-icon>
          <div>
            <span
              v-if="showOrderMethodPopoverToggle"
              class="cart-drawer__order-method-popover-toggle"
              @click="toggleOrderMethodPopover"
            >
              {{ orderMethodText }}
            </span>
          </div>
          <w-cart-button
            class="cart-drawer__cart-button"
            @click="toggle"
          ></w-cart-button>
        </div>
        <div class="cart-drawer__body">
          <w-cart-item-list></w-cart-item-list>
          <div class="cart-drawer__message-container" v-if="cartIsEmpty">
            <w-acceptance-hours-message
              class="cart-drawer__message"
            ></w-acceptance-hours-message>
            <w-delivery-address-message
              class="cart-drawer__message cart-drawer__delivery-address-message"
              role="button"
              tabIndex="0"
              @click="toggleOrderMethodPopover"
            ></w-delivery-address-message>
            <w-order-value-message
              class="cart-drawer__message"
            ></w-order-value-message>
            <w-forbidden-products-message
              class="cart-drawer__message"
            ></w-forbidden-products-message>
          </div>
        </div>
        <div v-if="cartIsFilled" class="cart-drawer__footer">
          <w-cart-total></w-cart-total>
          <div class="cart-drawer__message-container">
            <w-acceptance-hours-message
              class="cart-drawer__message"
            ></w-acceptance-hours-message>
            <w-delivery-address-message
              class="cart-drawer__message cart-drawer__delivery-address-message"
              role="button"
              tabIndex="0"
              @click="toggleOrderMethodPopover"
            ></w-delivery-address-message>
            <w-order-value-message
              class="cart-drawer__message"
            ></w-order-value-message>
            <w-forbidden-products-message
              class="cart-drawer__message"
            ></w-forbidden-products-message>
          </div>
          <w-button
            v-if="showCheckoutButton"
            class="cart-drawer__checkout-button blockable"
            primary
            :disabled="!checkoutEnabled"
            @click="routeToCheckout"
          >
            {{ checkoutButtonText }}
          </w-button>
        </div>
      </div>
    </transition>
  </w-backdrop>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import i18n from '../i18n';
import { Route, QrOrderType } from '../enums';

import { CloseIcon } from './icons';
import {
  ForbiddenProductsMessage,
  AcceptanceHoursMessage,
  DeliveryAddressMessage,
  OrderValueMessage,
} from './messages';

import Button from './Button';
import Backdrop from './Backdrop';
import CartButton from './CartButton';
import CartItemList from './CartItemList';
import CartTotal from './CartTotal';

export default {
  data() {
    return {
      isVisible: false,
      isToggling: false,
      checkoutButtonText: i18n.gettext('Checkout'),
    };
  },
  computed: {
    ...mapState(['cartDrawerIsOpen', 'showOrderMethodPopover', 'qrOrderType']),
    ...mapGetters([
      'cartItems',
      'cartTotal',
      'cartIsEmpty',
      'cartIsFilled',
      'getPriceTag',
      'checkoutEnabled',
      'hasSelectedPickup',
      'hasSelectedDelivery',
      'hasSelectedQrOrdering',
      'hasMultipleCategories',
    ]),
    totalPriceTag() {
      return this.getPriceTag(this.cartTotal);
    },
    isCheckoutRoute() {
      return this.$router.currentRoute.name === Route.Checkout;
    },
    showCheckoutButton() {
      return !this.isCheckoutRoute;
    },
    showOrderMethodPopoverToggle() {
      return !this.isCheckoutRoute;
    },
    orderMethodText() {
      if (this.hasSelectedPickup) {
        return i18n.gettext('Pickup');
      }
      if (this.hasSelectedDelivery) {
        return i18n.gettext('Delivery');
      }
      if (this.hasSelectedQrOrdering) {
        if (this.qrOrderType === QrOrderType.SeatOrder) {
          return i18n.gettext('Seat ordering');
        } else if (this.qrOrderType === QrOrderType.RoomOrder) {
          return i18n.gettext('Room ordering');
        } else {
          return i18n.gettext('Table ordering');
        }
      }
      return '';
    },
  },
  methods: {
    ...mapActions(['toggleCartDrawer', 'toggleOrderMethodPopover']),
    toggle() {
      if (!this.isToggling) {
        this.toggleCartDrawer();
      }
    },
    handleAfterAppear() {
      this.isToggling = false;
    },
    handleAfterLeave() {
      this.isVisible = false;
      this.isToggling = false;
    },
    routeToCheckout() {
      if (this.cartDrawerIsOpen) {
        this.toggleCartDrawer();
      }
      this.$router.push({ name: Route.Checkout });
    },
  },
  watch: {
    cartDrawerIsOpen(cartDrawerIsOpen) {
      if (cartDrawerIsOpen) {
        this.isVisible = true;
      }
      this.isToggling = true;
    },
    cartItems(items, prevItems) {
      if (
        items.length === 0 || // no items in cart
        items.length < prevItems.length || // item was removed
        this.cartDrawerIsOpen || // drawer is already open
        this.showOrderMethodPopover || // triggered by switching order types
        ![Route.ProductList, Route.ProductDetails].includes(this.$route.name) // not in product list or details
      ) {
        return; // no auto-toggling in these cases
      }
      this.toggleCartDrawer();
    },
  },
  components: {
    'w-button': Button,
    'w-backdrop': Backdrop,
    'w-close-icon': CloseIcon,
    'w-cart-button': CartButton,
    'w-cart-item-list': CartItemList,
    'w-cart-total': CartTotal,
    'w-forbidden-products-message': ForbiddenProductsMessage,
    'w-acceptance-hours-message': AcceptanceHoursMessage,
    'w-delivery-address-message': DeliveryAddressMessage,
    'w-order-value-message': OrderValueMessage,
  },
};
</script>

<style lang="scss">
@import 'bulma/sass/utilities/_all';

@import '../styles/_variables.scss';

.cart-drawer {
  background-color: var(--app-theme__backgroundColor);
  box-shadow: 0 0 5px 0 var(--app-theme__lineColor);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  right: 0;
  position: absolute;
  top: 0;
  transition: transform 0.3s ease-out;
  width: 100%;

  @include from($device: $tablet) {
    width: $drawer-width;
  }

  &.cart-drawer--closed {
    transform: translate(100%);
  }

  .cart-drawer__header {
    align-items: center;
    background-color: var(--app-theme__backgroundColor);
    color: var(--app-theme__primaryColor);
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.5rem;

    .cart-drawer__close {
      cursor: pointer;
    }

    .cart-drawer__order-method-popover-toggle {
      cursor: pointer;
      text-decoration: underline;
    }

    .cart-drawer__cart-button {
      margin-right: -0.5rem;
    }

    @include from($device: $tablet) {
      padding: 2rem;

      .cart-drawer__close {
        display: none;
      }

      .cart-button {
        margin: -1rem -1rem 0 0;
      }
    }
  }

  .cart-drawer__body {
    flex-grow: 1;
    min-height: 0; // needed for scroll to work
    overflow-y: auto;
    padding: 1rem 1.5rem;

    @include from($device: $tablet) {
      padding: 2rem;
    }
  }
}

.cart-drawer__footer {
  background-color: var(--app-theme__backgroundColor);
  padding: 0 1rem 1.5rem;

  @include from($device: $tablet) {
    padding: 0 2rem 2rem;
  }
}

.cart-drawer__message {
  margin-top: 1rem;
}

.cart-drawer__delivery-address-message {
  cursor: pointer;
  text-decoration: underline;
}

.cart-drawer__checkout-button,
.cart-drawer__message:first-child {
  margin-top: 1.5rem;

  @include from($device: $tablet) {
    margin-top: 2rem;
  }
}
</style>

<template>
  <w-icon>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square">
      <g transform="translate(1.000000, 7.000000)" stroke="currentColor" stroke-width="1.3">
        <line x1="13.5632551" y1="15.9082126" x2="16.7666287" y2="15.9082126"></line>
        <line x1="15.1636303" y1="4.8845268" x2="15.1636303" y2="15.6568231"></line>
        <line x1="8.30357143" y1="10.5178571" x2="10.5276693" y2="15.9082126"></line>
        <path d="M0.123825142,9.39486776 L8.21401037,9.39486776 C8.77097883,9.39486776 9.31702541,8.96677708 9.43670093,8.42483733 L11.2971416,2.51735141e-13" transform="translate(5.710483, 4.697434) scale(-1, 1) translate(-5.710483, -4.697434)"></path>
        <line x1="2.14324414" y1="9.07073668" x2="0" y2="15.798949"></line>
        <line x1="27.5" y1="9.5" x2="29.2252727" y2="15.9082126"></line>
        <path d="M18.8214286,9.39486776 L26.9116138,9.39486776 C27.4685823,9.39486776 28.0146288,8.96677708 28.1343044,8.42483733 L29.994745,1.70117888e-13"></path>
        <line x1="21.4654606" y1="10.5178571" x2="19.5279606" y2="16.0116154"></line>
        <line x1="7.75" y1="4.42857143" x2="22.1058896" y2="4.42857143"></line>
      </g>
    </g>
  </w-icon>
</template>

<script>
import Icon from './Icon'

export default {
  components: {
    'w-icon': Icon
  }
}
</script>

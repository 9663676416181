<template>
  <w-form-field v-bind="$attrs" v-on="$listeners">
    <template #default="{ inputProps, inputListeners }">
      <div ref="container" class="phone-form-field__container">
        <input
          ref="input"
          class="phone-form-field__input"
          type="tel"
          v-bind="inputProps"
          v-on="{
            ...inputListeners,
            input: handleInput,
            blur: handleBlur,
          }"
        />
      </div>
    </template>
  </w-form-field>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';

import i18n from '../../i18n';

import FormField from './FormField';

export default {
  computed: {
    ...mapState(['facility']),
  },
  methods: {
    ...mapActions('checkoutForm', ['setFormValue', 'setFormError']),
    handleBlur() {
      this.setFormError({
        name: this.$attrs.name,
        value: !this.iti.isValidNumber()
          ? i18n.gettext('Please enter a valid phone number.')
          : null,
      });
    },
    handleInput(e) {
      this.setFormValue({ name: this.$attrs.name, value: e.target.value });
      this.updateFormattedNumber();
    },
    updateFormattedNumber() {
      // set pseudo field which during serialization will replace unformatted value
      this.setFormValue({
        name: `${this.$attrs.name}_formatted`,
        value: this.iti.getNumber(),
      });
    },
  },
  mounted() {
    this.iti = intlTelInput(this.$refs.input, {
      dropdownContainer: this.$refs.container,
      autoPlaceholder: 'off',
      initialCountry: this.facility.country,
    });
    this.$refs.input.addEventListener(
      'countrychange',
      this.updateFormattedNumber
    );
  },
  beforeDestroy() {
    this.$refs.input.removeEventListener(
      'countrychange',
      this.updateFormattedNumber
    );
  },
  components: {
    'w-form-field': FormField,
  },
};
</script>

<style lang="scss">
body {
  .iti:not(.iti--container) {
    width: 100%;
  }
  &:not(.iti-mobile) {
    .iti--container {
      left: 0 !important;
      top: 100% !important;
      width: 100%;
    }
    .iti__country-list {
      width: 100%;
    }
  }
}
.phone-form-field__container {
  position: relative;
}
.phone-form-field__input {
  padding-left: 52px !important;
  width: 100%;
}
</style>

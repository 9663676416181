<template>
  <icon class="cart-icon">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path v-if="empty" d="M22.75,28.5 C23.7164983,28.5 24.5,27.7164983 24.5,26.75 C24.5,25.7835017 23.7164983,25 22.75,25 C21.7835017,25 21,25.7835017 21,26.75 C21,27.7164983 21.7835017,28.5 22.75,28.5 Z M13.75,28.5 C14.7164983,28.5 15.5,27.7164983 15.5,26.75 C15.5,25.7835017 14.7164983,25 13.75,25 C12.7835017,25 12,25.7835017 12,26.75 C12,27.7164983 12.7835017,28.5 13.75,28.5 Z M2,5 L5.34175904,5 C5.89907704,5 6.46086876,5.43162765 6.60005703,5.97780558 L10.7364416,22.209073 C10.8740624,22.7491001 11.4278244,23.1868786 11.9878808,23.1868786 L25.2387893,23.1868786 M8.07953007,11.1327818 C7.95235245,10.5931722 8.29687283,10.1557322 8.85417453,10.1557322 L28.275398,10.1557322 C28.8303999,10.1557322 29.1164538,10.5726883 28.9176365,11.0785837 L25.7129913,19.2328806 C25.5126879,19.7425574 24.8970737,20.1557322 24.3566559,20.1557322 L10.2060997,20.1557322 L8.07953007,11.1327818 Z" stroke="currentColor" stroke-width="1.3"></path>
      <g v-else transform="translate(2.000000, 5.000000)">
        <path d="M20.75,23.5 C21.7164983,23.5 22.5,22.7164983 22.5,21.75 C22.5,20.7835017 21.7164983,20 20.75,20 C19.7835017,20 19,20.7835017 19,21.75 C19,22.7164983 19.7835017,23.5 20.75,23.5 Z M11.75,23.5 C12.7164983,23.5 13.5,22.7164983 13.5,21.75 C13.5,20.7835017 12.7164983,20 11.75,20 C10.7835017,20 10,20.7835017 10,21.75 C10,22.7164983 10.7835017,23.5 11.75,23.5 Z M1.22409309e-13,-3.19744231e-14 L3.34175904,-3.19744231e-14 C3.89907704,-3.19744231e-14 4.46086876,0.431627653 4.60005703,0.977805584 L8.7364416,17.209073 C8.87406238,17.7491001 9.42782441,18.1868786 9.98788084,18.1868786 L23.2387893,18.1868786 M23.0174966,5.15573217 L26.275398,5.15573217 C26.8303999,5.15573217 27.1164538,5.57268826 26.9176365,6.07858373 L23.7129913,14.2328806 C23.5126879,14.7425574 22.8970737,15.1557322 22.3566559,15.1557322 L8.2060997,15.1557322 L5.71592756,5.15573217 L9.99816701,5.15573217" stroke="currentColor" stroke-width="1.3"></path>
        <path d="M16.4872379,6.07186974 C15.6549237,4.02395658 14.6919572,3 13.5983383,3 C11.95791,3 10.710676,4.45817431 11.0585695,6.07186974 C11.2904984,7.14766669 13.1000546,9.45704344 16.4872379,13 C19.8487496,9.39791853 21.6606331,7.08854178 21.9228884,6.07186974 C22.3162713,4.54686167 21.1649413,3 19.3716554,3 C18.1761316,3 17.2146591,4.02395658 16.4872379,6.07186974 Z" fill="currentColor"></path>
      </g>
    </g>
  </icon>
</template>

<script>
import Icon from './Icon'

export default {
  props: {
    empty: Boolean
  },
  components: {
    Icon
  }
}
</script>

<style>
.cart-icon {
  width: 2.5rem;
  height: 2.5rem;
}
</style>

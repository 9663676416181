<template>
  <w-icon v-bind="$attrs" v-on="$listeners">
    <g
      id="symbol-veggie"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <circle
        id="Oval-51"
        stroke="#00A7C4"
        stroke-width="1.3"
        fill="#FFFFFF"
        cx="16"
        cy="16"
        r="15"
      ></circle>
      <path
        d="M13.4392038,23.7869512 C13.4392038,23.7869512 20.9085059,22.213844 22.7582431,17.5194236 C24.6079804,12.8250033 19.8656046,5.43620441 19.8656046,5.43620441 C19.8656046,5.43620441 18.2783596,8.0310396 15.703254,9.53395053 C14.0327686,10.5088972 11.54646,11.2073954 10.8186558,13.0544788 C8.96891849,17.7488992 13.4392038,23.7869512 13.4392038,23.7869512 Z"
        id="Oval-51"
        stroke="#00A7C4"
        stroke-width="1.3"
        transform="translate(16.777277, 14.611578) rotate(16.000000) translate(-16.777277, -14.611578) "
      ></path>
      <path
        d="M9.48885047,11.6670872 C11.3750852,13.0609239 13.0486106,14.6546871 14.5094266,16.4483768 C15.9702426,18.2420666 17.6937773,21.378412 19.6800307,25.8574131"
        id="Line"
        stroke="#00A7C4"
        stroke-width="1.3"
        stroke-linecap="square"
        transform="translate(14.584441, 18.762250) scale(-1, 1) translate(-14.584441, -18.762250) "
      ></path>
    </g>
  </w-icon>
</template>

<script>
import Icon from './Icon';

export default {
  components: {
    'w-icon': Icon,
  },
};
</script>

<template>
  <w-page :layout="layout" fixed-height mobile-footer>
    <div class="product-details__container">
      <div
        class="product-details__image"
        v-bind:style="[image !== '' ? { width: '25%' } : { width: '0%' }]"
      >
        <img v-bind:src="image" />
        <div class="product-details__close-mobile" @click="handleClose">
          <w-close-icon></w-close-icon>
        </div>
      </div>
      <div
        class="product-details__content"
        v-bind:style="[
          image !== ''
            ? { width: '75%' }
            : { width: '100%', 'padding-left': 0 },
        ]"
      >
        <div class="product-details__header">
          <div class="product-details__title">
            <span class="product-details__title-text-spacing">{{ title }}</span>
            <span v-if="hasLabels" class="product-details__title-icon-inline">
              <span
                class="product-details__title-icon-spacing"
                v-if="hasAlcoholLabel"
                ><w-alcohol-icon></w-alcohol-icon
              ></span>
              <span
                class="product-details__title-icon-spacing"
                v-if="hasVeganLabel"
                ><w-vegan-icon></w-vegan-icon
              ></span>
              <span
                class="product-details__title-icon-spacing"
                v-if="hasVegetarianLabel"
                ><w-vegetarian-icon></w-vegetarian-icon
              ></span>
              <span
                class="product-details__title-icon-spacing"
                v-if="hasSpicyLabel"
                ><w-spicy-icon></w-spicy-icon
              ></span>
              <span
                class="product-details__title-icon-spacing"
                v-if="hasHotLabel"
                ><w-hot-icon></w-hot-icon
              ></span>
              <span
                class="product-details__title-icon-spacing"
                v-if="hasExtraHotLabel"
                ><w-extra-hot-icon></w-extra-hot-icon
              ></span>
            </span>
          </div>
          <div class="product-details__close" @click="handleClose">
            <w-close-icon></w-close-icon>
          </div>
        </div>
        <div class="product-details__description">{{ description }}</div>
        <div class="product-details__options">
          <div v-if="hasVariations" class="product-details__variation-groups">
            <w-product-variation-group
              :variations="variations"
              :get-price-tag="getVariationPriceTag"
              :ordering-enabled="orderingEnabled && orderable"
            ></w-product-variation-group>
          </div>
          <div v-else class="product-details__default-variation">
            {{ shouldShowProductPriceTag ? defaultVariationPriceTag : '' }}
          </div>
        </div>
      </div>
    </div>
    <div class="product-details__options">
      <div v-if="hasModifiers" class="product-details__modifier-groups">
        <w-product-modifier-group
          v-for="group in modifierGroups"
          :key="group.id"
          :group="group"
          :modifiers="modifiersByGroupId[group.id]"
          :get-price-tag="getModifierPriceTag"
          :ordering-enabled="orderingEnabled && orderable"
        ></w-product-modifier-group>
      </div>
      <div v-if="hasAllergens" class="product-details__allergens">
        <div class="product-details__allergens-title">
          {{ allergensTitle }}
        </div>
        <div class="product-details__allergens-list">
          {{ allergensList }}
        </div>
      </div>
    </div>
    <template v-slot:footer v-if="orderingEnabled">
      <w-product-details-footer v-if="orderable"></w-product-details-footer>
      <span v-else class="product-details__order-disabled-message">
        <w-disabled-icon
          class="product-details__disabled-icon"
        ></w-disabled-icon>
        {{ orderDisabledText }}
      </span>
    </template>
  </w-page>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import i18n from '../../i18n';
import { Route } from '../../enums';
import { handleProductDetailsRouteChange } from '../../utils';

import Page from '../Page';
import {
  CloseIcon,
  DisabledIcon,
  AlcoholIcon,
  VeganIcon,
  VegetarianIcon,
  SpicyIcon,
  HotIcon,
  ExtraHotIcon,
} from '../icons';

import ProductModifierGroup from './ProductModifierGroup';
import ProductVariationGroup from './ProductVariationGroup';
import ProductDetailsFooter from './ProductDetailsFooter';

export default {
  data() {
    return {
      allergensTitle: i18n.gettext('Allergens and additives'),
      orderDisabledText: i18n.gettext("This product can't be ordered online."),
    };
  },
  computed: {
    ...mapGetters([
      'getPriceTag',
      'orderingEnabled',
      'hasSingleCategory',
      'productIsOrderable',
      'getProductPrice',
      'getVariationPrice',
    ]),
    ...mapGetters('cartItemForm', [
      'product',
      'allergens',
      'variations',
      'modifiers',
      'modifierGroups',
      'modifiersByGroupId',
      'modifierPriceMultiplicator',
    ]),
    title() {
      return this.product.title;
    },
    image() {
      if (!this.product.image) {
        return '';
      }
      return (
        'https://' +
        window.resmioApp.imageResizeService +
        '/?&width=600&image=' +
        this.product.image
      );
    },
    description() {
      return this.product.description;
    },
    hasAllergens() {
      return this.allergens.length > 0;
    },
    allergensList() {
      const allAllergens = {
        ALMONDS: i18n.gettext('Almond and almond products'),
        ALPHA_ISOMETHYL_IONONE: i18n.gettext('Alpha-isomethyl ionone'),
        AMYLCINNAMYL_ALCOHOL: i18n.gettext('Amylcinnamyl alcohol'),
        AMYL_CINNAMAL: i18n.gettext('Amyl cinnamal'),
        ANISE_ALCOHOL: i18n.gettext('Anise Alcohol'),
        BARLEY: i18n.gettext('Barley and barley products'),
        BENZYL_ALCOHOL: i18n.gettext('Benzyl alcohol'),
        BENZYL_BENZOATE: i18n.gettext('Benzyl benzoate'),
        BENZYL_CINNAMATE: i18n.gettext('Benzyl cinnamate'),
        BENZYL_SALICYLATE: i18n.gettext('Benzyl salicylate'),
        BRAZIL_NUTS: i18n.gettext('Brazil nut and brazil nut products'),
        BUTYLPHENYL_METHYLPROPIONATE: i18n.gettext(
          'Butylphenyl methylpropionate'
        ),
        CARROTS: i18n.gettext('Carrot and derivatives'),
        CASHEW_NUTS: i18n.gettext('Cashew and cashew products'),
        CELERY: i18n.gettext('Celery or derivatives'),
        CEREALS_CONTAINING_GLUTEN: i18n.gettext(
          'Cereals containing gluten and their derivatives'
        ),
        CINNAMAL: i18n.gettext('Cinnamal'),
        CINNAMYL_ALCOHOL: i18n.gettext('Cinnamyl alcohol'),
        CITRAL: i18n.gettext('Citral'),
        CITRONELLOL: i18n.gettext('Citronellol'),
        COCOA: i18n.gettext('Cocoa and derivatives'),
        CORIANDER: i18n.gettext('Coriander derivatives'),
        CORN: i18n.gettext('Corn and derivatives'),
        COUMARIN: i18n.gettext('Coumarin'),
        CRUSTACEANS: i18n.gettext('Crustaceans and their derivatives'),
        EGGS: i18n.gettext('Eggs and their derivatives'),
        EUGENOL: i18n.gettext('Eugenol'),
        EVERNIA_FURFURACEA: i18n.gettext('Evernia furfuracea'),
        EVERNIA_PRUNASTRI: i18n.gettext('Evernia prunastri'),
        FARNESOL: i18n.gettext('Farnesol'),
        FISH: i18n.gettext('Fish and their derivatives'),
        GERANIOL: i18n.gettext('Geraniol'),
        GLUTEN: i18n.gettext('Gluten'),
        HAZELNUTS: i18n.gettext('Hazelnut and hazelnut products'),
        HEXYL_CINNAMAL: i18n.gettext('Hexyl cinnamal'),
        HYDROXYCITRONELLAL: i18n.gettext('Hydroxycitronellal'),
        'HYDROXYISOHEXYL_3-CYCLOHEXENE_CARBOXALDEHYDE_ISOEUGENOL_LIMONENE_LINAL':
          i18n.gettext(
            'Hydroxyisohexyl 3-cyclohexene carboxaldehyde isoeugenol limonene linal'
          ),
        KAMUT: i18n.gettext('Kamut and kamut products'),
        LACTOSE: i18n.gettext('Lactose'),
        LUPINE: i18n.gettext('Lupine and derivatives'),
        MACADAMIA_NUTS: i18n.gettext(
          'Macadamia nut and macadamia nut products'
        ),
        METHYL_2_OCTYNOATE: i18n.gettext('Methyl 2-octynoate'),
        MILK: i18n.gettext('Milk and derivatives'),
        MOLLUSCS: i18n.gettext('Molluscs and their derivatives'),
        MUSTARD: i18n.gettext('Mustard and derivatives'),
        NO_DECLARED_ALLERGENS: i18n.gettext(
          'No declaration obligatory allergens'
        ),
        OAT: i18n.gettext('Oat and oat products'),
        PEANUTS: i18n.gettext('Peanuts and their derivatives'),
        PEAS: i18n.gettext('Peas and pea products'),
        PECAN_NUTS: i18n.gettext('Pecan nut and pecan nut'),
        PISTACHIOS: i18n.gettext('Pistachio and pistachio products'),
        POD_FRUITS: i18n.gettext('Pod fruits derivatives'),
        QUEENSLAND_NUTS: i18n.gettext(
          'Queensland nut and queensland nut products'
        ),
        RYE: i18n.gettext('Rye and derivatives'),
        SESAME_SEEDS: i18n.gettext('Sesame seeds or their derivatives'),
        SOYBEANS: i18n.gettext('Soybeans and their derivatives'),
        SPELT: i18n.gettext('Spelt and spelt products'),
        SULPHUR_DIOXIDE: i18n.gettext('Sulphur dioxide and sulphites'),
        TREE_NUTS: i18n.gettext('Tree nuts and their derivatives'),
        TREE_NUT_TRACES: i18n.gettext('Traces of tree nuts'),
        WALNUTS: i18n.gettext('Walnut and walnut products'),
        WHEAT: i18n.gettext('Wheat and their derivatives'),

        WITH_FOOD_COLORING: i18n.gettext('With food coloring'),
        WITH_PRESERVATIVE: i18n.gettext('With preservative'),
        'WITH_ANTI-OXIDANT': i18n.gettext('With anti-oxidant'),
        WITH_FLAVOR_ENHANCER: i18n.gettext('With flavor enhancer'),
        BLACKENED: i18n.gettext('Blackened'),
        WITH_PHOSPHATE: i18n.gettext('With phosphate'),
        CONTAINS_CAFFEINE: i18n.gettext('Contains caffeine'),
        CONTAINS_QUININE: i18n.gettext('Contains quinine'),
        WITH_SWEETENER: i18n.gettext('With sweetener'),
        WAXED: i18n.gettext('Waxed'),
        POTASSIUM: i18n.gettext('Potassium'),
        SODIUM_NITRITE: i18n.gettext('Sodium nitrite'),
        TAURINE: i18n.gettext('Taurine'),
        CONTAINS_PHENYLALANINE: i18n.gettext('Contains phenylalanine'),
        BENZOIC_ACID: i18n.gettext('Benzoic acid'),
      };

      return this.allergens
        .map(({ title }) => {
          if (allAllergens.hasOwnProperty(title)) {
            return allAllergens[title];
          } else {
            return title;
          }
        })
        .join(', ');
    },
    hasVariations() {
      return this.variations.length > 0;
    },
    hasModifiers() {
      return this.modifiers.length > 0 && this.modifierGroups.length > 0;
    },
    defaultVariationPriceTag() {
      return this.getPriceTag(this.getProductPrice(this.product));
    },
    shouldShowProductPriceTag() {
      return this.getProductPrice(this.product) > 0;
    },
    layout() {
      return this.hasSingleCategory ? 'full-width' : 'default';
    },
    orderable() {
      return this.productIsOrderable(this.product);
    },
    hasLabels() {
      return (
        this.product.product_labels && this.product.product_labels.length > 0
      );
    },
    hasAlcoholLabel() {
      return (
        this.product.product_labels &&
        this.product.product_labels.find((label) => label.title === 'alcohol')
      );
    },
    hasVeganLabel() {
      return (
        this.product.product_labels &&
        this.product.product_labels.find((label) => label.title === 'vegan')
      );
    },
    hasVegetarianLabel() {
      return (
        this.product.product_labels &&
        this.product.product_labels.find(
          (label) => label.title === 'vegetarian'
        )
      );
    },
    hasSpicyLabel() {
      return (
        this.product.product_labels &&
        this.product.product_labels.find((label) => label.title === 'spicy')
      );
    },
    hasHotLabel() {
      return (
        this.product.product_labels &&
        this.product.product_labels.find((label) => label.title === 'hot')
      );
    },
    hasExtraHotLabel() {
      return (
        this.product.product_labels &&
        this.product.product_labels.find((label) => label.title === 'extrahot')
      );
    },
  },
  methods: {
    handleClose() {
      this.$router.back();
    },
    getVariationPriceTag(variation) {
      return this.getPriceTag(this.getVariationPrice(variation));
    },
    getModifierPriceTag(modifier) {
      return `+${this.getPriceTag(
        Number(modifier.price_gross) * this.modifierPriceMultiplicator
      )}`;
    },
  },
  beforeRouteUpdate(...args) {
    handleProductDetailsRouteChange(this.$store, ...args);
  },
  components: {
    'w-page': Page,
    'w-close-icon': CloseIcon,
    'w-disabled-icon': DisabledIcon,
    'w-product-modifier-group': ProductModifierGroup,
    'w-product-variation-group': ProductVariationGroup,
    'w-product-details-footer': ProductDetailsFooter,
    'w-alcohol-icon': AlcoholIcon,
    'w-vegan-icon': VeganIcon,
    'w-vegetarian-icon': VegetarianIcon,
    'w-spicy-icon': SpicyIcon,
    'w-hot-icon': HotIcon,
    'w-extra-hot-icon': ExtraHotIcon,
  },
};
</script>

<style lang="scss">
@import 'bulma/sass/utilities/_all';

@import '../../styles/_variables.scss';

.product-details__body {
  padding: 0 2rem;
  @media (max-width: $detail-desktop) {
    padding: 0;
  }
}

.product-details__header {
  padding-bottom: 3rem;
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
  @media (max-width: $detail-desktop) {
    padding-bottom: 0;
  }
}

.product-details__title {
  color: var(--app-theme__primaryColor);
  flex: 1;
}

.product-details__title-text-spacing {
  padding-right: 0.5rem;
}

.product-details__title-icon {
  display: flex;
}

.product-details__title-icon-inline {
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
}

.product-details__title-icon-spacing {
  display: inline-block;
}

.product-details__container {
  display: flex;
  width: 100%;
  margin-bottom: 2rem;

  @media (max-width: $detail-desktop) {
    flex-direction: column;
    margin-bottom: 0;
    margin-top: -1.5rem;
  }
}

.product-details__content {
  padding-left: 2rem;
  width: 75%;
  @media (max-width: $detail-desktop) {
    padding-left: 0;
    width: 100% !important;
  }
}

.product-details__image {
  height: auto;
  display: flex;
  > img {
    object-fit: cover;
  }

  @media (max-width: $detail-desktop) {
    flex-direction: column;
    width: auto !important;
    margin: 0 -2rem;
    max-height: 200px;
    margin-bottom: 2rem;
    position: relative;
    > img {
      object-fit: cover;
      max-height: 200px;
    }
  }
}

.product-details__description {
  margin-bottom: 2rem;
}

.product-details__variation-groups {
  margin-bottom: 2rem;
}

.product-details__default-variation {
  text-align: right;
  margin-bottom: 2rem;
}

.product-details__modifier-groups {
  margin-bottom: 2rem;
}

.product-details__modifier-groups > .product-modifier-group {
  margin-bottom: 2rem;
}

.product-details__modifier-groups > .product-modifier-group:last-child {
  margin-bottom: 0;
}

.product-details__allergens {
  margin-bottom: 2rem;
}

.product-details__allergens-title {
  color: var(--app-theme__lineColor);
}

.product-details__cart-item-form {
  display: none;
}

.product-details__order-disabled-message {
  align-items: center;
  color: var(--app-theme__lineColor);
  display: flex;
  justify-content: center;
}

.product-details__disabled-icon {
  margin-right: 0.5rem;
  margin-top: -0.125rem;
}
.product-details__close {
  cursor: pointer;
  @media (max-width: $detail-desktop) {
    display: none;
  }
}

.product-details__close-mobile {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
  color: white;
  mix-blend-mode: difference;
  @media (min-width: $detail-desktop) {
    display: none;
  }
}
</style>

<template>
  <div
    :class="{
      'default-layout': true,
      'default-layout--cart-empty': cartEmpty,
      'default-layout--cart-filled': cartFilled,
      'default-layout--fixed-height': fixedHeight,
    }"
  >
    <w-header cart-button></w-header>
    <w-category-sidebar></w-category-sidebar>
    <div class="default-layout__content">
      <slot></slot>
      <w-footer></w-footer>
    </div>
    <w-cart-drawer-toggle></w-cart-drawer-toggle>
    <w-cart-drawer></w-cart-drawer>
    <w-order-method-popover></w-order-method-popover>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Header from '../Header';
import Footer from '../Footer';
import CartDrawer from '../CartDrawer';
import CartDrawerToggle from '../CartDrawerToggle';
import CategorySidebar from '../CategorySidebar';
import { OrderMethodPopover } from '../popovers';

export default {
  props: {
    fixedHeight: Boolean,
  },
  computed: {
    ...mapGetters(['cartIsEmpty', 'cartIsFilled', 'orderingEnabled']),
    cartEmpty() {
      return this.orderingEnabled && this.cartIsEmpty;
    },
    cartFilled() {
      return this.orderingEnabled && this.cartIsFilled;
    },
  },
  components: {
    'w-header': Header,
    'w-footer': Footer,
    'w-cart-drawer': CartDrawer,
    'w-cart-drawer-toggle': CartDrawerToggle,
    'w-category-sidebar': CategorySidebar,
    'w-order-method-popover': OrderMethodPopover,
  },
};
</script>

<style lang="scss">
@import 'bulma/sass/utilities/_all';

@import '../../styles/_variables.scss';

.default-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &.default-layout--fixed-height {
    @include from($device: $tablet) {
      .page {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 100%;

        .page__body {
          flex: 1;
          min-height: 0; // needed vor scroll to work
          overflow-y: auto;
        }
      }
    }
  }
}

.default-layout__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 1rem;

  @include from($device: $tablet) {
    padding: 2rem $column-gap-tablet 0 ($sidebar-width + $column-gap-tablet);
  }

  @include from($device: $desktop) {
    padding: 5rem $column-gap-tablet 0 ($sidebar-width + $column-gap-desktop);
  }
}

.default-layout--cart-empty .default-layout__content {
  @include from($device: $tablet) {
    padding: 5rem calc(#{$column-gap-tablet} + 2.5rem) 0
      ($sidebar-width + $column-gap-tablet);
  }
  @include from($device: $desktop) {
    padding: 5rem calc(#{$column-gap-tablet} + 2rem) 0
      ($sidebar-width + $column-gap-tablet);
  }
  @include from($device: $widescreen) {
    padding: 5rem $column-gap-desktop 0 ($sidebar-width + $column-gap-desktop);
  }
}

.default-layout--cart-filled .default-layout__content {
  @include from($device: $tablet) {
    padding: 5rem calc(#{$column-gap-tablet} + 4rem) 0
      ($sidebar-width + $column-gap-tablet);
  }
  @include from($device: $desktop) {
    padding: 5rem calc(#{$column-gap-tablet} + 3.5rem) 0
      ($sidebar-width + $column-gap-tablet);
  }
  @include from($device: $widescreen) {
    padding: 5rem $column-gap-desktop 0 ($sidebar-width + $column-gap-desktop);
  }
}

.app--iframe .default-layout__content {
  padding: 0;

  @include from($device: $tablet) {
    padding: 2rem $column-gap-tablet 2rem ($sidebar-width + $column-gap-tablet);
  }

  @include from($device: $desktop) {
    padding: 2rem $column-gap-desktop 2rem
      ($sidebar-width + $column-gap-desktop);
  }
}
</style>

<template>
  <w-popover v-if="isShowing" :title="title" @close="close">
    {{message}}
    <template v-slot:footer>
      <w-button primary @click="close">{{buttonText}}</w-button>
    </template>
  </w-popover>
</template>

<script>
import { mapState } from 'vuex'

import i18n from '../../i18n'

import Button from '../Button'

import Popover from './Popover'

export default {
  data () {
    return {
      isShowing: false,
      buttonText: i18n.gettext('OK')
    }
  },
  computed: {
    ...mapState([
      'flashMessage'
    ]),
    title () {
      if (this.flashMessage instanceof Error) {
        return i18n.gettext('Oops!')
      }
      return ''
    },
    message () {
      if (this.flashMessage instanceof Error) {
        return this.flashMessage.message
      }
      return ''
    }
  },
  methods: {
    close () {
      this.isShowing = false
    }
  },
  watch: {
    flashMessage () {
      this.isShowing = true
    }
  },
  components: {
    'w-button': Button,
    'w-popover': Popover
  }
}
</script>

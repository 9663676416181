<template>
  <div
    :class="{
      'full-width-layout': true,
      'full-width-layout--fixed-height': fixedHeight,
    }"
  >
    <w-header cart-button show-always></w-header>
    <div class="full-width-layout__content">
      <slot></slot>
    </div>
    <w-footer></w-footer>
    <w-cart-drawer></w-cart-drawer>
    <w-category-sidebar></w-category-sidebar>
    <w-order-method-popover></w-order-method-popover>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Header from '../Header';
import Footer from '../Footer';
import CartDrawer from '../CartDrawer';
import CategorySidebar from '../CategorySidebar';
import { OrderMethodPopover } from '../popovers';

export default {
  props: {
    fixedHeight: Boolean,
  },
  components: {
    'w-header': Header,
    'w-footer': Footer,
    'w-cart-drawer': CartDrawer,
    'w-category-sidebar': CategorySidebar,
    'w-order-method-popover': OrderMethodPopover,
  },
};
</script>

<style lang="scss">
@import 'bulma/sass/utilities/_all';

@import '../../styles/_variables.scss';

.full-width-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @include from($device: $tablet) {
    &.full-width-layout--fixed-height .full-width-layout__content {
      height: 100%;

      .page {
        display: flex;
        flex-direction: column;
        flex: 1;

        .page__body {
          flex: 1;
          min-height: 0; // needed vor scroll to work
          overflow-y: auto;
        }
      }
    }
  }

  .full-width-layout__content {
    padding: 0 1rem;

    @include from($device: $desktop) {
      padding: 0 calc((#{$sidebar-width} + #{$column-gap-desktop} * 2) / 2) 0;
    }
  }
}
</style>

export default class PlacesService {
  location = null;

  constructor(googleMaps) {
    const { AutocompleteService, AutocompleteSessionToken } = googleMaps.places;
    this.googleMaps = googleMaps;
    this.autocompleteService = new AutocompleteService();
    this.autocompleteSessionToken = new AutocompleteSessionToken();
  }

  init(attrContainer) {
    this.placesService = new this.googleMaps.places.PlacesService(
      attrContainer
    );
  }

  setLocation(latitude, longitude) {
    const { LatLng } = this.googleMaps;
    this.location = new LatLng(latitude, longitude);
  }

  isInsideArea(place, area) {
    const { Polygon, LatLng, geometry } = this.googleMaps;
    const polygon = new Polygon({
      path: area.map(([lat, lng]) => new LatLng(lat, lng)),
    });
    const { lat, lng } = place.geometry.location;
    const point = new LatLng(lat, lng);
    return geometry.poly.containsLocation(point, polygon);
  }

  getPredictions(input) {
    return new Promise((resolve) =>
      this.autocompleteService.getPlacePredictions(
        {
          types: ['address'],
          rankby: 'distance',
          sessionToken: this.autocompleteSessionToken,
          ...(this.location && {
            radius: 50,
            location: this.location,
          }),
          input,
        },
        (predictions) => resolve(predictions || [])
      )
    );
  }

  getDetails(placeId) {
    return new Promise((resolve) =>
      this.placesService.getDetails(
        {
          fields: ['geometry', 'address_components', 'formatted_address'],
          placeId,
        },
        (details) => resolve(details || null)
      )
    );
  }
}

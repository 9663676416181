<template>
  <div class="checkout-layout">
    <w-header cart-button></w-header>
    <div class="checkout-layout__content">
      <slot></slot>
      <w-footer></w-footer>
    </div>
    <w-cart-sidebar></w-cart-sidebar>
    <w-cart-drawer></w-cart-drawer>
  </div>
</template>

<script>
import Header from '../Header';
import Footer from '../Footer';
import CartDrawer from '../CartDrawer';
import CartSidebar from '../CartSidebar';

export default {
  components: {
    'w-header': Header,
    'w-footer': Footer,
    'w-cart-drawer': CartDrawer,
    'w-cart-sidebar': CartSidebar,
  },
};
</script>

<style lang="scss">
@import 'bulma/sass/utilities/_all';

@import '../../styles/_variables.scss';

.checkout-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.checkout-layout__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 1rem;

  @include from($device: $tablet) {
    padding: 2rem calc(#{$drawer-width} + #{$column-gap-tablet}) 0
      $column-gap-tablet;
  }

  @include from($device: $widescreen) {
    padding: 5rem calc(#{$drawer-width} + #{$column-gap-desktop}) 0
      $column-gap-desktop;
  }
}
</style>

<template>
  <div
    :class="{ 'paypal-buttons': true, 'paypal-buttons--disabled': disabled }"
  >
    <div v-if="error" class="paypal-buttons__error">
      {{ error }}
    </div>
    <div ref="paypal"></div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

import i18n from '../../i18n';
import { Route } from '../../enums';

export default {
  props: {
    disabled: Boolean,
  },
  data() {
    return {
      error: null,
    };
  },
  computed: {
    ...mapGetters(['payPalPurchaseUnits', 'hasSelectedDelivery']),
  },
  methods: {
    ...mapActions('checkoutForm', [
      'setFormValue',
      'setFormError',
      'submitForm',
    ]),
    ...mapMutations('checkoutForm', ['setBusyWithPayPal']),
    setPayPalError(error) {
      this.error = error;
    },
    clearPayPalError() {
      this.error = null;
    },
  },
  mounted() {
    window.paypal
      .Buttons({
        style: {
          height: 45,
        },
        createOrder: (_, actions) => {
          this.clearPayPalError();
          this.setBusyWithPayPal(true); // block ui to prevent the user from making changes while paypal is doing its thing
          return actions.order.create({
            purchase_units: this.payPalPurchaseUnits,
            application_context: {
              shipping_preference: this.hasSelectedDelivery
                ? 'SET_PROVIDED_ADDRESS'
                : 'NO_SHIPPING',
            },
          });
        },
        onApprove: async (data) => {
          this.setBusyWithPayPal(false);
          if (!data.orderID) {
            this.setPayPalError(
              i18n.gettext(
                'There was a problem processing your PayPal payment.'
              )
            );
            return;
          }
          this.setFormValue({ name: 'token', value: data.orderID });
          try {
            await this.submitForm();
            this.$router.replace({ name: Route.CheckoutSuccess });
          } catch {}
        },
        onCancel: () => {
          this.setBusyWithPayPal(false);
        },
        onError: () => {
          this.setBusyWithPayPal(false);
          this.setPayPalError(
            i18n.gettext(
              'There was a problem initializing your PayPal payment.'
            )
          );
        },
      })
      .render(this.$refs.paypal);
  },
  beforeDestroy() {
    this.clearPayPalError();
  },
};
</script>

<style>
.paypal-buttons {
  text-align: center;
}
.paypal-buttons--disabled {
  opacity: 0.5;
  pointer-events: none;
}
.paypal-buttons__error {
  color: var(--app-theme__errorColor);
  margin-bottom: 1rem;
}
</style>

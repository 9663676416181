<template>
  <div class="category-list-item" :data-category-id="category.id">
    <div
      :class="{
        'category-list-item__header': true,
        'category-list-item__header--active': expanded,
      }"
      @click="toggle"
    >
      <div class="category-list-item__header-title">
        <div class="category-list-item__name">
          <div class="category-list-item__name-wrapper">
            {{ category.name }}
          </div>
        </div>
        <w-arrow-icon class="category-list-item__arrow"></w-arrow-icon>
      </div>
      <div v-if="category.description" class="category-list-item__description">
        {{ category.description }}
      </div>
    </div>
    <w-product-list
      v-show="expanded"
      :products="products"
      :imagepresent="imagepresent"
      :categoryElement="category"
    ></w-product-list>
  </div>
</template>

<script>
import { ArrowIcon } from '../icons';

import ProductList from './ProductList';

export default {
  props: ['category', 'products', 'expanded', 'imagepresent'],
  methods: {
    toggle() {
      this.$emit('toggle', this.category.id);
    },
  },
  components: {
    'w-arrow-icon': ArrowIcon,
    'w-product-list': ProductList,
  },
};
</script>

<style lang="scss">
@import 'bulma/sass/utilities/_all';

@import '../../styles/_variables.scss';

.category-list-item {
  &:last-child .category-list-item__header {
    border-bottom-style: none;
  }

  @include from($device: $tablet) {
    &:not(:last-child) {
      padding-bottom: 3rem;
    }
  }
}

.category-list-item__header {
  align-items: center;
  border-bottom: 2px solid var(--app-theme__lineColor);
  cursor: pointer;
  padding: $font-size-lg 0;

  @include until($device: $tablet) {
    // https://dev.to/mario/lots-of-fun-with-hover-css-selectors-on-mobile-devices-3kh6
    @media (-moz-touch-enabled: 0), (pointer: fine) {
      &:hover {
        color: var(--app-theme__primaryColor);
      }
    }
  }

  @include from($device: $tablet) {
    border-bottom-style: none;
    color: var(--app-theme__primaryColor);
    padding: 1rem 0;
    pointer-events: none;

    .category-list-item__description {
      display: block !important;
      padding: 2rem 1rem 0;
    }

    .category-list-item__arrow {
      display: none;
    }
  }
}

.category-list-item__header--active {
  color: var(--app-theme__primaryColor);

  .category-list-item__arrow {
    transform: rotate(180deg);
  }

  .category-list-item__description {
    display: block;
  }
}

.category-list-item__header-title {
  display: flex;
  font-size: $font-size-lg;

  @include from($device: $desktop) {
    font-size: $font-size-xl;
  }
}

.category-list-item__arrow {
  flex-shrink: 0;
}

.category-list-item__name {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.category-list-item__description {
  display: none;
  padding: 0.75rem 1rem 0;
  text-align: center;
}

.app--iframe {
  @include from($device: $tablet) {
    .category-list-item__name {
      border-bottom: 2px solid var(--app-theme__primaryColor);
      transform: translate(0, -50%);
    }
    .category-list-item__name-wrapper {
      background-color: var(--app-theme__backgroundColor);
      display: inline-block;
      padding: 0 1.5rem;
      transform: translate(0, 50%);
    }
  }
}
</style>

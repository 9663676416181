<template>
  <div class="cart-total">
    <div class="cart-total__subtotal">
      <div>{{ subtotalText }}</div>
      <div>{{ subtotalPriceTag }}</div>
    </div>
    <div v-if="hasSelectedDelivery" class="cart-total__delivery-fee">
      <div>{{ deliveryFeeText }}</div>
      <div>{{ deliveryFeeTag }}</div>
    </div>
    <div v-if="hasTip" class="cart-total__tip">
      <div>{{ tipText }}</div>
      <div>{{ tipTag }}</div>
    </div>
    <div v-if="isVoucherAvailable" class="cart-total__voucher">
      <div>{{ voucherText }}</div>
      <div>{{ voucherAmountTag }}</div>
    </div>
    <div class="cart-total__total">
      <div>{{ totalText }}</div>
      <div>{{ totalPriceTag }}</div>
    </div>
    <div v-if="shoudShowRemaining" class="cart-total__remaining-voucher">
      <div>{{ voucherCoverText }}</div>
      <div>
        {{ reaminingVoucherText }}
        <span v-bind:style="{ fontWeight: 600 }">{{
          remainingVoucherAmountTag
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import i18n from '../i18n';

export default {
  data() {
    return {
      totalText: i18n.gettext('Total'),
      subtotalText: i18n.gettext('Subtotal'),
      voucherText: i18n.gettext('Voucher'),
      deliveryFeeText: i18n.gettext('Delivery fee'),
      tipText: i18n.gettext('Tips'),
      voucherCoverText: i18n.gettext(
        'Your voucher covers the total order amount.'
      ),
      reaminingVoucherText: i18n.gettext('Your remaining voucher is'),
    };
  },
  computed: {
    ...mapGetters([
      'getPriceTag',
      'cartTotal',
      'cartSubtotal',
      'deliveryFee',
      'tipAmount',
      'hasSelectedDelivery',
      'hasDeliveryAreas',
      'hasValidAddressInsideDeliveryArea',
      'voucherAmount',
      'isVoucherAvailable',
    ]),
    ...mapGetters('checkoutForm', ['hasTip']),
    totalPriceTag() {
      if (this.hasDeliveryAreas && !this.hasValidAddressInsideDeliveryArea) {
        return this.getPriceTag(
          Math.max(this.cartSubtotal - this.voucherAmount, 0)
        );
      }
      return this.getPriceTag(Math.max(this.cartTotal - this.voucherAmount, 0));
    },
    subtotalPriceTag() {
      return this.getPriceTag(this.cartSubtotal);
    },
    deliveryFeeTag() {
      if (this.hasDeliveryAreas && !this.hasValidAddressInsideDeliveryArea) {
        return i18n.gettext('Depends on your location');
      }
      return this.getPriceTag(this.deliveryFee);
    },
    tipTag() {
      return this.getPriceTag(this.tipAmount);
    },
    voucherAmountTag() {
      if (this.voucherAmount > this.cartTotal) {
        return `- ${this.getPriceTag(this.cartTotal)}`;
      } else {
        return `- ${this.getPriceTag(this.voucherAmount)}`;
      }
    },
    shoudShowRemaining() {
      return this.isVoucherAvailable && this.voucherAmount >= this.cartTotal;
    },
    remainingVoucherAmountTag() {
      return this.getPriceTag(this.voucherAmount - this.cartTotal);
    },
  },
};
</script>

<style lang="scss">
@import 'bulma/sass/utilities/_all';

.cart-total {
  border-top: 2px solid var(--app-theme__lineColor);
  padding-top: 1.5rem;

  @include from($device: $tablet) {
    padding-top: 2rem;
  }
}

.cart-total__total,
.cart-total__subtotal,
.cart-total__voucher,
.cart-total__tip,
.cart-total__delivery-fee {
  display: flex;
  justify-content: space-between;
  padding-right: 3rem;

  @include from($device: $tablet) {
    padding-right: 2.5rem;
  }
}

.cart-total__tip,
.cart-total__voucher {
  color: var(--app-theme__primaryColor);
  margin-bottom: 0.5rem;
}

.cart-total__subtotal,
.cart-total__delivery-fee {
  color: var(--app-theme__lineColor);
  margin-bottom: 0.5rem;
}

.cart-total__total {
  font-weight: 600;
}
.cart-total__remaining-voucher {
  margin-top: 3rem;
  color: var(--app-theme__primaryColor);
}
</style>

<template>
  <label class="radio-input">
    <span class="radio-input__input-wrapper">
      <input class="radio-input__input" type="radio" v-bind="$attrs" v-on="$listeners" />
      <span class="radio-input__checkmark"></span>
    </span>
    <span class="radio-input__label">
      <slot></slot>
    </span>
  </label>
</template>

<script>
export default {
}
</script>

<style lang="scss">
// adapted from https://codesandbox.io/s/serene-cdn-1fw1i

.radio-input {
  align-items: flex-start;
  display: flex;
  font-weight: normal;
  width: 100%;
}

.radio-input__input-wrapper {
  display: block;
  flex-shrink: 0;
  height: 1.25rem;
  margin: 0.125rem 0;
  position: relative;
  width: 1.25rem;

  &::before {
    border: 2px solid var(--app-theme__lineColor);
    border-radius: 100%;
    box-sizing: border-box;
    content: "";
    height: 100%;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.radio-input__input {
  height: 100%;
  margin: 0;
  opacity: 0;
  position: absolute;
  width: 100%;
  z-index: 2;

  &:focus {
    outline: none;
  }

  &:checked {
    & ~ .radio-input__checkmark {
      height: calc(100% - 1.25rem * 0.5);
      transition: width 0.2s ease-out, height 0.2s ease-out;
      width: calc(100% - 1.25rem * 0.5);

      &::before {
        opacity: 1;
        transition: opacity 1s ease;
      }
    }
  }
}

.radio-input__checkmark {
  background: var(--app-theme__primaryColor);
  border-radius: 100%;
  display: block;
  height: 0;
  left: 50%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.2s ease-in, height 0.2s ease-in;
  width: 0;
  z-index: 1;

  &::before {
    border-radius: 100%;
    content: "";
    height: calc(1.25rem - 1.25rem * 0.25);
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(1.25rem - 1.25rem * 0.25);
  }
}

.radio-input__label {
  flex-grow: 1;
  margin-left: 0.5rem;
}
</style>

import { PaymentType } from '../../enums';
import { processFormField, validateFormField } from './utils';

function processFormValues(formValues) {
  const values = {};
  Object.entries(formValues).forEach(([name, value]) => {
    values[name] = processFormField(name, value);
  });
  return values;
}

function validateFormValues(formValues, context) {
  const errors = {};
  Object.entries(formValues).forEach(([name, value]) => {
    errors[name] = validateFormField(name, value, context);
  });
  return errors;
}

function serializeFormValues({
  stripe,
  paypal,
  phone,
  phone_formatted,
  ...formValues
}) {
  // remove pseudo fields, replace phone
  return { ...formValues, phone: phone_formatted || phone };
}

export default {
  processedFormValues(state) {
    return processFormValues(state.formValues);
  },
  serializedFormValues(_, getters) {
    return serializeFormValues(getters.processedFormValues);
  },
  validationContext(_, getters, __, rootGetters) {
    return {
      isQrOrder: rootGetters.hasSelectedQrOrdering,
      formValues: getters.processedFormValues,
      requiredFields: getters.requiredFields,
    };
  },
  validationErrors(_, getters) {
    return validateFormValues(
      getters.processedFormValues,
      getters.validationContext
    );
  },
  hasTip(state, getters) {
    return state.tipSelection !== '';
  },
  tip(state, getters) {
    return state.tip;
  },
  tipSelection(state, getters) {
    return state.tipSelection;
  },
  formIsValid(state, getters) {
    const errors = {
      ...state.formErrors, // might contain errors that were set outside of validation method
      ...getters.validationErrors,
    };
    return !Object.values(errors).find((value) => !!value);
  },
  formIsInvalid(_, getters) {
    return !getters.formIsValid;
  },
  hasFormErrors(state) {
    return !!Object.values(state.formErrors).find((value) => !!value);
  },
  payCash(state) {
    return state.formValues.payment_type === PaymentType.Cash;
  },
  payWithPayPal(state) {
    return state.formValues.payment_type === PaymentType.PayPal;
  },
  payWithBambora(state) {
    return state.formValues.payment_type === PaymentType.Bambora;
  },
  payWithCreditCard(state) {
    return state.formValues.payment_type === PaymentType.CreditCard;
  },
  payWithDirectDebit(state) {
    return state.formValues.payment_type === PaymentType.DirectDebit;
  },
  requiredFields(_, getters, ___, rootGetters) {
    const fields = [];
    if (rootGetters.hasSelectedQrOrdering) {
      if (rootGetters.menu.qr_name_required) {
        fields.push('name');
      }
      if (rootGetters.menu.qr_email_required) {
        fields.push('email');
      }
      if (rootGetters.menu.qr_phone_required) {
        fields.push('phone');
      }
      if (rootGetters.menu.qr_address_required) {
        fields.push('address1');
      }
    } else {
      fields.push('name', 'email', 'phone', 'fulfillment_time');
      if (rootGetters.hasSelectedDelivery) {
        fields.push('address1', 'zip_code', 'city');
      }
      if (rootGetters['availabilities/canOrderInAdvance']) {
        fields.push('fulfillment_day');
      }
    }
    if (getters.payWithCreditCard) {
      fields.push('owner_name');
    } else if (getters.payWithDirectDebit) {
      fields.push('owner_name', 'owner_email');
    }
    return fields;
  },
  hasFilledInRequiredFields(_, getters) {
    const { requiredFields, processedFormValues } = getters;
    for (const field of requiredFields) {
      if (!processedFormValues[field]) {
        return false;
      }
    }
    return true;
  },
};

<template>
  <w-icon>
    <path d="M23.657186,18.4871967 L26.1026995,18.4871967 C26.6523309,18.4871967 27.078672,18.0406768 27.0551294,17.4938237 L26.9553582,15.1763171" stroke="currentColor" stroke-width="1.3" stroke-linecap="square" transform="translate(25.377541, 16.831757) rotate(-240.000000) translate(-25.377541, -16.831757) "></path>
    <path d="M22.2517231,6.2468987 L22.2517231,10.7169191 C22.2517231,11.2714264 22.6990628,11.7209433 23.2423938,11.7209433 L26.0222847,11.7209433" stroke="currentColor" stroke-width="1.3"></path>
    <path d="M24.8912366,3.20546565 C24.3226987,3.06223514 23.4274229,3 22.8144197,3 C18.8103553,3 15.5644197,6.24593556 15.5644197,10.25 C15.5644197,14.2540644 18.8103553,17.5 22.8144197,17.5 C25.9138944,17.5 28.5590959,15.5550253 29.5960988,12.8190012 C29.7239389,12.4817085 29.8273382,12.1323937 29.904135,11.7732189" stroke="currentColor" stroke-width="1.3" transform="translate(22.734277, 10.250000) rotate(-208.000000) translate(-22.734277, -10.250000) "></path>
    <path d="M7.84793093,31.3467336 C14.5145976,30.4255071 18.1732226,27.081597 18.1732226,21.3361568 C18.1732226,15.5907166 14.5145976,12.26796 7.84793093,11.3467336" stroke="currentColor" stroke-width="1.3" transform="translate(13.010577, 21.346734) rotate(-90.000000) translate(-13.010577, -21.346734) "></path>
    <rect stroke="currentColor" stroke-width="1.3" x="0.65" y="26.8340877" width="24.7" height="1.7" rx="0.85"></rect>
    <circle fill="currentColor" cx="13" cy="14.9825857" r="1.5"></circle>
    <line x1="0.993951613" y1="31.1959669" x2="31.4979839" y2="4.31685358" stroke="currentColor" stroke-width="1.3" transform="translate(16.252016, 17.439557) rotate(-1.614597) translate(-16.252016, -17.439557) "></line>
  </w-icon>
</template>

<script>
import Icon from './Icon'

export default {
  components: {
    'w-icon': Icon
  }
}
</script>

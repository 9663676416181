<template>
  <w-message v-if="hasForbiddenProductsInCart" error>
    <div>{{messageText}}</div>
  </w-message>
</template>

<script>
import { mapGetters } from 'vuex'

import i18n from '../../i18n'

import Message from './Message'

export default {
  data () {
    return {
      messageText: i18n.gettext('Some products are not orderable at the moment. Please remove them from your cart.')
    }
  },
  computed: {
    ...mapGetters([
      'hasForbiddenProductsInCart'
    ]),
  },
  components: {
    'w-message': Message
  }
}
</script>

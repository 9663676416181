export default {
  setFormValues(state, values) {
    state.formValues = values;
  },
  setFormErrors(state, errors) {
    state.formErrors = errors;
  },
  setServerError(state, error) {
    state.serverError = error;
  },
  setSubmitting(state, submitting) {
    state.submitting = submitting;
  },
  setTip(state, value) {
    state.tip = value;
  },
  setTipSelection(state, value) {
    state.tipSelection = value;
  },
  setBusyWithPayPal(state, busyWithPayPal) {
    state.busyWithPayPal = busyWithPayPal;
  },
};

<template>
  <div class="form-group">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import 'bulma/sass/utilities/_all';

.form-group {
  padding-bottom: 2rem;
}

@include from($device: $widescreen) {
  .form-group {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5rem;

    .form-field {
      flex: 0 50%;
      padding: 0 0.5rem 1rem;
    }

    .form-field--grow {
      flex-grow: 1;
    }

    .form-field:only-child {
      flex-basis: 100%;
    }
  }
}
</style>

<template>
  <div class="gmp-autocomplete">
    <div ref="hiddenMap" class="gmp-autocomplete__hidden-map"></div>
    <autocomplete
      ref="autocomplete"
      :search="search"
      :placeholder="placeholder"
      :debounce-time="500"
      :default-value="defaultValue"
      :get-result-value="getResultValue"
      auto-select
      @submit="handleSubmit"
      @update="handleUpdate"
    >
      <template
        #default="{
          rootProps,
          inputProps,
          inputListeners,
          resultListProps,
          resultListListeners,
          results,
          resultProps,
        }"
      >
        <div
          v-bind="{
            ...rootProps,
            style: {
              ...rootProps.style,
              position: 'static',
            },
          }"
        >
          <div ref="inputWrapper" class="gmp-autocomplete__input-wrapper">
            <input
              ref="input"
              v-bind="inputProps"
              v-on="{
                ...inputListeners,
                blur: handleBlur,
                focus: (e) => handleFocus(e, inputListeners.focus),
              }"
              @keydown.tab="handleTab"
              :class="{
                'gmp-autocomplete__input': true,
                'gmp-autocomplete__input--error': error,
                'gmp-autocomplete__input--success': success,
              }"
            />
            <div class="gmp-autocomplete__icon-container">
              <w-check-icon
                v-if="hasValidAddressInsideDeliveryArea"
                class="gmp-autocomplete__check-icon"
              ></w-check-icon>
              <w-location-icon
                v-else
                class="gmp-autocomplete__location-icon"
              ></w-location-icon>
            </div>
            <button
              class="gmp-autocomplete__button"
              v-show="hasValidAddressInsideDeliveryArea"
              :disabled="!hasSelectedDelivery"
              @click="toggleOrderMethodPopover"
            >
              <w-arrow-icon
                class="gmp-autocomplete__button-arrow-icon"
              ></w-arrow-icon>
            </button>
          </div>
          <div
            ref="resultListWrapper"
            class="gmp-autocomplete__result-list-wrapper"
            v-bind="resultListProps"
            v-on="resultListListeners"
          >
            <ul class="gmp-autocomplete__result-list">
              <li
                v-for="(result, index) in results"
                v-bind="resultProps[index]"
                :key="resultProps[index].id"
                class="gmp-autocomplete__result"
              >
                {{ getResultValue(result) }}
              </li>
            </ul>
            <div class="gmp-autocomplete__google-logo-container">
              <img :src="poweredByGoogleImageSource" height="18px" />
            </div>
          </div>
        </div>
      </template>
    </autocomplete>
  </div>
</template>

<script>
import Autocomplete from '@trevoreyre/autocomplete-vue';
import { mapState, mapGetters, mapActions } from 'vuex';

import * as Sentry from '@sentry/browser';

import i18n from '../i18n';
import { isLightColor } from '../utils';
import { placesService, storageService } from '../services';

import { CheckIcon, LocationIcon, ArrowIcon } from './icons';
import { StorageKey } from '../enums';

export default {
  data() {
    return {
      placeholder: i18n.gettext('Your location'),
      lastSelectedAddress: '',
    };
  },
  computed: {
    ...mapState(['theme', 'facility', 'staticUrl', 'googleMapsPlace']),
    ...mapGetters([
      'hasAddress',
      'hasAddressError',
      'hasSelectedDelivery',
      'hasValidAddressInsideDeliveryArea',
    ]),
    error() {
      return this.hasAddressError;
    },
    success() {
      return this.hasSelectedDelivery && this.hasValidAddressInsideDeliveryArea;
    },
    defaultValue() {
      return this.googleMapsPlace ? this.googleMapsPlace.formatted_address : '';
    },
    poweredByGoogleImageSource() {
      return `${this.staticUrl}images/${
        isLightColor(this.theme.backgroundColor)
          ? 'powered_by_google_on_white'
          : 'powered_by_google_on_non_white'
      }.png`;
    },
  },
  methods: {
    ...mapActions(['selectGoogleMapsPlace', 'toggleOrderMethodPopover']),
    async search(input) {
      input = input.trim();
      if (input.length === 0) {
        // show previously selected places from local storage
        const prevSelectedPlaces = storageService.get(StorageKey.Places) || [];
        return prevSelectedPlaces.length > 0
          ? Promise.resolve(prevSelectedPlaces)
          : [];
      }
      if (input.length < 4 || input === this.lastSelectedAddress) {
        return [];
      }
      return placesService.getPredictions(input);
    },
    getResultValue(result) {
      // result can be a prediction (from places service, having a description)
      // or a place (coming from local storage, having a formatted address)
      return result.description || result.formatted_address;
    },
    handleTab() {
      const results = this.$refs.autocomplete.results;
      const index = this.$refs.autocomplete.selectedIndex;
      if (results[index]) {
        this.handleSubmit(results[index]);
      }
    },
    handleBlur() {
      this.$nextTick(() => {
        // wait for input value being up-to-date
        const { value } = this.$refs.input;
        if (value !== this.lastSelectedAddress) {
          this.lastSelectedAddress = '';
          this.selectGoogleMapsPlace(null);
        }
      });
    },
    handleFocus(e, originalHandler) {
      this.computeResultListPosition();
      originalHandler(e);
    },
    async handleSubmit(result) {
      if (!result) {
        return;
      }
      if (result.place_id) {
        // suggests that this is a prediction (from places service)
        const place = await placesService.getDetails(result.place_id);
        this.lastSelectedAddress = place ? this.getResultValue(result) : '';
        this.selectGoogleMapsPlace(place);
      } else {
        // suggests that this is a place (coming from local storage)
        this.lastSelectedAddress = this.getResultValue(result);
        this.selectGoogleMapsPlace(result);
      }
      this.$refs.input.blur();
    },
    handleUpdate() {
      this.computeResultListPosition();
    },
    computeResultListPosition() {
      const { inputWrapper, resultListWrapper } = this.$refs;
      const { bottom } = inputWrapper.getBoundingClientRect();
      const { offsetTop, offsetLeft, offsetWidth, offsetHeight } = inputWrapper;
      // position absolutely to popover backdrop so that result
      // list can pop out of popover scrollbox on desktop
      resultListWrapper.style.position = 'absolute';
      resultListWrapper.style.top = `${
        offsetTop + offsetHeight - this.popover.scrollTop
      }px`;
      resultListWrapper.style.left = `${offsetLeft}px`;
      resultListWrapper.style.width = `${offsetWidth}px`;
      const backdropHeight = this.popover.parentElement.clientHeight;
      resultListWrapper.style.maxHeight = `calc(${
        backdropHeight > this.popover.clientHeight
          ? backdropHeight - bottom
          : this.popover.scrollHeight - bottom
      }px - 1.5rem)`;
    },
    async setLocation() {
      try {
        const location = await new Promise((resolve, reject) =>
          window.navigator.geolocation.getCurrentPosition(resolve, reject)
        );
        const { latitude, longitude } = location.coords;
        placesService.setLocation(latitude, longitude);
      } catch {
        if (this.facility.location) {
          const [lat, lng] = this.facility.location;
          placesService.setLocation(lat, lng);
        }
      }
    },
  },
  mounted() {
    placesService.init(this.$refs.hiddenMap);

    this.lastSelectedAddress = this.defaultValue;

    this.setLocation();

    this.popover = this.$el.closest('.popover');
    this.popover.addEventListener('scroll', this.computeResultListPosition);
    window.addEventListener('resize', this.computeResultListPosition);

    if (this.hasSelectedDelivery && !this.hasAddress) {
      this.$refs.input.focus();
    }
  },
  beforeDestroy() {
    this.popover.removeEventListener('scroll', this.computeResultListPosition);
    window.removeEventListener('resize', this.computeResultListPosition);
  },
  components: {
    Autocomplete,
    'w-check-icon': CheckIcon,
    'w-location-icon': LocationIcon,
    'w-arrow-icon': ArrowIcon,
  },
};
</script>

<style lang="scss">
@import 'bulma/sass/utilities/_all';
@import '../styles/_variables.scss';

.gmp-autocomplete__hidden-map {
  display: none;
  height: 0;
  width: 0;
}

.gmp-autocomplete__icon-container {
  left: 0.75rem;
  position: absolute;
  color: var(--app-theme__lineColor);
  top: 50%;
  transform: translate(0, -50%);
}

.gmp-autocomplete__check-icon {
  height: 1.5rem;
  width: 1.75rem;
}

.gmp-autocomplete__location-icon {
  height: 1.75rem;
  width: 1.75rem;
}

.gmp-autocomplete__input-wrapper {
  position: relative;
}

.gmp-autocomplete__input {
  background-color: transparent;
  border: 2px solid var(--app-theme__lineColor);
  border-radius: 0.5rem;
  color: var(--app-them__textColor);
  font-size: $font-size-md;
  padding: 0.75rem 1rem 0.75rem 3rem;
  width: 100%;

  &:focus {
    border-color: var(--app-theme__primaryColor);
    outline: none;

    & + .gmp-autocomplete__icon-container {
      color: var(--app-theme__primaryColor);
    }
  }

  &:disabled {
    opacity: 0.5;
  }

  &::selection {
    color: var(--app-theme__gradientBottomColor);
    background-color: var(--app-theme__primaryColor);
  }

  &::placeholder {
    color: var(--app-theme__lineColor);
  }
}

.gmp-autocomplete__input--error {
  &,
  &:focus {
    border-color: var(--app-theme__errorColor);
  }

  & + .gmp-autocomplete__icon-container,
  &:focus + .gmp-autocomplete__icon-container {
    color: var(--app-theme__errorColor);
  }
}

.gmp-autocomplete__input--success {
  border-color: var(--app-theme__primaryColor);
  padding-right: calc(3.5rem);

  & + .gmp-autocomplete__icon-container {
    color: var(--app-theme__primaryColor);
  }

  & ~ .gmp-autocomplete__button {
    background-color: var(--app-theme__primaryColor);
  }

  @include from($device: $tablet) {
    padding-right: 1rem;
  }
}

.gmp-autocomplete__result-list-wrapper {
  background-color: var(--app-theme__backgroundColor);
  border: 1px solid var(--app-theme__lineColor);
  border-radius: 0.5rem;
  box-shadow: 0 0 5px 0 var(--app-theme__lineColor);
  display: flex;
  flex-direction: column;
}

.gmp-autocomplete__result-list {
  flex-grow: 1;
  overflow-y: auto;
}

.gmp-autocomplete__result {
  border-color: var(--app-theme__lineColor);
  cursor: pointer;
  padding: 1rem;

  &:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  &:hover,
  &[aria-selected] {
    color: var(--app-theme__gradientBottomColor) !important;
    background-color: var(--app-theme__primaryColor);
  }
}

.gmp-autocomplete__google-logo-container {
  border-top: 1px solid var(--app-theme__lineColor);
  padding-top: 0.5rem;
  text-align: center;
}

.gmp-autocomplete__button {
  background-color: var(--app-theme__lineColor);
  border-bottom-right-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border: none;
  color: #fff;
  height: 100%;
  padding: 0 1rem;
  position: absolute;
  right: 0;
  top: 0;

  @include from($device: $tablet) {
    display: none;
  }
}

.gmp-autocomplete__button-arrow-icon {
  transform: rotate(-90deg);
}
</style>

export default {
  setOrderDate(state, date) {
    state.orderDate = date;
  },
  setError(state, error) {
    state.error = error;
  },
  setMenus(state, menus) {
    state.menus = menus;
  },
  setFacility(state, facility) {
    state.facility = facility;
  },
  setProductsById(state, productsById) {
    state.productsById = productsById;
  },
  setProductsByResourceUri(state, productsByResourceUri) {
    state.productsByResourceUri = productsByResourceUri;
  },
  setForbiddenProducts(state, forbiddenProducts) {
    state.forbiddenProducts = forbiddenProducts;
  },
  setSelectedProductId(state, selectedProductId) {
    state.selectedProductId = selectedProductId;
  },
  setSelectedCategoryId(state, selectedCategoryId) {
    state.selectedCategoryId = selectedCategoryId;
  },
  setCartDrawerIsOpen(state, cartDrawerIsOpen) {
    state.cartDrawerIsOpen = cartDrawerIsOpen;
  },
  setShowOrderMethodPopover(state, showOrderMethodPopover) {
    state.showOrderMethodPopover = showOrderMethodPopover;
  },
  setStripeElements(state, stripeElements) {
    state.stripeElements = stripeElements;
  },
  setScrollPosition(state, scrollPosition) {
    state.scrollPosition = scrollPosition;
  },
  setExpandedCategories(state, expandedCategories) {
    state.expandedCategories = expandedCategories;
  },
  setFlashMessage(state, message) {
    state.flashMessage = message;
  },
  setCart(state, cart) {
    state.cart = cart;
  },
  setOrder(state, order) {
    state.order = order;
  },
  setGoogleMapsPlace(state, place) {
    state.googleMapsPlace = place;
  },
  setFetching(state, fetching) {
    state.fetching = fetching;
  },
  setRememberLastOrder(state, remember) {
    state.rememberLastOrder = remember;
  },
  setVoucher(state, voucher) {
    state.voucher = voucher;
  },
};

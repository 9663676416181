<template>
  <w-icon viewBox="0 0 32 32">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(0.000000, 9.864148)" stroke="currentColor" stroke-width="1.3">
          <path d="M13.8479309,16 C20.5145976,15.0787736 24.1732226,11.7348635 24.1732226,5.98942323 C24.1732226,0.243982998 20.5145976,-3.07877356 13.8479309,-4" transform="translate(19.010577, 6.000000) rotate(-90.000000) translate(-19.010577, -6.000000) "></path>
          <rect x="6.65" y="11.4873542" width="24.7" height="1.7" rx="0.85"></rect>
          <path d="M24.75,17.6358521 C25.7164983,17.6358521 26.5,16.8523504 26.5,15.8858521 C26.5,14.9193538 25.7164983,14.1358521 24.75,14.1358521 C23.7835017,14.1358521 23,14.9193538 23,15.8858521 C23,16.8523504 23.7835017,17.6358521 24.75,17.6358521 Z"></path>
          <circle cx="13.75" cy="15.8858521" r="1.75"></circle>
          <line x1="3" y1="11.1358521" x2="8" y2="11.1358521" stroke-linecap="square"></line>
          <line x1="0" y1="7.13585211" x2="9.2" y2="7.13585211" stroke-linecap="square"></line>
          <line x1="3" y1="3.13585211" x2="12" y2="3.13585211" stroke-linecap="square"></line>
      </g>
      <circle fill="currentColor" cx="19" cy="9.5" r="1.5"></circle>
    </g>
  </w-icon>
</template>

<script>
import Icon from './Icon'

export default {
  components: {
    'w-icon': Icon
  }
}
</script>

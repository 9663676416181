<template>
  <w-icon>
    <g transform="translate(0.75, -1)">
      <path d="M3 12.1L14.6 24.5C15 24.9 15.6 24.9 16 24.5L27.5 12"></path>
    </g>
  </w-icon>
</template>

<script>
import Icon from './Icon'

export default {
  components: {
    'w-icon': Icon
  }
}
</script>

<template>
  <w-sticky @stick="handleStick" @unstick="handleUnstick">
    <div v-if="preview" class="header__preview">
      <div class="header__preview-icon">
        <w-published-icon v-if="menuEnabled"></w-published-icon>
        <w-lock-icon v-else></w-lock-icon>
      </div>
      <div class="header__preview-text">{{ previewButtonTxt }}</div>
    </div>
    <div
      :class="{
        header: true,
        'header--sticky': sticky,
        'header--show-always': showAlways,
      }"
    >
      <div class="header__inner">
        <div class="header__title">
          <div class="header__title-text">{{ title }}</div>
        </div>
        <router-link class="header__facility" to="/">
          <w-arrow-icon v-if="showBackButton"></w-arrow-icon>
          {{ facilityName }}
        </router-link>
        <div v-if="showCartButton" class="header__cart-button">
          <w-cart-button @click="toggleCartDrawer"></w-cart-button>
        </div>
      </div>
    </div>
  </w-sticky>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import i18n from '../i18n';
import { Route } from '../enums';

import Sticky from './Sticky';
import CartButton from './CartButton';
import { ArrowIcon, LockIcon, PublishedIcon } from './icons';

export default {
  props: {
    backButton: Boolean,
    cartButton: Boolean,
    showAlways: Boolean,
  },
  data() {
    return {
      sticky: false,
      title: i18n.gettext('Menu'),
      publishedTxt: i18n.gettext('Published'),
      unpublishedTxt: i18n.gettext('Private preview'),
    };
  },
  computed: {
    ...mapState(['facility', 'preview']),
    ...mapGetters(['orderingEnabled', 'menuEnabled']),
    facilityName() {
      return this.facility.name;
    },
    previewButtonTxt() {
      return this.menuEnabled ? this.publishedTxt : this.unpublishedTxt;
    },
    showBackButton() {
      return !!this.backButton;
    },
    showCartButton() {
      return !!this.cartButton && this.orderingEnabled;
    },
  },
  methods: {
    ...mapActions(['toggleCartDrawer']),
    handleStick() {
      this.sticky = true;
    },
    handleUnstick() {
      this.sticky = false;
    },
  },
  components: {
    'w-sticky': Sticky,
    'w-arrow-icon': ArrowIcon,
    'w-lock-icon': LockIcon,
    'w-published-icon': PublishedIcon,
    'w-cart-button': CartButton,
  },
};
</script>

<style lang="scss">
@import 'bulma/sass/utilities/_all';

@import '../styles/_variables.scss';

.header {
  color: var(--app-theme__gradientBottomColor);
  padding: 1rem;
  overflow: hidden;
  text-align: center;

  &.header--sticky {
    background-color: var(--app-theme__gradientTopColor);
  }

  @include from($device: $tablet) {
    &.header--show-always {
      padding: 1.5rem 0;
    }
    &:not(.header--show-always) {
      display: none;
    }
  }
}

.header__title {
  font-size: $font-size-lg;
  text-transform: uppercase;
}

.header__inner {
  position: relative;
}

.header__facility {
  align-items: center;
  color: inherit;
  display: flex;
  font-size: $font-size-xl;
  justify-content: center;
  padding: 0 4rem;

  &:hover {
    color: inherit;
  }

  .icon {
    margin-right: 1rem;
    transform: rotate(90deg);
  }
}

.header__cart-button {
  align-items: center;
  display: flex;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;

  @include from($device: $tablet) {
    padding: 0 1.5rem;
  }
}

.header__preview {
  border-radius: 0;
  display: flex;
  color: #fff;
  background-color: #d7a43b;
  padding: 8px 16px;
  margin: 0;
  @include from($device: $tablet) {
    border-radius: 4px;
    position: fixed;
    top: 1rem;
    right: 83px;
  }
}

.header__preview-icon {
  flex: 0 0 auto;
  padding-right: 0.5rem;
}

.header__preview-text {
  flex: 1 1 auto;
}

.app--iframe {
  .header {
    background-color: var(--app-theme__menuColor);
    color: var(--app-theme__primaryColor);
    padding: 2rem 1rem;
  }

  .header__facility {
    display: none;
  }

  .header__title {
    border-bottom: 2px solid var(--app-theme__primaryColor);
    transform: translate(0, -50%);
  }

  .header__title-text {
    background-color: var(--app-theme__menuColor);
    display: inline-block;
    padding: 0 1.5rem;
    transform: translate(0, 50%);
  }

  .header__cart-button {
    background-color: var(--app-theme__menuColor);
    padding: 0 0 0 1rem;
  }

  @include from($device: $tablet) {
    .header {
      background-color: var(--app-theme__backgroundColor);
    }

    .header__title-text {
      background-color: var(--app-theme__backgroundColor);
    }

    .header__cart-button {
      background-color: var(--app-theme__backgroundColor);
    }
  }
}
</style>

<template>
  <div :class="{
    'product-modifier-group': true,
    'product-modifier-group--invalid': invalid,
  }">
    <div class="product-modifier-group__title">{{title}}</div>
    <div class="product-modifier-group__modifier-list">
      <div
        v-for="modifier in modifiers"
        :key="modifier.id"
        class="product-modifier-group__modifier-list-item"
      >
        <w-checkbox-input
          v-if="orderingEnabled"
          :checked="selected(modifier)"
          @change="toggle(modifier)"
        >
          {{modifier.title}}
        </w-checkbox-input>
        <span v-else class="product-modifier-group__modifier-list-item-title">
          {{modifier.title}}
        </span>
        <span class="product-modifier-group__modifier-list-item-price">
          {{getPriceTag(modifier)}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import i18n from '../../i18n'

import CheckboxInput from '../CheckboxInput'

export default {
  props: [
    'group',
    'modifiers',
    'getPriceTag',
    'orderingEnabled'
  ],
  computed: {
    ...mapState('cartItemForm', [
      'formValues',
      'formErrors'
    ]),
    ...mapGetters('cartItemForm', [
      'selectedModifiersByGroupId'
    ]),
    title () {
      return `${this.group.title_public}${this.error ? ` (${this.error})` : ''}`
    },
    selected () {
      return modifier => !!this.selectedModifiersByGroupId[this.group.id].find(
        ({ resource_uri }) => resource_uri === modifier.resource_uri
      )
    },
    error () {
      return this.orderingEnabled
        ? this.formErrors['product_modifiers'] && this.formErrors['product_modifiers'][this.group.resource_uri]
        : null
    },
    invalid () {
      return !!this.error
    }
  },
  methods: {
    ...mapActions('cartItemForm', ['toggleModifier']),
    toggle (modifier) {
      this.toggleModifier(modifier)
    }
  },
  components: {
    'w-checkbox-input': CheckboxInput
  }
}
</script>

<style lang="scss">
.product-modifier-group {
  border: 2px solid var(--app-theme__lineColor);
  border-radius: 0.25rem;
  margin: 0 -1rem;
  padding: 0 0.5rem;

  &.product-modifier-group--invalid {
    border-color: var(--app-theme__alertColor);

    .product-modifier-group__title {
      color: var(--app-theme__alertColor);
    }
  }
}

.product-modifier-group__title {
  background-color: var(--app-theme__backgroundColor);
  color: var(--app-theme__lineColor);
  display: inline-block;
  padding: 0 0.25rem;
  transform: translate(0, -50%);
}

.product-modifier-group__modifier-list {
  padding: 0 0.25rem;
}

.product-modifier-group__modifier-list-item {
  align-items: center;
  display: flex;
  margin-bottom: 1rem;
}

.product-modifier-group__modifier-list-item-title {
  flex-grow: 1;
}

.product-modifier-group__modifier-list-item-price {
  flex-shrink: 0;
}
</style>

export default class StorageService {
  constructor (storage, path) {
    this.storage = storage
    this.path = path
    this.data = {}
    this._load()
  }

  _save () {
    try {
      this.storage.setItem(this.path, JSON.stringify(this.data))
    } catch {}
  }

  _load () {
    try {
      const item = this.storage.getItem(this.path)
      if (item) {
        this.data = JSON.parse(item)
      }
    } catch {}
  }

  get (key) {
    return this.data[key]
  }

  set (key, value) {
    this.data[key] = value
    this._save()
  }

  remove (key) {
    delete this.data[key]
    this._save()
  }
}

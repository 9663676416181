<template>
  <w-icon>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(23.000000, 16.500000) scale(-1, 1) translate(-23.000000, -16.500000) translate(16.000000, 6.000000)" stroke="currentColor" stroke-width="1.3">
          <path d="M3,20.2613457 L1.67388269,20.2613457 C1.1216147,20.2613457 0.673913043,19.8072879 0.673913043,19.2596642 L0.673913043,1.12935988 C0.673913043,0.649968876 1.03872837,0.261345684 1.49768649,0.261345684 L12.8501396,0.261345684 C13.3050971,0.261345684 13.673913,0.647982426 13.673913,1.12935988 L13.673913,8.1468433"></path>
      </g>
      <path d="M20.323432,0.213825579 L22.9006422,8.48282943 C23.0645262,9.00865304 22.7474219,9.43491729 22.1995615,9.43491729 L20.7441592,9.43491729" stroke="currentColor" stroke-width="1.3" transform="translate(21.760406, 4.824371) rotate(-90.000000) translate(-21.760406, -4.824371)"></path>
      <path d="M20.5,11.5 L25.5,11.5" stroke="currentColor" stroke-width="1.3" stroke-linecap="square"></path>
      <path d="M8.84793093,31.8641479 C15.5145976,30.9429215 19.1732226,27.5990114 19.1732226,21.8535711 C19.1732226,16.1081309 15.5145976,12.7853743 8.84793093,11.8641479" stroke="currentColor" stroke-width="1.3" transform="translate(14.010577, 21.864148) rotate(-90.000000) translate(-14.010577, -21.864148)"></path>
      <rect stroke="currentColor" stroke-width="1.3" x="1.65" y="27.3515021" width="24.7" height="1.7" rx="0.85"></rect>
      <circle fill="currentColor" cx="14" cy="15.5" r="1.5"></circle>
    </g>
  </w-icon>
</template>

<script>
import Icon from './Icon'

export default {
  components: {
    'w-icon': Icon
  }
}
</script>

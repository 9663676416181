<template>
  <div style="position:relative">
    <div v-if="stickToTop" ref="scrollSpy"></div>
    <div ref="placeholder" v-if="sticky">
      <slot></slot>
    </div>
    <div v-if="stickToBottom && sticky" ref="scrollSpy"></div>
    <div ref="container" :style="containerStyle">
      <slot></slot>
    </div>
    <div v-if="stickToBottom && !sticky" ref="scrollSpy"></div>
  </div>
</template>

<script>
export default {
  props: {
    bottom: Boolean,
    scrollTarget: String
  },
  data () {
    return {
      scrollElement: null,
      containerStyle: null
    }
  },
  computed: {
    stickToTop () {
      return !this.bottom
    },
    stickToBottom () {
      return !this.stickToTop
    },
    sticky () {
      return this.containerStyle !== null
    }
  },
  methods: {
    handleScroll () {
      const stickyPos = this.getStickyPos()
      if (this.stickToTop) {
        const { top } = this.$refs.scrollSpy.getBoundingClientRect()
        if (this.sticky) {
          if (top >= stickyPos) {
            this.containerStyle = null
            this.$emit('unstick')
          }
        } else if (top < stickyPos) {
          this.resizeContainer(this.$refs.container, stickyPos)
          this.$emit('stick')
        }
      } else {
        const { bottom } = this.$refs.scrollSpy.getBoundingClientRect()
        if (this.sticky) {
          if (bottom <= stickyPos) {
            this.containerStyle = null
            this.$emit('unstick')
          }
        } else if (bottom > stickyPos) {
          this.resizeContainer(this.$refs.container, stickyPos)
          this.$emit('stick')
        }
      }
    },
    handleResize () {
      if (this.sticky) {
        this.resizeContainer(this.$refs.placeholder, this.getStickyPos())
      }
    },
    resizeContainer (el, stickyPos) {
      const { top, left, bottom, width, height } = el.getBoundingClientRect()
      this.containerStyle = {
        top: `${this.stickToTop ? stickyPos : stickyPos - height}px`,
        left: `${left}px`,
        width: `${width}px`,
        height: `${height}px`,
        position: 'fixed',
        zIndex: 101 // greater than paypal buttons
      }
    },
    getStickyPos () {
      const { top, bottom } = this.scrollElement === window
        ? { top: 0, bottom: window.innerHeight }
        : this.scrollElement.getBoundingClientRect()
      return this.stickToTop ? top : bottom
    }
  },
  async mounted () {
    this.scrollElement = this.scrollTarget ? document.querySelector(this.scrollTarget) : window
    this.scrollElement.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleResize)
    await this.$nextTick()
    this.handleScroll()
  },
  beforeDestroy () {
    this.scrollElement.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<template>
  <div v-if="cartIsFilled" class="cart-item-list blockable">
    <w-cart-item
      v-for="item in cartItems"
      :key="item.id"
      :item="item"
    >
    </w-cart-item>
  </div>
  <w-empty-cart-message v-else></w-empty-cart-message>
</template>

<script>
import { mapGetters } from 'vuex'

import { EmptyCartMessage } from './messages'

import CartItem from './CartItem'

export default {
  computed: {
    ...mapGetters(['cartItems', 'cartIsFilled'])
  },
  components: {
    'w-cart-item': CartItem,
    'w-empty-cart-message': EmptyCartMessage
  }
}
</script>

<style>
.cart-item-list {
  display: table;
  width: 100%;
}
</style>

<template>
  <w-icon v-bind="$attrs" v-on="$listeners">
    <g
      id="symbol-alcohol"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <circle
        id="Oval-51"
        stroke="#F8C150"
        stroke-width="1.3"
        fill="#FFFFFF"
        cx="16"
        cy="16"
        r="15"
      ></circle>
      <path
        d="M14.5643967,4.5 L17.3410179,4.5 L17.3410179,10.9691559 C18.6844751,11.4221482 19.3562037,12.365648 19.3562037,13.7996553 C19.3562037,15.2336626 19.3562037,19.8004442 19.3562037,27.5 L12.6492111,27.5 C12.6492111,19.8004442 12.6492111,15.2336626 12.6492111,13.7996553 C12.6492111,12.365648 13.2876063,11.4221482 14.5643967,10.9691559 L14.5643967,4.5 Z"
        id="Rectangle"
        stroke="#F8C150"
        stroke-width="1.3"
      ></path>
    </g>
  </w-icon>
</template>

<script>
import Icon from './Icon';

export default {
  components: {
    'w-icon': Icon,
  },
};
</script>

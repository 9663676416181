<template>
  <div class="error-layout">
    <slot></slot>
    <w-footer></w-footer>
  </div>
</template>

<script>
import Footer from '../Footer';

export default {
  components: {
    'w-footer': Footer,
  },
};
</script>

<style lang="scss">
@import 'bulma/sass/utilities/_all';

@import '../../styles/_variables.scss';

.error-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 1rem 1rem 0;

  @include from($device: $tablet) {
    padding: 2rem 1rem 0;
  }

  @include from($device: $desktop) {
    padding: 5rem calc((#{$sidebar-width} + #{$column-gap-desktop} * 2) / 2) 0;
  }
}
</style>
